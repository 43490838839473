import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../../middleware/axiosInstance";
import Select from "react-select";

const CreateExamType = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    examType: "",
    branchId: null,
  });

  const [loading, setLoading] = useState(false);
  const [branches, setBranches] = useState([]);
  const [errors, setErrors] = useState({
    examType: "",
  });

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await axiosInstance.get("/branches");
        setBranches(
          response.data.map((branch) => ({
            value: branch._id,
            label: branch.branchName,
          }))
        );
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
    };
    fetchBranches();
  }, []);

  const handleSelectChange = (selectedOption, { name }) => {
    setFormData((prev) => ({
      ...prev,
      [name]: selectedOption ? selectedOption.value : null,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });

    // Validation logic for examType
    if (name === "examType") {
      const words = value.trim().split(/\s+/);
      const wordCount = words.length;

      if (wordCount > 40) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          examType: "Exam Type must be 40 words or less.",
        }));
      } else if (words.some((word) => word.length > 50)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          examType: "Each word must be 50 characters or less.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          examType: "", // Clear error if valid
        }));
      }
    }
  };

  const handleCreateExamType = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axiosInstance.post("/exam/create/examType", formData);
      navigate("/examtypes");
    } catch (error) {
      console.error("Error creating exam type:", error);
    }

    setLoading(false);
  };

  const handleCancel = () => {
    navigate("/examtypes");
  };

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#FB0F01" : provided.borderColor,
      boxShadow: state.isFocused ? "0 0 0 1px #FB0F01" : null,
      borderWidth: state.isFocused ? "0px" : "1px",
      transition: "border-color 0.2s, box-shadow 0.2s",
      "&:hover": {
        borderColor: "#FB0F01",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#FFFFFF",
      zIndex: 999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FDD7C2" : "#FFFFFF",
      color: "black",
      "&:hover": {
        backgroundColor: "#FDD7C2",
        color: "black",
      },
      "&:active": {
        backgroundColor: "#E8F0FE",
      },
    }),
  };

  return (
    <div>
      <Card style={{ margin: "0 auto" }}>
        <CardBody>
          <CardTitle tag="h3" className="mb-1 text-center">
            Create Exam Type
          </CardTitle>
          <Form className="mt-5" onSubmit={handleCreateExamType}>
            <Row>
              <Col xs={12} sm={6} md={6} xl={4}>
                <FormGroup>
                  <Label for="branch">
                    <span style={{ fontWeight: "bold" }}>Branch*</span>
                  </Label>
                  <Select
                    name="branchId"
                    options={branches}
                    value={
                      branches.find(
                        (branch) => branch.value === formData.branchId
                      ) || null
                    }
                    onChange={handleSelectChange}
                    isClearable
                    placeholder="Select a Branch"
                    required
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={6} md={6} xl={4}>
                <FormGroup>
                  <Label for="examType">
                    <span style={{ fontWeight: "bold" }}>Exam Type*</span>
                  </Label>
                  <Input
                    type="text"
                    name="examType"
                    id="examType"
                    value={formData.examType}
                    onChange={handleChange}
                    required
                    placeholder="e.g., Written" 
                    />
                  {errors.examType && (
                    <div
                      style={{
                        color: "red",
                        marginTop: "0.25rem",
                        fontSize: "0.875rem",
                      }}
                    >
                      {errors.examType}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <div className="d-flex justify-content-center mt-3">
              <Button
                className="btn-no-radius"
                type="submit"
                color="primary"
                disabled={loading}
              >
                {loading ? <Spinner size="sm" /> : "Save"}
              </Button>
              <Button
                className="btn-no-radius mx-2"
                color="secondary"
                onClick={handleCancel}
                disabled={loading}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default CreateExamType;
