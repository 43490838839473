import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Button, Form, FormGroup, Label, Input, CardBody, CardTitle, Row, Col, Table } from 'reactstrap';
import Swal from 'sweetalert2';
import { FaRegEdit } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";
import axiosInstance from '../../../middleware/axiosInstance';


const UpdateClass = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    className: '',
    sections: [],
    branchName: '',
  });

  const [errors, setErrors] = useState({
    className: '',
    sections: [],

  });
  
  const [sectionInput, setSectionInput] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  

  const { className, sections, branchName } = formData;

  useEffect(() => {
    const fetchClassAndBranches = async () => {
      try {
        const [classResponse] = await Promise.all([
          axiosInstance.get(`/singleclass/${id}`),       
        ]);
        setFormData({
          className: classResponse.data.className || '',
          branchName: classResponse.data.branch_id?.branchName || '', 
          sections: classResponse.data.sections || [],
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchClassAndBranches();
  }, [id]);



  const handleUpdate = async (e) => {
    e.preventDefault();
  
    const classWordCount = formData.className.trim().split(/\s+/).length;
    if (classWordCount > 40) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        className: 'ClassName must be less than 40 words.',
      }));
      return;
    }
  
    try {
      await axiosInstance.put(`/updateclass/${id}`, { className, sections });
      navigate("/classes");
  
    } catch (err) {
      console.error("Error updating Class:", err);
    }
  };






  const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name === 'className') {
      const words = value.trim().split(/\s+/);
      const wordCount = words.length;
    
      if (wordCount > 40) {
        setErrors({ ...errors, [name]: 'ClassName must be less than 40 words.' });
        return;
      }
      
      if (words.some(word => word.length > 50)) {
        setErrors({ ...errors, [name]: 'Each word must be less than 50 characters.' });
        return;
      }
      
      setFormData({ ...formData, [name]: value });
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
    };

  const handleSectionInputChange = (e) => {
    const { value } = e.target;

     const words = value.trim().split(/\s+/);
    const wordCount = words.length;
  
      if (wordCount > 40) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          sections: 'Section name must be 40 words or less',
        }));
      } 
       else if (words.some(word => word.length > 50)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          sections: 'Each word must be 50 characters or less',
        }));
      } 
      else {
        setSectionInput(value);
        setErrors((prevErrors) => ({
          ...prevErrors,
          sections: '',  
        }));
      }
    
  };

  const addSection = () => {
    if (isEditing) {
    
      if(sectionInput === null || sectionInput.trim() === '')
        {
          Swal.fire({
            title: "Warning!",
            text: "Section Name Cannot Be Empty",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }else{
          const updatedSections = [...sections];
          if(updatedSections[editingIndex]=== undefined)
          {
            setSectionInput('');
            setIsEditing(false);
            return;
          }
          const id = updatedSections[editingIndex]._id;
          axiosInstance.put(`/sections/${id}`, { newName: sectionInput })
        .then(response => {
          updatedSections[editingIndex].sectionName = sectionInput;  
          setFormData({ ...formData, sections: updatedSections });
        })
        .catch(error => {
          console.error("Error updating section name:", error);
        });
      setIsEditing(false);
      setEditingIndex(null);
        }
    } else {
      if(sectionInput === null || sectionInput.trim() === '')
        {
          Swal.fire({
            title: "Warning!",
            text: "Section Name Cannot Be Empty",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }else{
          axiosInstance.post(`/addsection/${id}`, { sectionName: sectionInput })
          .then(response => {
             setFormData({
              ...formData,
              sections: [...sections, response.data.savedSection]  
        
            });
          })
          .catch(error => {
            console.error('Error adding section:', error);
          });
        }
    }
    setSectionInput(''); 
  };

  const editSection = (index) => {
    setSectionInput(sections[index].sectionName);
    setIsEditing(true);
    setEditingIndex(index);
  };

const deleteSection = async (index) => {
  const sectionId = sections[index]._id;
  const result = await Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'Cancel'
  });

  if (result.isConfirmed) {
    try {
      await axiosInstance.delete(`/deleteSection/${sectionId}`);
      const updatedSections = sections.filter((_, i) => i !== index);
      setFormData({ ...formData, sections: updatedSections });
      if (isEditing && editingIndex === index) {
        setSectionInput(''); 
        setIsEditing(false); 
        setEditingIndex(null); 
      }
    } catch (error) {
      console.error('Error deleting section:', error);
    }
  }
};


  const handleCancel = () => {
    navigate('/classes');
  };

  return (
    <div>
      <Card style={{ margin: '0 auto' }}>
        <CardBody>
          <CardTitle tag="h3" className="mb-4 text-center fw-bold">Edit Class</CardTitle>
          <Form onSubmit={handleUpdate}>
            <Row>
              <Col xs={12} sm={6}   xl={3}>
                <FormGroup>
                  <Label for="className" className=' fw-bold'>Class</Label>
                  <Input
                    type="text"
                    name="className"
                    id="className"
                    value={className}
                    onChange={handleChange}
                     placeholder="Enter Class Name"
                    required
                    />
                    {errors.className && errors.className.length > 0 && ( // Display error if it exists
                      <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                        {errors.className}
                      </div>
                    )}
                </FormGroup>
              </Col>

              <Col xs={12} sm={6}   xl={3}>
                <FormGroup>
                  <Label for="className" className=' fw-bold'>Branch</Label>
                  <Input
                    type="text"
                    name="className"
                    id="className"
                    value={branchName}
                    onChange={handleChange}
                    required
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}   xl={3}>
                <FormGroup>
                  <Label for="sectionInput" className=' fw-bold'>Section</Label>
                  <Input
                    type="text"
                    id="sectionInput"
                    value={sectionInput}
                    onChange={handleSectionInputChange}
                    placeholder="Enter Section Name"
                    />
                      {errors.sections && (
                          <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                            {errors.sections}
                          </div>
                        )}
                 
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}   xl={3} className="mt-sm-2">
              <Button color="primary" className=" mt-sm-4 btn-no-radius" onClick={addSection}>
                    {isEditing ? 'Update Section' : 'Add Section'}
                  </Button>
              </Col>
              <Col md={12} className='mt-3'>
              {formData.sections.length > 0 && (
                <Table bordered hover responsive>
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Section</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sections.map((section, index) => (
                      <tr key={section._id}>
                        <th scope="row">{index + 1}</th>
                        <td>{section.sectionName}</td>
                        <td>
                          <FaRegEdit
                            className="mx-2"
                            style={{ color: 'black', cursor: 'pointer' }}
                            onClick={() => editSection(index)}
                          />
                          <AiOutlineDelete
                            className="mx-2"
                            style={{ color: 'black', cursor: 'pointer' }}
                            onClick={() => deleteSection(index)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                  )}
              </Col>
            </Row>
            <div className="d-flex justify-content-center mt-3">
            <Button className="btn-no-radius" color="primary" type="submit">
                Save
              </Button>
              <Button color="secondary" type="button" onClick={handleCancel} className="btn-no-radius mx-2">
                Cancel
              </Button>
             
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default UpdateClass;

