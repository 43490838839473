import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../../../middleware/axiosInstance';
import { Row, Col, CardTitle, CardText,  Button, Spinner } from 'reactstrap';
import Swal from 'sweetalert2';
import { format } from 'date-fns';
import { FiArrowLeft } from 'react-icons/fi';


const StudentDocuments = () => {

  const navigate = useNavigate();

  const { id } = useParams(); 
  const [studentData, setStudentData] = useState(null);

  const [loadingIdCard, setLoadingIdCard] = useState(false); 
  const [loadingBonafide, setLoadingBonafide] = useState(false);
  const [loadingLeaving, setLoadingLeaving] = useState(false);
  const [loadingAdmission, setLoadingAdmission] = useState(false);
  const [loadingCharacter, setLoadingCharacter] = useState(false);

  const [lastdateIdCard, setlastdateIdCard] = useState(false); 
  const [lastdateBonafide, setlastdateBonafide] = useState(false);
  const [lastdateLeaving, setlastdateLeaving] = useState(false);
  const [lastdateAdmission, setlastdateAdmission] = useState(false);
  const [lastdateCharacter, setlastdateCharacter] = useState(false);

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const response = await axiosInstance.get(`/singlestudent/${id}`);
        setStudentData(response.data);
         setlastdateAdmission(
          response.data?.documents?.generatedAdmissionConfirmation?.lastGeneratedDate ? format(new Date(response.data?.documents?.generatedAdmissionConfirmation?.lastGeneratedDate), "MMMM dd, yyyy (hh:mm a)")  : ' Not generated yet'
        )
        setlastdateLeaving( response.data?.documents?.generatedLeavingCertificate?.lastGeneratedDate ? format(new Date(response.data?.documents?.generatedLeavingCertificate?.lastGeneratedDate), "MMMM dd, yyyy (hh:mm a)")  : ' Not generated yet')

        setlastdateBonafide(response.data?.documents?.generatedBonafideCertificate?.lastGeneratedDate ? format(new Date(response.data?.documents?.generatedBonafideCertificate?.lastGeneratedDate), "MMMM dd, yyyy (hh:mm a)")  : 'Not generated yet ')
        setlastdateIdCard(response.data?.documents?.generatedStudentIDCard?.lastGeneratedDate ? format(new Date(response.data?.documents?.generatedStudentIDCard?.lastGeneratedDate), "MMMM dd, yyyy (hh:mm a)")  : ' Not generated yet')
        setlastdateCharacter(response.data?.documents?.generatedCharacterCertificate?.lastGeneratedDate ? format(new Date(response.data?.documents?.generatedCharacterCertificate?.lastGeneratedDate), "MMMM dd, yyyy (hh:mm a)")  : ' Not generated yet')
      
      
      } catch (err) {
        console.error('Error fetching student data:', err);
        Swal.fire({
          title: 'Error!',
          text: 'There was an error fetching the student details',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    };
    fetchStudentData();
  }, [id]);

  
  const getInitial = (name) => {
    if (!name) return '';
    return name.charAt(0).toUpperCase();
  };

const handleDownloadIdCard = async () => {
  setLoadingIdCard(true); 
  try {
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/generateidcard/${id}`);
     const pdfUrl = response.data.responsedata.url;
     const formattedDate = format(new Date(response.data.responsedata.lastGeneratedDate), "MMMM dd, yyyy (hh:mm a)");
    setlastdateIdCard(formattedDate || '')
    if (pdfUrl) {
      window.open(pdfUrl, '_blank');
    } 
  } catch (err) {
    console.error('Error generating ID card:', err);
  } finally {
    setLoadingIdCard(false); 
  }
};

const handleDownloadBonafide = async () => {
  setLoadingBonafide(true);
  try {
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/generatebonafide/${id}`);
     const bonafideUrl = response.data.responsedata.url;
    const formattedDate = format(new Date(response.data.responsedata.lastGeneratedDate), "MMMM dd, yyyy (hh:mm a)");
    setlastdateBonafide(formattedDate || '')
    if (bonafideUrl) {
      window.open(bonafideUrl, '_blank');
    }
  } catch (err) {
    console.error('Error generating Bonafide Certificate:', err);
  } finally {
    setLoadingBonafide(false);
  }
};

const handleDownloadLC = async () => {
  setLoadingLeaving(true);
  try {
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/generateleaving/${id}`);
    const leavingUrl = response.data.responsedata.url;
    const formattedDate = format(new Date(response.data.responsedata.lastGeneratedDate), "MMMM dd, yyyy (hh:mm a)");
    setlastdateLeaving(formattedDate || '')

    if (leavingUrl) {
      window.open(leavingUrl, '_blank');
    }
  } catch (err) {
    console.error('Error generating Leaving Certificate:', err);
  } finally {
    setLoadingLeaving(false);
  }
};

const handleDownloadAdmissionForm = async () => {
  setLoadingAdmission(true);
  try {
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/generateadmissionform/${id}`);
    const admissionFormUrl = response.data.responsedata.url;
    const formattedDate = format(new Date(response.data.responsedata.lastGeneratedDate), "MMMM dd, yyyy (hh:mm a)");
    setlastdateAdmission(formattedDate || '')



    if (admissionFormUrl) {
      window.open(admissionFormUrl, '_blank');
    }
  } catch (err) {
    console.error('Error generating Admission Confirmation:', err);
  } finally {
    setLoadingAdmission(false);
  }
};

const handleDownloadCC = async () => {
  setLoadingCharacter(true);
  try {
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/generateCharacter/${id}`);
    const CharacterUrl = response.data.responsedata.url;
    const formattedDate = format(new Date(response.data.responsedata.lastGeneratedDate), "MMMM dd, yyyy (hh:mm a)");
    setlastdateCharacter(formattedDate || '')

    if (CharacterUrl) {
      window.open(CharacterUrl, '_blank');
    }
  } catch (err) {
    console.error('Error generating Character Certificate:', err);
  } finally {
    setLoadingCharacter(false);
  }
};


  if (!studentData) {
    return <p>Loading...</p>; 
  }

  const handleBackClick = () => {
    navigate(-1); 
  };


  return (
    <div>
       <button onClick={handleBackClick} className="floating-back-button">
        <FiArrowLeft size={24} />
      </button>
      <div style={{
        backgroundColor: 'white',
        padding: '0px',
        borderRadius: '0px',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        width: '100%',
        margin: '0 auto'
      }}>
        <Row className='m-0 p-0'>
          <Col md="4" style={{ display: 'flex', justifyContent: 'center' }}>
          {studentData.image ? (
            <img
            src={studentData.image || 'default-image-url'}
            alt="Student"
            style={{
              width: '200px',
              height: '200px',
              objectFit: 'contain',
              borderRadius: '0px',
              marginTop: '10px'
            }}
          /> ) : (
                     <div
                      className="square mt-2 mt-md-0"
                      style={{
                        width: '200px',
                        height: '200px',
                        backgroundColor: '#fb0f01',
                        color: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        alignSelf:"center",
                        fontSize: '60px',
                        fontWeight: 'bold',

                      }}
                    >
                      {getInitial(studentData.firstName)}
                    </div>
                     )}
           </Col>
          
          <Col md="8" className="p-3">
            <CardTitle tag="h4" className="mb-3">
              {studentData.firstName} {studentData.lastName}
            </CardTitle>
            <CardText>
              <strong>Register Number:</strong> {studentData.registerNo}
            </CardText>
            <CardText> <strong>Branch: </strong>{studentData.branch_id ? `${studentData.branch_id.branchName}` : 'N/A'}</CardText>
            <CardText>
              <strong>Class:</strong> {studentData.class_id?.className}{studentData.section_id ? ` (${studentData.section_id.sectionName})` : ''}
            </CardText>
            <CardText>
              <strong>Roll Number:</strong> {studentData.roll}
            </CardText>
          </Col>
        </Row>
      </div>

      <div style={{ backgroundColor: '', padding: '20px', marginTop: '10px', marginBottom: '10px', textAlign: 'center' }} className='bg-light'>
  <h5>Generate Student Documents</h5>

  <Row className="justify-content-center mb-3">
    <Col md={6} lg={4} className="text-center mt-3 ">
      <Button 
        color="primary" 
        style={{ backgroundColor: loadingIdCard ? 'black' : '', width: '100%' }} 
        onClick={handleDownloadIdCard}
        disabled={loadingIdCard}
      >
        {loadingIdCard ? (
          <>
            <Spinner size="sm" /> Generating ID Card...
          </>
        ) : 'ID Card'}
      </Button>
      <span style={{ fontSize: "14px", color: "black", display: 'block', marginTop: '5px' }}>
        Last Generated Date: <span style={{color:'#FB0F01'}}> {lastdateIdCard}</span>
      </span>
    </Col>

    <Col md={6} lg={4} className="text-center mt-3">
      <Button 
        color="primary" 
        style={{ width: '100%' }} 
        onClick={handleDownloadLC}
        disabled={loadingLeaving}
      >
        {loadingLeaving ? (
          <>
            <Spinner size="sm" /> Generating Leaving Certificate...
          </>
        ) : 'Leaving Certificate'}
      </Button>
      <span style={{ fontSize: "14px", color: "black", display: 'block', marginTop: '5px' }}>
        Last Generated Date: <span style={{color:'#FB0F01'}}> {lastdateLeaving}</span>
      </span>
    </Col>

    <Col md={6} lg={4} className="text-center mt-3">
      <Button 
        color="primary" 
        style={{ width: '100%' }} 
        onClick={handleDownloadBonafide}
        disabled={loadingBonafide}
      >
        {loadingBonafide ? (
          <>
            <Spinner size="sm" /> Generating Bonafide...
          </>
        ) : 'Bonafide'}
      </Button>
      <span style={{ fontSize: "14px", color: "black", display: 'block', marginTop: '5px' }}>
        Last Generated Date:<span style={{color:'#FB0F01'}}>  {lastdateBonafide}</span>
      </span>
    </Col>

    <Col md={6} lg={4} className="text-center mt-3">
      <Button 
        color="primary" 
        style={{ width: '100%' }} 
        onClick={handleDownloadAdmissionForm}
        disabled={loadingAdmission}
      >
        {loadingAdmission ? (
          <>
            <Spinner size="sm" /> Generating Admission Confirmation...
          </>
        ) : 'Admission Confirmation'}
      </Button>
      <span style={{ fontSize: "14px", color: "black", display: 'block', marginTop: '5px' }}>
        Last Generated Date:<span style={{color:'#FB0F01'}}>  {lastdateAdmission}</span>
      </span>
    </Col>

    
    <Col md={6} lg={4} className="text-center mt-3">
      <Button 
        color="primary" 
        style={{ width: '100%' }} 
        onClick={handleDownloadCC}
        disabled={loadingCharacter}
      >
        {loadingCharacter ? (
          <>
            <Spinner size="sm" /> Generating Character Certificate...
          </>
        ) : 'Character Certificate'}
      </Button>
      <span style={{ fontSize: "14px", color: "black", display: 'block', marginTop: '5px' }}>
        Last Generated Date: <span style={{color:'#FB0F01'}}> {lastdateCharacter}</span>
      </span>
    </Col>
  </Row>
</div>
    </div>
  );
};

export default StudentDocuments;




