import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import {
  Card,
  CardTitle,
  Form,
  FormGroup,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Label,
  Input,
  Spinner,
  Button,
  Table,
  Row,
  Col
} from 'reactstrap'

import { FaSearch, FaRegEdit, FaFileAlt } from 'react-icons/fa'
import { AiOutlineDelete, AiOutlineEye, AiOutlineFilter } from 'react-icons/ai'
import { NavLink, useNavigate, Link } from 'react-router-dom'
import { debounce } from 'lodash'
import axiosInstance from '../../../../middleware/axiosInstance'
import Select from 'react-select'

const Students = () => {
  const navigate = useNavigate()

  const [formData, setFormData] = useState({
    branchId: '',
    classId: '',
    sectionId: '',
    academicYearId: '',
    status: '',
    studentname:""

  })

  const [branches, setBranches] = useState([])
  const [classes, setClasses] = useState([])
  const [sections, setSections] = useState([])
  const [students, setStudents] = useState([])
  const [totalStudents, setTotalStudents] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [academicYears, setAcademicYears] = useState([])
  const [studentsPerPage] = useState(75)
  const [error, setError] = useState('')

  const [noDataFound, setNoDataFound] = useState(false)
  const [loading, setLoading] = useState(true)

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggle = () => setDropdownOpen(!dropdownOpen)






  
  useEffect(() => {
    
    const getAcademicYears = async () => {
      try {
        const response = await axiosInstance.get("/academicyears");
        setAcademicYears(response.data);

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();  
        const currentDay = currentDate.getDate();

        let defaultAcademicYear;

        if (currentMonth > 4 || (currentMonth === 4 && currentDay >= 1)) {
          // June 1 to December 31, or May 1 to May 31
          
          defaultAcademicYear = `${currentYear}-${String(currentYear + 1).slice(
            -2
          )}`;
        } else {
          defaultAcademicYear = `${currentYear - 1}-${String(currentYear).slice(
            -2
          )}`;
        }

         const defaultAcademicYearEntry = response.data.find(
          (year) => year.year === defaultAcademicYear  
        );

        

        if (defaultAcademicYearEntry) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            academicYearId: defaultAcademicYearEntry._id,  
          }));
        }
      } catch (error) {
        console.error("Error fetching academic years:", error);
      }finally {
        setLoading(false);  
      }
    };

    getAcademicYears();
  }, []);

  

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await axiosInstance.get('/branches')
        setBranches(response.data)
      } catch (error) {
        // console.error('Error fetching branches:', error)
        setError('Failed to fetch academic years.')
      }
    }
    fetchBranches()
  }, [])

  useEffect(() => {
    const fetchClasses = async () => {
      if (formData.branchId) {
        try {
          const response = await axiosInstance.get('/classes/branch', {
            params: { branchId: formData.branchId }
          })
          setClasses(response.data)
          setFormData(prev => ({ ...prev, classId: '', sectionId: '' }))
        } catch (error) {
          setError('Failed to fetch classes.')
        } finally {
          setLoading(false)
        }
      } else {
        setClasses([])
        setSections([])
        setFormData(prev => ({ ...prev, classId: '', sectionId: '' }))
      }
    }

    fetchClasses()
  }, [formData.branchId])

  useEffect(() => {
    const fetchSections = async () => {
      if (formData.branchId && formData.classId) {
        try {
          const response = await axiosInstance.get('/sections', {
            params: {
              branchId: formData.branchId,
              classId: formData.classId
            }
          })
          const sortedSections = response.data.sort((a, b) =>
            a.sectionName.localeCompare(b.sectionName)
          )
          setSections(sortedSections)
        } catch (error) {
          setError('Failed to fetch sections.')
        }
      } else {
        setSections([])
      }
    }
    fetchSections()
  }, [formData.branchId, formData.classId])



   const fetchStudents = async () => {
     try {
       const requestData = {}

       if (formData?.academicYearId)
         requestData.academicYearId = formData.academicYearId
       if (formData?.branchId) requestData.branchId = formData.branchId
       if (formData?.classId) requestData.classId = formData.classId
       if (formData?.sectionId) requestData.sectionId = formData.sectionId
       if (formData?.status) requestData.status = formData.status
       if (formData?.studentname) requestData.studentname = formData.studentname.trim()
       const response = await axiosInstance.post(
         '/report/studentreport',
         requestData
       )
       setStudents(response.data.allStudents)
       setTotalStudents(response.data.totalStudents)
  //     // setNoDataFound(sortedStudents.length === 0);
     } catch (error) {
       setError('No Data Found')
     }
   }

 
   const handleChange = ({ target: { name, value } }) => {
     setFormData(prev => ({
       ...prev,
       [name]: value,
       ...(name === 'classId' ? { sectionId: '' } : {})
     }))
     if (name === 'branchId' || name === 'classId') {
    
       setNoDataFound(false)
     }
   }


  
 

  const handleSearchChange = (event) => {
  

    
      
     setFormData({
       ...formData,
       studentname: event.target.value
     });
   };

  
  useEffect(() => {
    
      fetchStudents();
  
  }, [formData.branchId, formData.classId, formData.sectionId, formData.status,formData.studentname,formData.academicYearId]);



  const handleExport = async type => {
    const requestData = {}

    if (formData?.branchId) requestData.branchId = formData.branchId
    if (formData?.academicYearId)
      requestData.academicYearId = formData.academicYearId
    if (formData?.classId) requestData.classId = formData.classId
    if (formData?.sectionId) requestData.sectionId = formData.sectionId
    if (formData?.status) requestData.status = formData.status
    if (formData.studentname) requestData.studentname = formData.studentname.trim()

    if (type === 'pdf') {
      requestData.file = type
      const responsepdf = await axiosInstance.post(
        '/report/studentreportgenerate/',
        requestData
      )
      const buffer = responsepdf.data.buffer
      const byteArray = new Uint8Array(buffer.data)
      const blob = new Blob([byteArray], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'StudentFeesStatusReport.pdf' // Set the file name for download
      document.body.appendChild(a)
      a.click()
      a.remove()
    } else if (type === 'excel') {
      requestData.file = type
      const responsetome = await axiosInstance.post(
        '/report/studentreportgenerate/',
        requestData
      )
      const buffer = responsetome.data.buffer
      const byteArray = new Uint8Array(buffer.data)
      const blob = new Blob([byteArray], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'StudentFeesStatusReport.xlsx'
      document.body.appendChild(a)
      a.click()
      a.remove()
    }
  }

  const customStyles = {
    placeholder: provided => ({
      ...provided,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#FB0F01' : provided.borderColor,
      boxShadow: state.isFocused ? '0 0 0 1px #FB0F01' : null,
      borderWidth: state.isFocused ? '0px' : '1px',
      transition: 'border-color 0.2s, box-shadow 0.2s',
      '&:hover': {
        borderColor: '#FB0F01'
      }
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: '#FFFFFF',
      zIndex: 999
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#FDD7C2' : '#FFFFFF',
      color: 'black',
      '&:hover': {
        backgroundColor: '#FDD7C2',
        color: 'black'
      },
      '&:active': {
        backgroundColor: '#E8F0FE'
      }
    })
  }


  return (
    <>
      {loading ? (
        <div
          className='d-flex justify-content-center center align-items-center p-4'
          style={{ minHeight: '90vh' }}
        >
          <Spinner color='primary' />
        </div>
      ) : (
        <>
          <Card
            style={{
              boxShadow: 'none',
              marginBottom: '10px',
              borderRadius: '0'
            }}
          >
            <div className='d-flex justify-content-between align-items-center px-3 pt-1'>
              <CardTitle className='mt-2 no-wrap' tag='h4'>
              Student Fees Status Report
              </CardTitle>
              <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle
                  caret
                  className='btn-no-radius '
                  color='primary'
                >
                  Export
                </DropdownToggle>
                <DropdownMenu
                  className='p-0 w-100'
                  style={{
                    backgroundColor: 'red',
                    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                    minWidth: '50px'
                  }}
                >
                  <DropdownItem
                    onClick={() => handleExport('pdf')}
                    style={{
                      backgroundColor: 'white',
                      color: 'black',
                      padding: '10px 15px',
                      cursor: 'pointer',
                      transition: 'background-color 0.2s'
                    }}
                    onMouseOver={e =>
                      (e.target.style.backgroundColor = '#FDD7C2')
                    }
                    onMouseOut={e => (e.target.style.backgroundColor = 'white')}
                  >
                    PDF
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => handleExport('excel')}
                    style={{
                      backgroundColor: 'white',
                      color: 'black',
                      padding: '10px 15px',
                      cursor: 'pointer',
                      transition: 'background-color 0.2s'
                    }}
                    onMouseOver={e =>
                      (e.target.style.backgroundColor = '#FDD7C2')
                    }
                    onMouseOut={e => (e.target.style.backgroundColor = 'white')}
                  >
                    Excel
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            <hr className='mt-1' />
            <div className='px-3 pt-0'>
              <Form>
                <Row>
                  <Col xs={12} className='mb-3 '>
                    <CardTitle tag='h5' className='d-flex align-items-center'>
                      <AiOutlineFilter className='mr-2' />
                      Filters :
                    </CardTitle>
                  </Col>

                  <Col xs={12} sm={6} md={4} xl={3} className='mb-3'>
                    <FormGroup>
                      <Select
                        id='academicYear'
                        options={academicYears.map(year => ({
                          value: year._id,
                          label: year.year
                        }))}
                        onChange={selected => {
                          handleChange({
                            target: {
                              name: 'academicYearId',
                              value: selected ? selected.value : '' // Handle null case
                            }
                          })
                        }}
                        value={
                          academicYears.find(
                            year => year._id === formData.academicYearId
                          )
                            ? {
                                value: formData.academicYearId,
                                label: academicYears.find(
                                  year => year._id === formData.academicYearId
                                ).year
                              }
                            : null
                        }
                        placeholder='Academic Year'
                        isSearchable={true}
                        isClearable={true}
                        styles={customStyles}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={6} md={4} xl={3} className='mb-3 '>
                    <FormGroup>
                      <Select
                        id='branchId'
                        options={branches.map(branch => ({
                          value: branch._id,
                          label: branch.branchName
                        }))}
                        onChange={selected => {
                          handleChange({
                            target: {
                              name: 'branchId',
                              value: selected ? selected.value : ''
                            }
                          })
                        }}
                        value={
                          branches.find(
                            branch => branch._id === formData.branchId
                          )
                            ? {
                                value: formData.branchId,
                                label: branches.find(
                                  branch => branch._id === formData.branchId
                                ).branchName
                              }
                            : null
                        }
                        placeholder='Branch'
                        isSearchable={true}
                        isClearable={true}
                        styles={customStyles}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={6} md={4} xl={3} className='mb-3'>
                    <FormGroup>
                      <Select
                        id='classId'
                        options={classes.map(classItem => ({
                          value: classItem._id,
                          label: classItem.className
                        }))}
                        onChange={selected => {
                          handleChange({
                            target: {
                              name: 'classId',
                              value: selected ? selected.value : ''
                            }
                          })
                        }}
                        value={
                          classes.find(
                            classItem => classItem._id === formData.classId
                          )
                            ? {
                                value: formData.classId,
                                label: classes.find(
                                  classItem =>
                                    classItem._id === formData.classId
                                ).className
                              }
                            : null
                        }
                        
                        placeholder='Class'
                        isSearchable={true}
                        isClearable={true}
                        styles={customStyles}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={6} md={4} xl={3} className='mb-3'>
                    <FormGroup>
                      <Select
                        id='sectionId'
                        options={sections.map(section => ({
                          value: section._id,
                          label: section.sectionName
                        }))}
                        onChange={selected => {
                          handleChange({
                            target: {
                              name: 'sectionId',
                              value: selected ? selected.value : ''
                            }
                          })
                        }}
                        value={
                          sections.find(
                            section => section._id === formData.sectionId
                          )
                            ? {
                                value: formData.sectionId,
                                label: sections.find(
                                  section => section._id === formData.sectionId
                                ).sectionName
                              }
                            : null
                        }
                        // placeholder="Section"
                        placeholder={
                          sections.length === 0 ? 'No Section' : 'Section'
                        }
                        isDisabled={sections.length === 0}
                        isSearchable={true}
                        isClearable={true}
                        styles={customStyles}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={6} md={4} xl={3} className='mb-3'>
                    <FormGroup>
                      <Select
                        id='status'
                        options={[
                          {
                            value: 'Partial Payment',
                            label: 'Partial Payment'
                          },
                          { value: 'Unpaid', label: 'Unpaid' },
                          { value: 'Paid', label: 'Paid' },
                          { value: 'Fees Not Added', label: 'Fees Not Added' }
                        ]}
                        onChange={selected => {
                          handleChange({
                            target: {
                              name: 'status',
                              value: selected ? selected.value : ''
                            }
                          })
                        }}
                        value={
                          formData.status
                            ? {
                                value: formData.status,
                                label:
                                  formData.status.charAt(0).toUpperCase() +
                                  formData.status.slice(1)
                              }
                            : null
                        }
                        placeholder='Fees Status'
                        isSearchable={true}
                        isClearable={true}
                        styles={customStyles}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={6} md={4} xl={3} className='mb-3'>
                    <FormGroup style={{ position: 'relative' }}>
                      <Input
                        type='text'
                        id='searchInput'
                        placeholder='Search By Student Name'
                        value={formData.studentname}
                        onChange={handleSearchChange}
                        style={{ paddingLeft: '2.5rem' }}
                      />
                      <FaSearch
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '10px',
                          transform: 'translateY(-50%)',
                          color: '#495057'
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card>
          <div
                style={{
                  padding: '15px 15px 25px 15px',
                  backgroundColor: '#ffffff'
                }}
              >
                <CardTitle tag='h3' className='mb-3 text-center'>
                Student Fees Status Report
                </CardTitle>
                <div className='mb-1'>
                  <span style={{ fontWeight: 'bold', fontSize: '1.0em' }}>
                    Total Records: {students.length}{' '}
                  </span>
                </div>
                <div style={{  maxHeight: '492px', overflowY: 'auto', borderTop:'1px solid #DEE2E6' ,borderLeft:'1px solid #DEE2E6'}} className=' position-relative table-container'>
                <Table bordered striped  className=' mb-0 pb-0'>


                  {/* <thead>
                    <tr
                      style={{ fontWeight: 'bold', backgroundColor: '#e9ecef' }}
                    > */}
                      {/* <td colSpan={1} style={{ textAlign: 'left' }}>
       Total Students: {students.length}
      </td> */}
                    {/* </tr>
                  </thead> */}



                  <thead
                    style={{ backgroundColor: '#f8f9fa', color: '#495057' }} className='head-stick2'
                  >
                    <tr>
                      <th className="fixed-column">S. No.</th>
                      <th className="fixed-column">Student Name</th>
                      {/* <th>Roll No.</th> */}
                      <th>Academic Year</th>
                      <th>Branch</th>
                      <th>Class</th>
                      <th>Register No.</th>
                      <th>Fees Status</th>
                    </tr>
                  </thead>

                  
                  {/* <tbody>
          {students.length > 0 ? (
            students.map(student => (
              <tr key={student._id}>
                <td>{student.roll}</td>
                <td>
                  <Link to={`/singlestudent/${student._id}`} style={{ textDecoration: 'none', color: '#fb0f01' }}>
                  {student.firstName} {student.lastName}
                  </Link>
                </td>
                <td>{student.academicyear_id?.year}</td>
                <td>{student.branch_id?.branchName}</td>
                <td>{student.class_id?.className} {student.section_id ? ` (${student.section_id.sectionName})` : ''}</td>
                <td>{student.registerNo}</td>
                <td style={{ color: 
        student.Status === "Partial" ? "#3166ff" : 
        student.Status === "Unpaid" ? "red" : 
        student.Status === "Paid" ? "green" : "inherit"
      }}>
    <Link to={`/receivable/create/${student._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
        {student.Status}
    </Link>
</td> 
                      </tr>
                    ))
                  ) : (
                    <tr>
                    <td colSpan="7" className="text-center">
                      <FaFileAlt className='mt-3' size={70} color="#888" />
                      <h4 className="alert-heading mb-3 mt-3">No Students Found</h4>
                    </td>
                  </tr>
                  )}
                  <tr style={{ fontWeight: 'bold', backgroundColor: '#e9ecef' }}>
      <td colSpan={1} style={{ textAlign: 'left' }}>
      Total Students: {students.length} 
      </td>
      
    </tr>
          </tbody> */}
                  <tbody>
                    {students.length > 0 ? (
                      students
                        .sort((a, b) => {
                          const nameA =
                            `${a.firstName} ${a.lastName}`.toLowerCase()
                          const nameB =
                            `${b.firstName} ${b.lastName}`.toLowerCase()
                          if (nameA < nameB) return -1
                          if (nameA > nameB) return 1
                          return 0
                        })
                        .map((student, index) => (
                          <tr key={student._id}>
                            <td className="fixed-column">{index + 1}</td> {/* Sr. No. Column */}
                            <td className="fixed-column">
                              <Link
                                to={`/singlestudent/${student._id}`}
                                style={{
                                  textDecoration: 'none',
                                  color: '#fb0f01'
                                }}
                              >
                                {student.firstName} {student.lastName}
                              </Link>
                              {` (Roll No. ${student.roll})`}
                            </td>
                            {/* <td>{student.roll}</td> */}
                            <td>{student.academicyear_id?.year}</td>
                            <td>{student.branch_id?.branchName}</td>
                            <td>
                              {student.class_id?.className}{' '}
                              {student.section_id
                                ? ` (${student.section_id.sectionName})`
                                : ''}
                            </td>
                            <td>{student.registerNo}</td>
                            <td
                              style={{
                                color:
                                  student.Status === 'Partial Payment'
                                    ? '#3166ff'
                                    : student.Status === 'Unpaid'
                                    ? 'red'
                                    : student.Status === 'Paid'
                                    ? 'green'
                                    : 'inherit'
                              }}
                            >
                              <Link
                                to={`/receivable/create/${student._id}`}
                                style={{
                                  textDecoration: 'none',
                                  color: 'inherit'
                                }}
                              >
                                {student.Status}
                              </Link>
                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td colSpan='7' className='text-center'>
                          <FaFileAlt className='mt-3' size={70} color='#888' />
                          <h4 className='alert-heading mb-3 mt-3'>
                            No Students Found
                          </h4>
                        </td>
                      </tr>
                    )}
                    {/* <tr style={{ fontWeight: 'bold', backgroundColor: '#e9ecef' }}>
    <td colSpan={1} style={{ textAlign: 'left' }}>
      Total Students: {students.length} 
    </td>
  </tr> */}
                  </tbody>
                </Table>
                </div>
                {/* <div className='mt-1 mb-3'
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1.0em',
                  }}
                >
                  Total Records: {students.length}{' '}
                </div> */}

                <style jsx='true'>{`

                    .head-stick {
                    position: sticky;
                        top: -2px;
                        background-color: #fff;
                        z-index: 2;
                    }
                    .head-stick-bottom {
                    position: sticky;
                        bottom: -1px;
                        background-color: #fff;
                        z-index: 2;
                    }
                    .head-stick2 {
                    position: sticky;
                        top: -1px;
                        background-color: #fff;
                        z-index: 2;
                    }

                      .fixed-column {
                        position: sticky;
                        left: 0;
                        background-color: #fff;
                        z-index: 2;
                      }

                      .fixed-column:nth-child(2) {
                        left: 50px; /* Adjust based on the column width */
                      }

                      .table-container tbody td.fixed-column,
                      .table-container thead th.fixed-column {
                        position: sticky;
                        left: -2px;
                        background-color: #fff;
                        z-index: 1;
                      }

                      .table-container tbody td.fixed-column:nth-child(2),
                      .table-container thead th.fixed-column:nth-child(2) {
                        left: 57px;
                      }

                    table td {
                      white-space: nowrap;  
                    }
                    table th {
                      white-space: nowrap;
                    }

                      @media (max-width: 992px) {
                      
                      .fixed-column {
                        position: static;
                        left: 0;
                        background-color: #fff;
                        z-index: 2;
                      }

                      .fixed-column:nth-child(2) {
                        left: 50px; /* Adjust based on the column width */
                      }

                      .table-container tbody td.fixed-column,
                      .table-container thead th.fixed-column {
                        position: static;
                        left: -2px;
                        background-color: #fff;
                        z-index: 1;
                      }

                      .table-container tbody td.fixed-column:nth-child(2),
                      .table-container thead th.fixed-column:nth-child(2) {
                        left: 57px;
                      }
                    }


                    @media (max-width: 768px) {
                      table td,
                      table th {
                        padding: 5px;
                        white-space: nowrap;
                      }
                    }

                    @media (max-width: 576px) {
                      table td,
                      table th {
                        white-space: nowrap;
                      }
                    }
                    `}</style>
              </div>
              {/* {students.length > 0 && (
        <div className="d-flex justify-content-end align-items-center px-3 py-2">
          <Button
            style={{ border: "none" }}
            color="primary"
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          >
            &lt;
          </Button>
          <span className="mx-2">{currentPage}</span>
          <Button
            style={{ border: "none", borderRadius:"0px"}}
            color="primary"
            disabled={currentPage === Math.ceil(totalStudents / studentsPerPage)}
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(totalStudents / studentsPerPage)))}
          >
            &gt;
          </Button>
        </div>
      )} */}
            
        </>
      )}
    </>
  )
}

export default Students
