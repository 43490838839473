import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  Table,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../../middleware/axiosInstance";
import Select from "react-select";
import Swal from "sweetalert2";
import { FaRegEdit } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";

const CreateExam = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    examName: "",
    branchId: null,
    classId: null,
    academicYearId: null,
    sectionId: [],
    examTypeId: [],
    grades: [],
  });

  const [academicYears, setAcademicYears] = useState([]);
  const [branches, setBranches] = useState([]);
  const [classes, setClasses] = useState([]);
  const [examType, setExamType] = useState([]);
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [isExamTypeChecked, setIsExamTypeChecked] = useState(false);

  const [errors, setErrors] = useState({
    examName: "",
  });

  useEffect(() => {
    const fetchAcademicYears = async () => {
      try {
        const response = await axiosInstance.get("/academicyears");
        setAcademicYears(
          response.data.map((ay) => ({
            value: ay._id,
            label: ay.year,
          }))
        );
      } catch (error) {
        console.error("Error fetching academic years:", error);
      }
    };
    fetchAcademicYears();
  }, []);

  // Fetch branches
  const fetchBranches = async () => {
    try {
      const response = await axiosInstance.get("/branches");
      setBranches(
        response.data.map((branch) => ({
          value: branch._id,
          label: branch.branchName,
        }))
      );
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  useEffect(() => {
    fetchBranches();
  }, []);

  // Fetch classes based on selected branch
  const fetchClasses = async () => {
    try {
      const response = await axiosInstance.get("/classes/branch", {
        params: { branchId: formData.branchId },
      });
      setClasses(
        response.data.map((classObj) => ({
          value: classObj._id,
          label: classObj.className,
        }))
      );
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  useEffect(() => {
    if (formData.branchId) {
      fetchClasses();
    } else {
      // Reset class, section, and academic year when branch changes
      setClasses([]);
      setSections([]);
      setExamType([]);
      // setAcademicYears([]); // Clear academic years if needed
      setFormData((prev) => ({
        ...prev,
        classId: null,
        sectionId: [],
        examTypeId: [],
        academicYearId: null,
      }));
    }
  }, [formData.branchId]);

  // Fetch Sections when branchId and classId are selected
  useEffect(() => {
    const fetchSections = async () => {
      if (formData.branchId && formData.classId) {
        try {
          const response = await axiosInstance.get("/sections", {
            params: {
              branchId: formData.branchId,
              classId: formData.classId,
            },
          });

          // Sorting sections by sectionName
          const sortedSections = response.data.sort((a, b) =>
            a.sectionName.localeCompare(b.sectionName)
          );

          // Map sorted sections to the desired format
          setSections(
            sortedSections.map((section) => ({
              value: section._id,
              label: section.sectionName,
            }))
          );
        } catch (error) {
          // console.log("Failed to fetch sections.");
        }
      } else {
        setSections([]);
      }
    };

    fetchSections();
  }, [formData.branchId, formData.classId]);

  useEffect(() => {
    const fetchExamTypes = async () => {
      if (formData.branchId) {
        try {
          const response = await axiosInstance.get("/exam/examtypes/branch", {
            params: { branchId: formData.branchId },
          });

          // Set the response data in the `examType` state
          setExamType(
            response.data.map((exam) => ({
              value: exam._id,
              label: exam.examType,
            }))
          );
        } catch (error) {
          // console.error("Error fetching exam types:", error);
        }
      } else {
        // Reset exam types if no branch selected
        setExamType([]);
      }
    };

    fetchExamTypes();
  }, [formData.branchId]);



  const handleSelectChange = (selectedOption, { name }) => {
    setFormData((prev) => {
      if (name === "branchId") {
        // Reset related fields when branch changes
        return {
          ...prev,
          branchId: selectedOption ? selectedOption.value : null,
          classId: null, // Reset class
          sectionId: [], // Reset section
          academicYearId: null, // Reset academic year
          examTypeId: [], // Reset examTypeId
        };
      }
      return {
        ...prev,
        [name]: selectedOption ? selectedOption.value : null,
      };
    });

    // Reset exam types when branch changes
    if (name === "branchId") {
      setExamType([]); // Clear existing exam types
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "examName") {
      const words = value.trim().split(/\s+/);
      const wordCount = words.length;

      if (wordCount > 40) {
        setErrors({
          ...errors,
          [name]: "Exam Name must be less than 40 words.",
        });
        return;
      } else if (words.some((word) => word.length > 50)) {
        setErrors({
          ...errors,
          [name]: "Each word must be less than 50 characters.",
        });
        return;
      }
      setFormData({ ...formData, [name]: value });
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleCreateExamType = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const payload = {
        ...formData,
        sectionIds: formData.sectionId || [],
        examTypeIds: formData.examTypeId || [],
      };
      await axiosInstance.post("/exam/create/exam", payload);
      navigate("/exams");
    } catch (error) {
      console.error("Error creating exam type:", error);
    }
    setLoading(false);
  };

  const handleCancel = () => {
    navigate("/exams");
  };

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#FB0F01" : provided.borderColor,
      boxShadow: state.isFocused ? "0 0 0 1px #FB0F01" : null,
      borderWidth: state.isFocused ? "0px" : "1px",
      transition: "border-color 0.2s, box-shadow 0.2s",
      "&:hover": {
        borderColor: "#FB0F01",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#FFFFFF",
      zIndex: 999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FDD7C2" : "#FFFFFF",
      color: "black",
      "&:hover": {
        backgroundColor: "#FDD7C2",
        color: "black",
      },
      "&:active": {
        backgroundColor: "#E8F0FE",
      },
    }),
  };

  const addGrade = () => {
    const { grade, max_mark, min_mark } = formData;

    // Validation for empty fields
    const isMinMarkExist = formData.grades.some(
      (g) => g.min_mark === parseFloat(min_mark)
    );
    const isMaxMarkExist = formData.grades.some(
      (g) => g.max_mark === parseFloat(max_mark)
    );
    // const isGradeExist = formData.grades.some((g) => g.grade === grade);
    const isGradeExist = formData.grades.some(
      (g) => g.grade.trim().toLowerCase() === grade.trim().toLowerCase() // Normalize both to lowercase and trim spaces before comparison
    );
    const isMaxMarkExistwithMax = formData.grades.some(
      (g) => g.max_mark === parseFloat(min_mark)
    );
    const isMaxMarkExistwithMin = formData.grades.some(
      (g) => g.min_mark === parseFloat(max_mark)
    );

    const isRangeOverlap = formData.grades.some((existingGrade) => {
      // Check if the new range [min_mark, max_mark] overlaps with any existing range
      return (
        (parseFloat(min_mark) >= existingGrade.min_mark &&
          parseFloat(min_mark) <= existingGrade.max_mark) || // Min mark within existing range
        (parseFloat(max_mark) >= existingGrade.min_mark &&
          parseFloat(max_mark) <= existingGrade.max_mark) || // Max mark within existing range
        (parseFloat(min_mark) <= existingGrade.min_mark &&
          parseFloat(max_mark) >= existingGrade.max_mark) // New range fully covers existing range
      );
    });

    if (isRangeOverlap) {
      Swal.fire({
        title: "Invalid Range!",
        text: "The new grade range overlaps with an existing range.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }
    if (isMaxMarkExistwithMax) {
      Swal.fire({
        title: "Duplicate Min percentage!",
        text: "A grade with the same max percentage already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }
    if (isMaxMarkExistwithMin) {
      Swal.fire({
        title: "Duplicate Min percentage!",
        text: "A grade with the same Min percentage already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (isMinMarkExist) {
      Swal.fire({
        title: "Duplicate Min percentage!",
        text: "A grade with the same Min percentage already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (isMaxMarkExist) {
      Swal.fire({
        title: "Duplicate Max percentage!",
        text: "A grade with the same Max percentage already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (isGradeExist) {
      Swal.fire({
        title: "Duplicate Grade!",
        text: "A grade with the same name already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (!grade || !max_mark || !min_mark) {
      Swal.fire({
        title: "Incomplete Information",
        text: "All fields must be filled out",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }
    if (grade.length > 25) {
      Swal.fire({
        title: "Invalid Grade!",
        text: "Grade must be 25 characters or fewer.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Validate that max_mark and min_mark are valid integers
    if (
      isNaN(max_mark) ||
      isNaN(min_mark) ||
      !Number.isInteger(parseFloat(max_mark)) ||
      !Number.isInteger(parseFloat(min_mark))
    ) {
      Swal.fire({
        title: "Invalid Number!",
        text: "Max Marks and Min Marks must be valid Numbers.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Validate that max_mark is an integer and <= 100 (percentage)
    if (parseFloat(max_mark) > 100) {
      Swal.fire({
        title: "Invalid percentage!",
        text: "Maximum percentage cannot be greater than 100.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Validate that min_mark is not greater than max_mark
    if (parseFloat(min_mark) > parseFloat(max_mark)) {
      Swal.fire({
        title: "Invalid percentage!",
        text: "Minimum percentage cannot be greater than Maximum percentage.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // NEW VALIDATION: Check if max_mark is equal to min_mark
    if (parseFloat(min_mark) === parseFloat(max_mark)) {
      Swal.fire({
        title: "Invalid percentage!",
        text: "Minimum percentage cannot be the same as Maximum percentage.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Create the new grade object
    const newGrade = {
      grade,
      max_mark: parseInt(max_mark),
      min_mark: parseInt(min_mark),
    };

    // Update the grades array in formData
    setFormData((prevData) => ({
      ...prevData,
      grades: [...prevData.grades, newGrade],
      grade: "", // Reset form fields
      max_mark: "",
      min_mark: "",
    }));
  };

  const deleteGrade = (index, _id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this grade?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        // Delete the grade from the grades array
        const updatedGrades = formData.grades.filter((_, i) => i !== index);
        setFormData((prevData) => ({
          ...prevData,
          grades: updatedGrades,
        }));
        Swal.fire("Deleted!", "The grade has been deleted.", "success");
      }
    });



  if(index == editIndex)
    {
      setIsEditing(false)
      setFormData((prevState) => ({
        ...prevState,
        grade: "",
        max_mark: "",
        min_mark: "",
      }));
    }
};



  const editGrade = (index, _id) => {
    // Get the selected grade object from the grades array
    const selectedGrade = formData.grades[index];

    // Set the form fields with the selected grade values
    setFormData({
      ...formData,
      grade: selectedGrade.grade,
      max_mark: selectedGrade.max_mark,
      min_mark: selectedGrade.min_mark,
      _id: selectedGrade._id,
      indexofList: index,
    });

    setIsEditing(true);
    setEditIndex(index);
  };

  const submitEditedGrade = () => {
    const { grade, max_mark, min_mark, indexofList } = formData;


  const isGradeExist = formData.grades.some(
  (g, index) =>
    g.grade.trim().toLowerCase() === grade.trim().toLowerCase() && // Normalize both to lowercase and trim spaces before comparison
    (index !== indexofList || // Exclude the grade being edited
     g.grade.trim().toLowerCase() !== formData.grades[indexofList]?.grade.trim().toLowerCase()) // Ensure the same grade hasn't been selected as the one being edited
);






    // If the grade has not been changed, allow it to be saved as is
    const isGradeUnchanged = formData.grades.some(
      (g) =>
        g.indexofList === indexofList &&
        g.grade === grade &&
        g.max_mark === parseFloat(max_mark) &&
        g.min_mark === parseFloat(min_mark)
    );

    // If the grade is unchanged, skip the duplicate check and proceed with the update
    if (isGradeUnchanged) {
      const updatedGrades = [...formData.grades];
      const gradeIndex = updatedGrades.findIndex(
        (g) => g.indexofList === indexofList
      );

      if (gradeIndex !== -1) {
        // Update only the specific grade
        updatedGrades[gradeIndex] = { grade, max_mark, min_mark, indexofList };
      } else {
        Swal.fire({
          title: "Grade Not Found",
          text: "Grade not found for editing.",
          icon: "warning",
          confirmButtonText: "OK",
        });
        return;
      }

      // Update state with the new grades array
      setFormData((prevData) => ({
        ...prevData,
        grades: updatedGrades,
        grade: "", // Reset form fields
        max_mark: "",
        min_mark: "",
      }));

      // Exit edit mode
      setIsEditing(false);
      setEditIndex(null);

      // Show success message
      Swal.fire({
        title: "Success!",
        text: "Grade successfully updated.",
        icon: "success",
        confirmButtonText: "OK",
      });
      return; // Exit function here if no changes
    }

    // If grade already exists (excluding the current grade being edited)
    if (isGradeExist) {
      Swal.fire({
        title: "Duplicate Grade!",
        text: "A grade with this name already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return; // Exit function if duplicate grade is found
    }

    // Validate Max and Min Marks
    const isMaxMarkExistwithMax = formData.grades.some(
      (g) => g.max_mark === parseFloat(min_mark)
    );
    const isMaxMarkExistwithMin = formData.grades.some(
      (g) => g.min_mark === parseFloat(max_mark)
    );

    if (isMaxMarkExistwithMax) {
      Swal.fire({
        title: "Duplicate Max percentage!",
        text: "A grade with the same max percentage already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (isMaxMarkExistwithMin) {
      Swal.fire({
        title: "Duplicate Min percentage!",
        text: "A grade with the same Min percentage already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    const isRangeOverlap =
      formData.grades.length > 1 && // Ensure there is more than one entry
      (parseFloat(max_mark) !==
        parseFloat(formData.grades[indexofList]?.max_mark) ||
        parseFloat(min_mark) !==
          parseFloat(formData.grades[indexofList]?.min_mark)) // Check only if values have changed
        ? formData.grades.some((existingGrade, idx) => {
            // Skip checking against the current grade being edited
            if (idx === indexofList) return false;

            return (
              (parseFloat(min_mark) >= existingGrade.min_mark &&
                parseFloat(min_mark) <= existingGrade.max_mark) || // Min mark within existing range
              (parseFloat(max_mark) >= existingGrade.min_mark &&
                parseFloat(max_mark) <= existingGrade.max_mark) || // Max mark within existing range
              (parseFloat(min_mark) <= existingGrade.min_mark &&
                parseFloat(max_mark) >= existingGrade.max_mark) // New range fully covers existing range
            );
          })
        : false;

    if (isRangeOverlap) {
      Swal.fire({
        title: "Invalid Range!",
        text: "The new grade range overlaps with an existing range.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Validation for empty fields
    if (
      grade === "" ||
      max_mark === "" ||
      min_mark === "" ||
      grade == null ||
      max_mark == null ||
      min_mark == null
    ) {
      Swal.fire({
        title: "Incomplete Information",
        text: "All fields must be filled out.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (grade.length > 25) {
      Swal.fire({
        title: "Invalid Grade!",
        text: "Grade must be 25 characters or fewer.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (isNaN(max_mark) || isNaN(min_mark)) {
      Swal.fire({
        title: "Invalid percentage!",
        text: "Max percentage and Min percentage must be valid numbers.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (parseFloat(max_mark) > 100) {
      Swal.fire({
        title: "Invalid percentage!",
        text: "Maximum percentage cannot be greater than 100.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (!Number.isInteger(parseFloat(min_mark))) {
      Swal.fire({
        title: "Invalid percentage!",
        text: "Minimum percentage must be a valid integer.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (parseFloat(min_mark) > parseFloat(max_mark)) {
      Swal.fire({
        title: "Invalid percentage!",
        text: "Minimum percentage cannot be greater than Maximum Marks.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Update the grades array with the edited grade
    const updatedGrades = [...formData.grades];

    // Find the grade to update and replace it in the array (using _id)
    const gradeIndex = updatedGrades.findIndex((g, idx) => idx === indexofList);

    if (gradeIndex !== -1) {
      updatedGrades[gradeIndex] = { grade, max_mark, min_mark, indexofList };
    } else {
      Swal.fire({
        title: "Grade Not Found",
        text: "Grade not found for editing.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Update state with the new grades array
    setFormData((prevData) => ({
      ...prevData,
      grades: updatedGrades,
      grade: "", // Reset form fields
      max_mark: "",
      min_mark: "",
    }));

    // Exit edit mode
    setIsEditing(false);
    setEditIndex(null);

    // Show success message
    Swal.fire({
      title: "Success!",
      text: "Grade successfully updated.",
      icon: "success",
      confirmButtonText: "OK",
    });
  };


  function getResponsiveFontSize() {
    const screenWidth = window.innerWidth;
  
    if (screenWidth < 576) {
      return "1rem"; // Extra small screens
    } else if (screenWidth >= 576 && screenWidth < 768) {
      return "1rem"; // Small screens
    } else if (screenWidth >= 768 && screenWidth < 992) {
      return "0.97rem"; // Medium screens
    } else {
      return "0.93rem"; // Larger screens
    }
  }
  

  const renderTable = () => (
    <div>
      <div style={{ overflowX: "auto" }}>
          <Table bordered striped responsive>
            <thead style={{ backgroundColor: "#f8f9fa", color: "#495057" }}>
              <tr>
                <th>% From</th>
                <th>% To</th>
                <th>Grade</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(formData.grades) && formData.grades.length > 0 ? (
                formData.grades.map((grade, index) => (
                <tr key={index}>
                  <td>{grade.min_mark}</td>
                  <td>{grade.max_mark}</td>
                  <td>{grade.grade}</td>
                  <td>
                    <FaRegEdit
                      title="Edit"
                      onClick={() => editGrade(index, grade._id)} // Edit action
                      style={{ cursor: "pointer", margin: "0 6px" }}
                    />
                    <AiOutlineDelete
                      title="Delete"
                      onClick={() => deleteGrade(index, grade._id)} // Delete action
                      style={{ cursor: "pointer", margin: "0 6px" }}
                    />
                  </td>
                </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="h5 py-3 text-center">
                    No grades available
                  </td>
                </tr>
              )}
            </tbody>
            <style jsx="true">{`
              table td {
                white-space: nowrap;
              }
              table th {
                white-space: nowrap;
              }

              @media (max-width: 768px) {
                table td,
                table th {
                  padding: 5px;
                  white-space: nowrap;
                }
              }

              @media (max-width: 576px) {
                table td,
                table th {
                  white-space: nowrap;
                }
              }
            `}</style>
          </Table>
      </div>
    </div>
  );

  return (
    <div>
      <Card style={{ margin: "0 auto" }}>
        <CardBody>
          <CardTitle tag="h3" className="mb-1 text-center">
            Create Exam
          </CardTitle>
          <Form className="mt-5" onSubmit={handleCreateExamType}>
            <Row>
              <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="branch">
                    <span style={{ fontWeight: "bold" }}>Branch*</span>
                  </Label>
                  <Select
                    name="branchId"
                    options={branches}
                    value={
                      branches.find(
                        (branch) => branch.value === formData.branchId
                      ) || null
                    }
                    onChange={handleSelectChange}
                    isClearable
                    placeholder="Select a Branch"
                    required
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>

              <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="class">
                    <span style={{ fontWeight: "bold" }}>Class*</span>
                  </Label>
                  <Select
                    name="classId"
                    options={classes}
                    value={
                      classes.find((cls) => cls.value === formData.classId) ||
                      null
                    }
                    onChange={handleSelectChange}
                    isClearable
                    placeholder="Select a Class"
                    required
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>

              <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="section">
                    <span style={{ fontWeight: "bold" }}>Section*</span>
                  </Label>
                  <Select
                    name="sectionId"
                    options={sections}
                    value={sections.filter((section) =>
                      Array.isArray(formData.sectionId)
                        ? formData.sectionId.includes(section.value)
                        : formData.sectionId === section.value
                    )}
                    onChange={(selectedOptions) => {
                      const selectedValues = selectedOptions
                        ? selectedOptions.map((option) => option.value)
                        : []; // Always set as an array
                      setFormData((prev) => ({
                        ...prev,
                        sectionId: selectedValues, // Ensure it's always an array
                      }));
                    }}
                    isMulti
                    isClearable
                    placeholder={
                      sections.length === 0 ? "No Section" : "Select a Section"
                    }
                    required={sections.length > 0}
                    isDisabled={sections.length === 0}
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>

              <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="academicYearId">
                    <span style={{ fontWeight: "bold" }}>Academic Year*</span>
                  </Label>
                  <Select
                    name="academicYearId"
                    options={academicYears}
                    value={
                      academicYears.find(
                        (ay) => ay.value === formData.academicYearId
                      ) || null
                    }
                    onChange={handleSelectChange}
                    isClearable
                    placeholder="Select Academic Year"
                    required
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>

              <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="examName">
                    <span style={{ fontWeight: "bold" }}>Exam Name*</span>
                  </Label>
                  <Input
                    type="text"
                    name="examName"
                    id="examName"
                    value={formData.examName}
                    onChange={handleChange}
                    required
                    placeholder="Enter Exam Name"
                  />
                  {errors.examName && (
                    <div style={{ color: "red", fontSize: "0.875rem" }}>
                      {errors.examName}
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col xs={12} sm={6} md={4} xl={3} className="mb-2 position-relative pe-0">
                <FormGroup check className="mt-sm-4 pt-sm-3 ms-1  ">
                  <Input
                  style={{fontSize:'18px' , marginTop:'4px'}}
                    type="checkbox"
                    id="isExamType"
                    checked={isExamTypeChecked}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setIsExamTypeChecked(isChecked);
                
                      if (!isChecked) {
                        // Reset exam types in formData when checkbox is unchecked
                        setFormData((prev) => ({
                          ...prev,
                          examTypeId: [], // Clear the selected exam types
                        }));
                      }
                    }}
                  />
                  <Label for="isExamType" check>
                  <span
                    style={{
                      fontWeight: "bold",
                      marginLeft: "-3px",
                      fontSize: getResponsiveFontSize(), // Dynamically calculated font size
                      whiteSpace: "nowrap", // Prevent wrapping
                      overflow: "hidden", // Hide overflow
                      textOverflow: "ellipsis", // Add ellipsis for overflowed text
                    }}
                  >
                    Is Exam Type Applicable?
                  </span>
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="By default, the exam is considered written. Enable and select multiple exam types."
                      className="ms-1"
                      style={{
                        cursor: "pointer",
                        fontSize: "1rem",
                        color: "#fb0f01",
                      }}
                    >
                      <i className="bi bi-info-circle"></i>
                    </span>
                  </Label>
                </FormGroup>
              </Col>

              {isExamTypeChecked && (
                <Col xs={12} sm={6} md={4} xl={3}>
                  <FormGroup>
                    <Label for="examType">
                      <span style={{ fontWeight: "bold" }}>Exam Types</span>
                    </Label>
                    <Select
                      name="examTypeId"
                      options={examType}
                      value={examType.filter((type) =>
                        Array.isArray(formData.examTypeId)
                          ? formData.examTypeId.includes(type.value)
                          : formData.examTypeId === type.value
                      )}
                      onChange={(selectedOptions) => {
                        const selectedValues = selectedOptions
                          ? selectedOptions.map((option) => option.value)
                          : []; // Always set as an array
                        setFormData((prev) => ({
                          ...prev,
                          examTypeId: selectedValues, // Ensure it's always an array
                        }));
                      }}
                      isMulti
                      isClearable
                      placeholder={
                        examType.length === 0
                          ? "No Exam Type"
                          : "Select if applicable"
                      }
                      isDisabled={examType.length === 0}
                      styles={customStyles}
                    />
                  </FormGroup>
                </Col>
              )}
            </Row>

            <div>
              <Card style={{ margin: "0 auto" }} className=" shadow-none">
                <CardBody className="p-0 pb-2">
                  <div className="d-flex justify-content-between align-items-center pt-1">
                    <CardTitle className="mt-2 ms-1 mb-0" tag="h4">
                      Add Grade
                    </CardTitle>
                  </div>

                  <Form className="mt-1 ">
                    <Row
                      className="mt-2 pt-2  m-0"
                      style={{
                        border: "1px solid #ccc", // Border around the row
                        padding: "10px 0", // Padding inside the row
                        margin: "5px", // Margin after the border (space between rows)
                      }}
                    >
                      <Col xs={12} sm={6} md={4} xl={3}>
                        <FormGroup>
                          <Label for="min_mark">
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "0.95rem",
                              }}
                            >
                              Minimum Percentage
                            </span>
                          </Label>
                          <Input
                            type="text"
                            name="min_mark"
                            id="min_mark"
                            placeholder="Min Percentage"
                            value={formData.min_mark}
                            onChange={handleChange}
                            className=" ps-2"
                            style={{
                              padding: "4px",
                              fontSize: "0.95rem",
                              height: "37px",
                              borderRadius: "4px",
                              border: "1px solid #ccc",
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col xs={12} sm={6} md={4} xl={3}>
                        <FormGroup>
                          <Label for="max_mark">
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "0.95rem",
                              }}
                            >
                              Maximum Percentage
                            </span>
                          </Label>
                          <Input
                            type="text"
                            name="max_mark"
                            id="max_mark"
                            placeholder="Max Percentage"
                            value={formData.max_mark}
                            onChange={handleChange}
                            className=" ps-2"
                            style={{
                              padding: "4px",
                              fontSize: "0.95rem",
                              height: "37px",
                              borderRadius: "4px",
                              border: "1px solid #ccc",
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col xs={12} sm={6} md={4} xl={3}>
                        <FormGroup>
                          <Label for="grade">
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "0.95rem",
                              }}
                            >
                              Grade
                            </span>
                          </Label>
                          <Input
                            type="text"
                            name="grade"
                            id="grade"
                            placeholder="Grade"
                            value={formData.grade}
                            onChange={handleChange}
                            className=" ps-2"
                            style={{
                              padding: "4px",
                              fontSize: "0.95rem",
                              height: "37px",
                              borderRadius: "4px",
                              border: "1px solid #ccc",
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col xs={12} sm={6} md={3} xl={2} className="mt-2">
                        {!isEditing && (
                          <Button
                            color="primary"
                            className="mt-xl-4 mt-sm-4 mt-md-0 btn-no-radius mb-2"
                            onClick={addGrade} // Assuming you have this handler
                          >
                            Add Grade
                          </Button>
                        )}
                        {isEditing && (
                          <Button
                            color="primary"
                            className="mt-xl-4 btn-no-radius mb-2"
                            onClick={submitEditedGrade} // Assuming you have this handler
                          >
                            Edit Grade
                          </Button>
                        )}
                      </Col>

                      {renderTable()}
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </div>

            <div className="d-flex justify-content-center mt-3">
              <Button
                className="btn-no-radius"
                type="submit"
                color="primary"
                disabled={loading}
              >
                {loading ? <Spinner size="sm" /> : "Save"}
              </Button>
              <Button
                className="btn-no-radius mx-2"
                color="secondary"
                onClick={handleCancel}
                disabled={loading}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default CreateExam;
