import React, { useState, useEffect } from 'react'
import {
  Card,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
  Row,
  Col,
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import { FaSearch, FaFileAlt } from 'react-icons/fa'
import { AiOutlineFilter } from 'react-icons/ai'

import { useNavigate, NavLink } from 'react-router-dom'
import axiosInstance from '../../../../middleware/axiosInstance'
import Swal from 'sweetalert2'
import Select from 'react-select'
import 'react-datepicker/dist/react-datepicker.css'
import { Link } from 'react-router-dom'
import { debounce } from 'lodash'

const FeesReport = () => {
  const [formData, setFormData] = useState({
    branchId: '',
    classId: '',
    academicYearId: ''
  })
  const [branches, setBranches] = useState([])
  const [classes, setClasses] = useState([])
  const [academicYears, setAcademicYears] = useState([])
  const [error, setError] = useState('')
  const [noDataFound, setNoDataFound] = useState(false)
  const [feesTypes, setFeesTypes] = useState([])
  const [loading, setLoading] = useState(true)
  const [reportedData, SetReportedData] = useState([])
  // const [searchTerm, setSearchTerm] = useState('')
  const [sections, setSections] = useState([])

  const fetchReportData = async () => {
    try {
      // Create a data object based on the available formData fields
      const requestData = {}

      // Check and add formData fields if they are present
      if (formData?.branchId) requestData.branchId = formData.branchId
      if (formData?.academicYearId)
        requestData.academicYearId = formData.academicYearId
      if (formData?.classId) requestData.classId = formData.classId
      if (formData?.sectionId) requestData.sectionId = formData.sectionId
      if (formData?.feesTypeId) requestData.feesTypeId = formData.feesTypeId
      if (formData.studentname) requestData.studentname = formData.studentname.trim()

      // Send the data as request body
      const response = await axiosInstance.post('/fees/feesReport', requestData)
      SetReportedData(response.data.feeDetails)
    } catch (error) {
      // console.error('Error fetching Data:', error)
      setError('Failed to fetch data.') // Set error state
    }
  }

  
  // Fetch all branches on page load
  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await axiosInstance.get('/branches')
        setBranches(response.data)
      } catch (error) {
        // console.error('Error fetching branches:', error)
        setError('Failed to fetch branches.') // Set error state
      }
    }

    fetchBranches()
  }, [])

  // Fetch classes based on the selected branch
  useEffect(() => {
    const fetchClasses = async () => {
      if (formData.branchId) {
        try {
          const response = await axiosInstance.get('/classes/branch', {
            params: { branchId: formData.branchId }
          })
          setClasses(response.data)
          setFormData(prev => ({ ...prev, classId: '', sectionId: '' }))
        } catch (error) {
          setError('Failed to fetch classes.')
        } finally {
          setLoading(false)
        }
      } else {
        setClasses([])
        setSections([])
        setFormData(prev => ({ ...prev, classId: '', sectionId: '' }))
      }
    }

    fetchClasses()
  }, [formData.branchId])

 
  useEffect(() => {
    
    const getAcademicYears = async () => {
      try {
        const response = await axiosInstance.get("/academicyears");
        setAcademicYears(response.data);

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();  
        const currentDay = currentDate.getDate();

        let defaultAcademicYear;

        if (currentMonth > 4 || (currentMonth === 4 && currentDay >= 1)) {
          // June 1 to December 31, or May 1 to May 31
          
          defaultAcademicYear = `${currentYear}-${String(currentYear + 1).slice(
            -2
          )}`;
        } else {
          defaultAcademicYear = `${currentYear - 1}-${String(currentYear).slice(
            -2
          )}`;
        }

         const defaultAcademicYearEntry = response.data.find(
          (year) => year.year === defaultAcademicYear  
        );

        

        if (defaultAcademicYearEntry) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            academicYearId: defaultAcademicYearEntry._id,  
          }));
        }
      } catch (error) {
        console.error("Error fetching academic years:", error);
      }finally {
        setLoading(false);  
      }
    };

    getAcademicYears();
  }, []);



  useEffect(() => {
    const fetchSections = async () => {
      if (formData.branchId && formData.classId) {
        try {
          const response = await axiosInstance.get('/sections', {
            params: {
              branchId: formData.branchId,
              classId: formData.classId
            }
          })
          const sortedSections = response.data.sort((a, b) =>
            a.sectionName.localeCompare(b.sectionName)
          )
          setSections(sortedSections)
        } catch (error) {
          setError('Failed to fetch sections.')
        }
      } else {
        setSections([])
      }
    }
    fetchSections()
  }, [formData.branchId, formData.classId])

  useEffect(() => {
    const fetchFeesTypes = async () => {
      try {
        let response
        if (formData.branchId || formData.academicYearId || formData.classId) {
          // Fetch fees types filtered by branch, class, and academic year
          response = await axiosInstance.get('/feestype', {
            params: {
              branchId: formData.branchId,
              classId: formData.classId || null, // Allow classId to be null
              academicYearId: formData.academicYearId || null // New filter by academic year
            }
          })
        } else {
          // Fetch all fees types when no filter is selected
          response = await axiosInstance.get('/feestype')
        }

        setFeesTypes(response.data)
        setNoDataFound(response.data.length === 0)
      } catch (error) {
        setError('Failed to fetch fees types.')
      } finally {
        setLoading(false)
      }
    }

    fetchFeesTypes() // Fetch fees types when branchId, classId, or academicYearId changes
  }, [formData.branchId, formData.classId, formData.academicYearId])

  const handleChange = ({ target: { name, value } }) => {
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))

    if (name === 'branchId') {
      // setClasses([]);
      setFormData(prev => ({ ...prev, classId: '' }))
      setNoDataFound(false)
    } else if (name === 'classId' || name === 'academicYearId') {
      setNoDataFound(false)
    }
  }


  const handleSearchChange = (event) => {
  
    setFormData({
      ...formData,
      studentname: event.target.value
    });
  };

  useEffect(() => {
  
    fetchReportData()
  }, [formData.branchId, formData.classId, formData.sectionId,formData.feesTypeId,formData.studentname,formData.academicYearId])

  const customStyles = {
    placeholder: provided => ({
      ...provided,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#FB0F01' : provided.borderColor,
      boxShadow: state.isFocused ? '0 0 0 1px #FB0F01' : null,
      borderWidth: state.isFocused ? '0px' : '1px',
      transition: 'border-color 0.2s, box-shadow 0.2s',
      '&:hover': {
        borderColor: '#FB0F01'
      }
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: '#FFFFFF',
      zIndex: 999
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#FDD7C2' : '#FFFFFF',
      color: 'black',
      '&:hover': {
        backgroundColor: '#FDD7C2',
        color: 'black'
      },
      '&:active': {
        backgroundColor: '#E8F0FE'
      }
    })
  }

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggle = () => setDropdownOpen(!dropdownOpen)

  const handleExport = async type => {
    const requestData = {}

    if (formData?.branchId) requestData.branchId = formData.branchId
    if (formData?.academicYearId)
      requestData.academicYearId = formData.academicYearId
    if (formData?.classId) requestData.classId = formData.classId
    if (formData?.sectionId) requestData.sectionId = formData.sectionId
    if (formData?.feesTypeId) requestData.feesTypeId = formData.feesTypeId
    if (formData.studentname) requestData.studentname = formData.studentname.trim()

    if (type === 'pdf') {
      requestData.file = type
      const responsepdf = await axiosInstance.post(
        '/generatereport/',
        requestData
      )
      const buffer = responsepdf.data.buffer
      const byteArray = new Uint8Array(buffer.data)
      const blob = new Blob([byteArray], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'FeesReport.pdf' // Set the file name for download
      document.body.appendChild(a)
      a.click()
      a.remove()
    } else if (type === 'excel') {
      requestData.file = type
      const responsetome = await axiosInstance.post(
        '/generatereport/',
        requestData
      )
      const buffer = responsetome.data.buffer
      const byteArray = new Uint8Array(buffer.data)
      const blob = new Blob([byteArray], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'FeesReport.xlsx'
      document.body.appendChild(a)
      a.click()
      a.remove()
    }
  }

  return (
    <>
      {loading ? (
        <div
          className='d-flex justify-content-center center align-items-center p-4'
          style={{ minHeight: '90vh' }}
        >
          <Spinner color='primary' />
        </div>
      ) : (
        <>
          <Card
            style={{
              boxShadow: 'none',
              marginBottom: '10px',
              borderRadius: '0'
            }}
          >
            <div className='d-flex justify-content-between  align-items-center px-3 pt-1'>
              <CardTitle className='mt-2 no-wrap' tag='h4'>
                Fees Report
              </CardTitle>
              <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle
                  caret
                  className='btn-no-radius custom-dropdown-toggle '
                  color='primary'
                >
                  Export
                </DropdownToggle>
                <DropdownMenu
                  className='p-0 custom-dropdown-menu w-100'
                  style={{
                    backgroundColor: '#FFFFFF',
                    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                    minWidth: '50px'
                  }}
                >
                  <DropdownItem
                    onClick={() => handleExport('pdf')}
                    style={{
                      backgroundColor: 'white',
                      color: 'black',
                      padding: '10px 15px',
                      cursor: 'pointer',
                      transition: 'background-color 0.2s'
                    }}
                    onMouseOver={e =>
                      (e.target.style.backgroundColor = '#FDD7C2')
                    }
                    onMouseOut={e => (e.target.style.backgroundColor = 'white')}
                  >
                    PDF
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => handleExport('excel')}
                    style={{
                      backgroundColor: 'white',
                      color: 'black',
                      padding: '10px 15px',
                      cursor: 'pointer',
                      transition: 'background-color 0.2s'
                    }}
                    onMouseOver={e =>
                      (e.target.style.backgroundColor = '#FDD7C2')
                    }
                    onMouseOut={e => (e.target.style.backgroundColor = 'white')}
                  >
                    Excel
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            <hr className='mt-1' />
            <div className='px-3 pt-0'>
              <Form>
                <Row>
                  <Col xs={12} className='mb-3 '>
                    <CardTitle tag='h5' className='d-flex align-items-center'>
                      <AiOutlineFilter className='mr-2' />
                      Filters :
                    </CardTitle>
                  </Col>
                  {/* Academic Year Selector */}
                  <Col xs={12} sm={6} md={4} xl={3} className='mb-3'>
                    <FormGroup>
                      <Select
                        id='academicYear'
                        options={academicYears.map(year => ({
                          value: year._id,
                          label: year.year
                        }))}
                        onChange={selected => {
                          handleChange({
                            target: {
                              name: 'academicYearId',
                              value: selected ? selected.value : '' // Handle null case
                            }
                          })
                        }}
                        value={
                          academicYears.find(
                            year => year._id === formData.academicYearId
                          )
                            ? {
                                value: formData.academicYearId,
                                label: academicYears.find(
                                  year => year._id === formData.academicYearId
                                ).year
                              }
                            : null
                        }
                        placeholder='Academic Year'
                        isSearchable={true}
                        isClearable={true}
                        styles={customStyles}
                      />
                    </FormGroup>
                  </Col>

                  {/* Branch Selector */}
                  <Col xs={12} sm={6} md={4} xl={3} className='mb-3 '>
                    <FormGroup>
                      <Select
                        id='branch'
                        options={branches.map(branch => ({
                          value: branch._id,
                          label: branch.branchName
                        }))}
                        onChange={selected => {
                          handleChange({
                            target: {
                              name: 'branchId',
                              value: selected ? selected.value : '' // Handle null case
                            }
                          })
                        }}
                        value={
                          branches.find(
                            branch => branch._id === formData.branchId
                          )
                            ? {
                                value: formData.branchId,
                                label: branches.find(
                                  branch => branch._id === formData.branchId
                                ).branchName
                              }
                            : null
                        }
                        placeholder='Branch'
                        isSearchable={true}
                        isClearable={true}
                        styles={customStyles}
                      />
                    </FormGroup>
                  </Col>

          
                  <Col xs={12} sm={6} md={4} xl={3} className='mb-3'>
                    <FormGroup>
                      <Select
                        id='class'
                        options={classes.map(classItem => ({
                          value: classItem._id,
                          label: classItem.className
                        }))}
                        onChange={selected => {
                          handleChange({
                            target: {
                              name: 'classId',
                              value: selected ? selected.value : '' // Handle null case
                            }
                          })
                        }}
                        value={
                          classes.find(
                            classItem => classItem._id === formData.classId
                          )
                            ? {
                                value: formData.classId,
                                label: classes.find(
                                  classItem =>
                                    classItem._id === formData.classId
                                ).className
                              }
                            : null
                        }
                        placeholder='Class'
                        isSearchable={true}
                        isClearable={true}
                        styles={customStyles}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={6} md={4} xl={3} className='mb-3'>
                    <FormGroup>
                      <Select
                        id='sectionId'
                        options={sections.map(section => ({
                          value: section._id,
                          label: section.sectionName
                        }))}
                        onChange={selected => {
                          handleChange({
                            target: {
                              name: 'sectionId',
                              value: selected ? selected.value : ''
                            }
                          })
                        }}
                        value={
                          sections.find(
                            section => section._id === formData.sectionId
                          )
                            ? {
                                value: formData.sectionId,
                                label: sections.find(
                                  section => section._id === formData.sectionId
                                ).sectionName
                              }
                            : null
                        }
                        placeholder={
                          sections.length === 0 ? 'No Section' : 'Section'
                        }
                        isDisabled={sections.length === 0}
                        isSearchable={true}
                        isClearable={true}
                        styles={customStyles}
                      />
                    </FormGroup>
                  </Col>

                  <Col xs={12} sm={6} md={4} xl={3} className='mb-3'>
                    <FormGroup>
                      <Select
                        name='feesTypeId'
                        id='feesTypeId'
                        options={feesTypes.map(type => ({
                          value: type._id,
                          label: type.feesType
                        }))}
                        value={
                          formData.feesTypeId
                            ? {
                                value: formData.feesTypeId,
                                label: feesTypes.find(
                                  type => type._id === formData.feesTypeId
                                )?.feesType
                              }
                            : null
                        }
                        onChange={selectedOption => {
                          handleChange({
                            target: {
                              name: 'feesTypeId',
                              value: selectedOption ? selectedOption.value : ''
                            }
                          })
                        }}
                        placeholder='Fees Type' // Placeholder text
                        isSearchable={true}
                        isClearable={true}
                        required
                        styles={customStyles}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={6} xl={3} className='mb-3'>
                    <FormGroup style={{ position: 'relative' }}>
                      <Input
                        type='text'
                        id='searchInput'
                        placeholder='Search By Student Name'
                        value={formData.studentname}
                        onChange={handleSearchChange}
                        style={{ paddingLeft: '2.5rem' }}
                      />
                      <FaSearch
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '10px',
                          transform: 'translateY(-50%)',
                          color: '#495057'
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card>
          <div
            style={{
              padding: '15px 15px 25px 15px',
              backgroundColor: '#ffffff'
            }}
          >
            <CardTitle tag='h3' className='mb-3 text-center'>
              Receivable Details
            </CardTitle>

            <div className='mb-1'>
              <span style={{ fontWeight: 'bold', fontSize: '1.0em' }}>
                Total Records: {reportedData.length}{' '}
              </span>
            </div>
            <div style={{  maxHeight: '492px', overflowY: 'auto', borderTop:'1px solid #DEE2E6' ,borderLeft:'1px solid #DEE2E6'}} className=' position-relative table-container'>
            <Table bordered striped  className=' mb-0 pb-0'>
              {/* Table Header */}
            {/* <thead>
                <tr style={{ fontWeight: 'bold', backgroundColor: '#e9ecef' }}>
                 
                  <td
                  className=' pe-2'
                    colSpan={7}
                    style={{ textAlign: 'right', padding: '2px' }}
                  >
                    Total:
                  </td>
                  <td className=' ps-2' style={{ padding: '2px' }}>
                    {reportedData.reduce(
                      (acc, fee) => acc + (fee.totalPayableAmount || 0),
                      0
                    )}
                  </td>
                  <td className=' ps-2' style={{ padding: '2px' }}>
                    {reportedData.reduce(
                      (acc, fee) => acc + (fee.totalAmount || 0),
                      0
                    )}
                  </td>
                  <td className=' ps-2' style={{ padding: '2px' }}>
                    {
                      reportedData.reduce(
                        (acc, fee) => acc + (fee.Remaining || 0),
                        0
                      ) // Sum of Remaining (outstanding)
                    }
                  </td>
                </tr>
            </thead> */}

            <thead style={{ backgroundColor: '#f8f9fa', color: '#495057' }} className='head-stick2'>
            <tr >
                  <th className="fixed-column">S. No.</th>
                  <th className="fixed-column">Status</th>
                  <th className="fixed-column">Student Name</th>
                  {/* <th>Roll No.</th> */}
                  <th>Academic Year</th>
                  <th>Branch</th>
                  <th>Class</th>
                  <th>Fees Type</th>
                  <th>Total Payable</th>
                  <th>Collected</th>
                  <th>Outstanding</th>
                </tr>
              </thead>
              <tbody>
                {reportedData.length > 0 ? (
                  reportedData
                    .sort((a, b) => {
                      const nameA = a.studentName.toLowerCase()
                      const nameB = b.studentName.toLowerCase()
                      if (nameA < nameB) return -1
                      if (nameA > nameB) return 1
                      return 0
                    })
                    .map((fee, index) => (
                      <tr key={fee.feeId}>
                        <td className="fixed-column">{index + 1}</td>

                        <td className="fixed-column"
                          style={{
                            color:
                              fee.status === 'Partial Payment'
                                ? '#3166ff'
                                : fee.status === 'Unpaid'
                                ? 'red'
                                : fee.status === 'Paid'
                                ? 'green'
                                : 'inherit'
                          }}
                        >
                          <div style={{ width:"113px" }}>
                          {fee.status}
                          </div>
                        </td>
                        <td className="fixed-column">
                          <Link
                            to={`/singlestudent/${fee.studentId}`}
                            style={{ textDecoration: 'none', color: '#fb0f01' }}
                          >
                            {fee.studentName}
                          </Link>
                          {` (Roll No. ${fee.rollNo})`}
                        </td>
                        <td>{fee.academicyear}</td>
                        <td>{fee.branchName}</td>
                        <td>{fee.className}</td>
                        <td>
                          <Link
                            to={`/receivable/create/transaction/${fee.feeId}`}
                            style={{ textDecoration: 'none', color: '#fb0f01' }}
                          >
                            {fee.feeType}
                          </Link>
                        </td>
                        <td>{fee.totalPayableAmount}</td>
                        <td>{fee.totalAmount}</td>
                        <td>{fee.Remaining}</td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan='10' className='text-center'>
                      <FaFileAlt className='mt-3' size={70} color='#888' />
                      <h4 className='alert-heading mb-3 mt-3'>
                        No Students Found
                      </h4>
                    </td>
                  </tr>
                )}

                {reportedData.length > 0 && (
                 <tr style={{ fontWeight: 'bold', backgroundColor: '#e9ecef' }} className='head-stick-bottom'>
                    <td
                    className=' pe-2'
                      colSpan={7}
                      style={{ textAlign: 'right', padding: '2px' }}
                    >
                      Total:
                    </td>
                    <td className=' ps-2' style={{ padding: '2px' }}>
                      {reportedData.reduce(
                        (acc, fee) => acc + (fee.totalPayableAmount || 0),
                        0
                      )}
                    </td>
                    <td className=' ps-2' style={{ padding: '2px' }}>
                      {reportedData.reduce(
                        (acc, fee) => acc + (fee.totalAmount || 0),
                        0
                      )}
                    </td>
                    <td className=' ps-2' style={{ padding: '2px' }}>
                      {reportedData.reduce(
                        (acc, fee) => acc + (fee.Remaining || 0),
                        0
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            </div>
            {/* <div className='mt-1'
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1.0em',
                  }}
                >
                Total Records: {reportedData.length}{' '}
            
            </div> */}
            <style jsx='true'>{`
              .head-stick {
                position: sticky;
                top: -2px;
                background-color: #fff;
                z-index: 2;
              }
              .head-stick-bottom {
                position: sticky;
                bottom: -1px;
                background-color: #fff;
                z-index: 2;
              }
              .head-stick2 {
                position: sticky;
                top: -1px;
                background-color: #fff;
                z-index: 2;
              }

              .fixed-column {
                position: sticky;
                left: 0;
                background-color: #fff;
                z-index: 2;
              }

              .fixed-column:nth-child(2) {
                left: 50px; /* Adjust based on the column width */
              }

              .fixed-column:nth-child(3) {
                left: 120px; /* Adjust based on the column width of the first and second columns */
              }

              .table-container tbody td.fixed-column,
              .table-container thead th.fixed-column {
                position: sticky;
                background-color: #fff;
                z-index: 1;
              }

              .table-container tbody td.fixed-column:nth-child(2),
              .table-container thead th.fixed-column:nth-child(2) {
                left: 58px;
              }

              .table-container tbody td.fixed-column:nth-child(3),
              .table-container thead th.fixed-column:nth-child(3) {
                left: 186px;
              }

              table td {
                white-space: nowrap;  
              }

              table th {
                white-space: nowrap;
              }

              @media (max-width: 992px) {
                .fixed-column {
                  position: static;
                  left: 0;
                  background-color: #fff;
                  z-index: 2;
                }

                .fixed-column:nth-child(2),
                .fixed-column:nth-child(3) {
                  left: auto; /* Reset for responsive layouts */
                }

                .table-container tbody td.fixed-column,
                .table-container thead th.fixed-column {
                  position: static;
                  left: 0;
                  background-color: #fff;
                  z-index: 1;
                }
              }

              @media (max-width: 768px) {
                table td,
                table th {
                  padding: 5px;
                  white-space: nowrap;
                }
              }

              @media (max-width: 576px) {
                table td,
                table th {
                  white-space: nowrap;
                }
              }
            `}</style>
          </div>
        </>
      )}
    </>
  )
}

export default FeesReport
