import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../../middleware/axiosInstance";
import Select from "react-select";

const CreateFeesType = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    feesType: "",
    amount: "",
    branchId: null,
    classId: null,
    academicYearId: null,
    reminderDays: null,
    dueDate:null
  });

  const [academicYears, setAcademicYears] = useState([]);
  const [loading, setLoading] = useState(false);

  const [branches, setBranches] = useState([]);
  const [classes, setClasses] = useState([]);
  const [errors, setErrors] = useState({
    feesType: "",
    amount: "",
  });

  // const [nextDueDateRequired, setNextDueDateRequired] = useState(false);

  // Reminder Days options
  const reminderDaysOptions = [

    { value: 1, label: "1 Day Before" },
    { value: 2, label: "2 Day Before" },
    { value: 3, label: "3 Day Before" },
    { value: 4, label: "4 Day Before" },
    { value: 5, label: "5 Day Before" },
    { value: 6, label: "6 Day Before" },
    { value: 7, label: "7 Day Before" },
    { value: 8, label: "8 Day Before" },
    { value: 9, label: "9 Day Before" },
    { value: 10, label: "10 Day Before" },
  ];

  useEffect(() => {
    const fetchAcademicYears = async () => {
      try {
        const response = await axiosInstance.get("/academicyears");
        setAcademicYears(
          response.data.map((ay) => ({
            value: ay._id,
            label: ay.year,
          }))
        );
      } catch (error) {
        console.error("Error fetching academic years:", error);
      }
    };

    fetchAcademicYears();
  }, []);

  // Fetch branches
  const fetchBranches = async () => {
    try {
      const response = await axiosInstance.get("/branches");
      setBranches(
        response.data.map((branch) => ({
          value: branch._id,
          label: branch.branchName,
        }))
      );
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  // Fetch classes based on selected branch
  const fetchClasses = async () => {
    try {
      const response = await axiosInstance.get("/classes/branch", {
        params: { branchId: formData.branchId },
      });
      setClasses(
        response.data.map((classObj) => ({
          value: classObj._id,
          label: classObj.className,
        }))
      );
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  // Fetch branches on component mount
  useEffect(() => {
    fetchBranches();
  }, []);

  // Fetch classes when branchId changes
  useEffect(() => {
    if (formData.branchId) {
      fetchClasses();
    } else {
      setClasses([]);
      setFormData((prev) => ({
        ...prev,
        classId: null,
        academicYearId: null,
      }));
    }
  }, [formData.branchId]);

  // Handle select change and clear dependent values conditionally
  const handleSelectChange = (selectedOption, { name }) => {
    setFormData((prev) => ({
      ...prev,
      [name]: selectedOption ? selectedOption.value : null,
      // Clear dependent fields when branch or class is cleared
      ...(name === "branchId" && { classId: null, academicYearId: null }),
      ...(name === "classId" && { academicYearId: null }),
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });

    // Validation logic for feesType
    if (name === "feesType") {
      const words = value.trim().split(/\s+/);
      const wordCount = words.length;

      if (wordCount > 40) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          feesType: "Fees Type must be 40 words or less.",
        }));
      } else if (words.some((word) => word.length > 50)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          feesType: "Each word must be 50 characters or less.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          feesType: "", // Clear error if valid
        }));
      }
    }

    // Validation logic for amount
    if (name === "amount") {
      // Check if the input contains only digits (no characters or special symbols)
      const isNumber = /^[0-9]*$/.test(value);

      if (!isNumber) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          amount:
            "Only numbers are allowed. Special characters and letters are not permitted.",
        }));
      } else if (value.length > 10) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          amount:
            "Please enter a valid amount (less than or equal to 10 digits).",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          amount: "", // Clear error if valid
        }));
      }
    }

    // Reset classId when branchId is changed
    if (name === "branchId") {
      setFormData((prevData) => ({ ...prevData, classId: "" }));
    }
  };

  // Handle form submission
  const handleCreateFees = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Check for errors before submitting
    if (errors.feesType || errors.amount) {
      setLoading(false);
      return; // Prevent form submission if errors exist
    }

    try {
      await axiosInstance.post("/create/feestype", formData);
      navigate("/feestypes");
    } catch (error) {
      console.error("Error creating fees type:", error);
    }

    setLoading(false);
  };

  const handleCancel = () => {
    navigate("/feestypes");
  };


  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#FB0F01" : provided.borderColor,
      boxShadow: state.isFocused ? "0 0 0 1px #FB0F01" : null,
      borderWidth: state.isFocused ? "0px" : "1px",
      transition: "border-color 0.2s, box-shadow 0.2s",
      "&:hover": {
        borderColor: "#FB0F01",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#FFFFFF",
      zIndex: 999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FDD7C2" : "#FFFFFF",
      color: "black",
      "&:hover": {
        backgroundColor: "#FDD7C2",
        color: "black",
      },
      "&:active": {
        backgroundColor: "#E8F0FE",
      },
    }),
  };

  return (
    <div>
      <Card style={{ margin: "0 auto" }}>
        <CardBody>
          <CardTitle tag="h3" className="mb-1 text-center">
            Create Fees Type
          </CardTitle>
          <Form className="mt-5" onSubmit={handleCreateFees}>
            <Row>
              <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="branch">
                    <span style={{ fontWeight: "bold" }}>Branch*</span>
                  </Label>
                  <Select
                    className="custom-select-input"
                    name="branchId"
                    options={branches}
                    value={
                      branches.find(
                        (branch) => branch.value === formData.branchId
                      ) || null
                    }
                    onChange={handleSelectChange}
                    isClearable
                    placeholder="Select a Branch"
                    required
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>

              {/* Class Select */}
              <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="class">
                    <span style={{ fontWeight: "bold" }}>Class*</span>
                  </Label>
                  <Select
                    className="custom-select-input"
                    name="classId"
                    options={classes}
                    value={
                      classes.find((cls) => cls.value === formData.classId) ||
                      null
                    }
                    onChange={handleSelectChange}
                    isClearable
                    placeholder="Select a Class"
                    required
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>

              {/* Academic Year Select */}
              <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="academicYearId">
                    <span style={{ fontWeight: "bold" }}>Academic Year*</span>
                  </Label>
                  <Select
                    className="custom-select-input"
                    name="academicYearId"
                    options={academicYears}
                    value={
                      academicYears.find(
                        (ay) => ay.value === formData.academicYearId
                      ) || null
                    }
                    onChange={handleSelectChange}
                    isClearable
                    placeholder="Select Academic Year"
                    required
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="feesType">
                    <span style={{ fontWeight: "bold" }}>Fees Type*</span>
                  </Label>
                  <Input
                    type="text"
                    name="feesType"
                    id="feesType"
                    value={formData.feesType}
                    onChange={handleChange}
                    required
                    placeholder="Enter fees type"
                  />
                  {errors.feesType && (
                    <div
                      style={{
                        color: "red",
                        marginTop: "0.25rem",
                        fontSize: "0.875rem",
                      }}
                    >
                      {errors.feesType}
                    </div>
                  )}
                </FormGroup>
              </Col>

              {/* Amount Input */}
              <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="amount">
                    <span style={{ fontWeight: "bold" }}>Amount*</span>
                  </Label>
                  <Input
                    type="text"
                    name="amount"
                    id="amount"
                    placeholder="Enter amount"
                    value={formData.amount}
                    onChange={handleChange}
                    inputMode="numeric"
                    required
                    maxLength="11"
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allow only digits
                    }} // Limit input length to 10 characters visually
                  />
                  {errors.amount && (
                    <div
                      style={{
                        color: "red",
                        marginTop: "0.25rem",
                        fontSize: "0.875rem",
                      }}
                    >
                      {errors.amount}
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="reminderDays">
                    <span style={{ fontWeight: "bold" }}>Due Date Reminder</span>
                  </Label>
                  <Select
                    name="reminderDays"
                    options={reminderDaysOptions}
                    value={reminderDaysOptions.find(
                      (option) => option.value === formData.reminderDays
                    )}
                    onChange={handleSelectChange}
                    isClearable
                    placeholder="Select Reminder Days"
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>


              <Col xs={12} sm={6} md={4} xl={3}>
                      <FormGroup>
                        <Label for="dueDate">
                          <span style={{ fontWeight: "bold" }}>
                            Due Date
                          </span>
                        </Label>
                        <Input
                          type="date"
                          name="dueDate"
                          id="dueDate"
                          value={formData.dueDate}
                          onChange={handleChange}
                          // required={nextDueDateRequired} // Conditionally required
                 
                        />
                      </FormGroup>
                    </Col>

            </Row>
            <div className="d-flex justify-content-center mt-3">
              <Button
                className="btn-no-radius"
                type="submit"
                color="primary"
                disabled={loading}
              >
                {loading ? <Spinner size="sm" /> : "Save"}
              </Button>
              <Button
                className="btn-no-radius mx-2"
                color="secondary"
                onClick={handleCancel}
                disabled={loading}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default CreateFeesType;
