import React, { useState, useEffect } from "react";
import {
  Card,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Button,
  Table,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { FaRegEdit, FaFileAlt } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";
import { useNavigate, NavLink } from "react-router-dom";
import axiosInstance from "../../../../middleware/axiosInstance";
import Swal from "sweetalert2";
import Select from "react-select";

const ExamSubjectDetails = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    branchId: "",
    classId: "",
    academicYearId: "",
    examNameId: "",
  });
  const [branches, setBranches] = useState([]);
  const [classes, setClasses] = useState([]);
  const [academicYears, setAcademicYears] = useState([]);
  const [exams, setExams] = useState([]);
  const [error, setError] = useState("");
  const [noDataFound, setNoDataFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [examNames, setExamNames] = useState([]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await axiosInstance.get("/branches");
        setBranches(response.data);
      } catch (error) {
        setError("Failed to fetch branches.");
      }
    };
    fetchBranches();
  }, []);

  // Reusable function to fetch classes
  const fetchClasses = async (branchId) => {
    try {
      const response = await axiosInstance.get("/classes/branch", {
        params: { branchId },
      });
      setClasses(response.data);
    } catch (error) {
      setError("Failed to fetch classes.");
    }
  };

  // Fetch classes when branchId changes
  useEffect(() => {
    if (formData.branchId) {
      fetchClasses(formData.branchId);
    }
  }, [formData.branchId]);

  useEffect(() => {
    const fetchExams = async () => {
      if (formData.branchId && formData.classId) {
        try {
          const response = await axiosInstance.get("/exam/exams", {
            params: {
              branchId: formData.branchId,
              classId: formData.classId,
              academicYearId: formData.academicYearId,
            },
          });

          const sortedExamNames = response.data.sort((a, b) =>
            a.examName.localeCompare(b.examName)
          );
          setExamNames(sortedExamNames);
        } catch (error) {
          setError("Failed to fetch exams.");
        }
      }
    };
    fetchExams();
  }, [formData.branchId, formData.classId]);

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const response = await axiosInstance.get("/academicyears");
        setAcademicYears(response.data);

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();
        const currentDay = currentDate.getDate();

        let defaultAcademicYear;

        if (currentMonth > 4 || (currentMonth === 4 && currentDay >= 1)) {
          // June 1 to December 31, or May 1 to May 31

          defaultAcademicYear = `${currentYear}-${String(currentYear + 1).slice(
            -2
          )}`;
        } else {
          defaultAcademicYear = `${currentYear - 1}-${String(currentYear).slice(
            -2
          )}`;
        }

        const defaultAcademicYearEntry = response.data.find(
          (year) => year.year === defaultAcademicYear
        );

        if (defaultAcademicYearEntry) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            academicYearId: defaultAcademicYearEntry._id,
          }));
        }
      } catch (error) {
        console.error("Error fetching academic years:", error);
      } finally {
        setLoading(false);
      }
    };

    getAcademicYears();
  }, []);

  useEffect(() => {
    const fetchExams = async () => {
      try {
        let response;

        response = await axiosInstance.get("exam/getall/examSubjects", {
          params: {
            branchId: formData.branchId,
            classId: formData.classId || null,
            academicYearId: formData.academicYearId || null,

            examNameId: formData.examNameId || null,
          },
        });
        setExams(response.data);

        setNoDataFound(response.data.length === 0);
      } catch (error) {
        setError("Failed to fetch exams.");
      } finally {
        setLoading(false);
      }
    };

    fetchExams();
  }, [
    formData.branchId,
    formData.classId,
    formData.academicYearId,
    formData.examNameId,
  ]);


  const handleChange = ({ target: { name, value } }) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "branchId") {
      setClasses([]); // Clear classes when branch changes
      setNoDataFound(false); // Reset noDataFound when branch changes

      if (value) {
        // Fetch classes only if branchId is not empty
        fetchClasses(value);
      }

      // Clear class selection
      setFormData((prev) => ({ ...prev, classId: "" }));
    } else if (name === "classId" || name === "academicYearId") {
      setNoDataFound(false); // Reset noDataFound when class or academic year changes
    }
  };

  const handleEdit = (id) => {
    navigate(`/edit/examsubject/${id}`);
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        await axiosInstance.delete(`/exam/delete/examsubject/${id}`);
        setExams((prevExam) => prevExam.filter((exam) => exam._id !== id));
      } catch (error) {
        console.error("Error deleting exam type:", error);
      }
    }
  };

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#FB0F01" : provided.borderColor,
      boxShadow: state.isFocused ? "0 0 0 1px #FB0F01" : null,
      borderWidth: state.isFocused ? "0px" : "1px",
      transition: "border-color 0.2s, box-shadow 0.2s",
      "&:hover": {
        borderColor: "#FB0F01",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#FFFFFF",
      zIndex: 999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FDD7C2" : "#FFFFFF",
      color: "black",
      "&:hover": {
        backgroundColor: "#FDD7C2",
        color: "black",
      },
      "&:active": {
        backgroundColor: "#E8F0FE",
      },
    }),
  };

  // Render the exam types table
  const renderTable = () => (
    <div>
      <div className="px-0 pb-3">
        <CardTitle tag="h4" className="mt-3 mb-1">
          Exam Subject List
        </CardTitle>
      </div>
      <div style={{ overflowX: "auto" }}>
        <Table bordered striped responsive>
          <thead style={{ backgroundColor: "#f8f9fa", color: "#495057" }}>
            <tr>
              <th>Subject</th>
              <th>Exam Name</th>
              <th>Exam Types</th>
              <th>Branch</th>
              <th>Class</th>
              <th>Academic Year</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {exams.length
              ? exams.map((examItem) => (
                  <tr key={examItem._id}>
                    <td>{examItem.subject_id.subject}</td>
                    <td>{examItem.exam_id?.examName || "N/A"}</td>
                    <td>
                      {examItem.ExamType && examItem.ExamType.length > 0
                        ? examItem.ExamType.map((val) => {
                            // Check if `examtype_id` and `examType` exist to avoid errors
                            return val.examtype_id && val.examtype_id.examType
                              ? val.examtype_id.examType
                              : "";
                          }).join(", ")
                        : "Written"}
                    </td>
                    <td>{examItem.branch_id.branchName}</td>
                    <td>
                      {examItem.class_id?.className}
                      {examItem.exam_id.section_id &&
                      examItem.exam_id.section_id.length > 0
                        ? ` (${examItem.exam_id.section_id
                            .map((val) => val.sectionName) // Mapping through populated section_id
                            .join(", ")})`
                        : ""}
                    </td>
                    <td>{examItem.academicyear_id.year}</td>
                    <td>
                      <FaRegEdit
                        title="Edit"
                        onClick={() => handleEdit(examItem._id)}
                        style={{ cursor: "pointer", margin: "0 6px" }}
                      />
                      <AiOutlineDelete
                        title="Delete"
                        onClick={() => handleDelete(examItem._id)}
                        style={{ cursor: "pointer", margin: "0 6px" }}
                      />
                    </td>
                  </tr>
                ))
              : noDataFound && (
                  <tr>
                    <td rowSpan="4" colSpan="7" className="text-center">
                      <FaFileAlt className="mt-3" size={70} color="#888" />
                      <h4 className="alert-heading mb-3 mt-3">
                        No Exams Found
                      </h4>
                    </td>
                  </tr>
                )}
          </tbody>
        </Table>
        <style jsx="true">{`
          table td {
            white-space: nowrap;
          }
          table th {
            white-space: nowrap;
          }

          @media (max-width: 768px) {
            table td,
            table th {
              padding: 5px;
              white-space: nowrap;
            }
          }

          @media (max-width: 576px) {
            table td,
            table th {
              white-space: nowrap;
            }
          }
        `}</style>
      </div>
    </div>
  );

  return (
    <>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center p-4"
          style={{ minHeight: "90vh" }}
        >
          <Spinner color="primary" />
        </div>
      ) : (
        <>
          <Card
            style={{
              boxShadow: "none",
              marginBottom: "10px",
              borderRadius: "0",
            }}
          >
            <div className="d-flex justify-content-between align-items-center px-3 pt-1">
              <CardTitle className="mt-2" tag="h4">
                Exam Subject Setup
              </CardTitle>
              <NavLink to="/create/examsubject" className="pb-0 mb-0">
                <Button className="btn-no-radius" color="primary">
                  Add Exam Subject
                </Button>
              </NavLink>
            </div>
            <hr className="mt-1" />
            <Row className="m-0 p-0 ">
              <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="academicYear">
                    <span style={{ fontWeight: "bold" }}>Academic Year</span>
                  </Label>
                  <Select
                    id="academicYear"
                    options={academicYears.map((year) => ({
                      value: year._id,
                      label: year.year,
                    }))}
                    onChange={(selected) => {
                      handleChange({
                        target: {
                          name: "academicYearId",
                          value: selected ? selected.value : "", // Handle null case
                        },
                      });
                    }}
                    value={
                      academicYears.find(
                        (year) => year._id === formData.academicYearId
                      )
                        ? {
                            value: formData.academicYearId,
                            label: academicYears.find(
                              (year) => year._id === formData.academicYearId
                            ).year,
                          }
                        : null
                    }
                    placeholder="Select Academic Year"
                    isSearchable={true}
                    isClearable={true}
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="branch">
                    <span style={{ fontWeight: "bold" }}>Branch</span>
                  </Label>
                  <Select
                    className=""
                    id="branch"
                    options={branches.map((branch) => ({
                      value: branch._id,
                      label: branch.branchName,
                    }))}
                    onChange={(selected) => {
                      handleChange({
                        target: {
                          name: "branchId",
                          value: selected ? selected.value : "", // Handle null case
                        },
                      });
                    }}
                    value={
                      branches.find(
                        (branch) => branch._id === formData.branchId
                      )
                        ? {
                            value: formData.branchId,
                            label: branches.find(
                              (branch) => branch._id === formData.branchId
                            ).branchName,
                          }
                        : null
                    }
                    placeholder="Select a Branch"
                    isSearchable={true}
                    isClearable={true}
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>

              <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="class">
                    <span style={{ fontWeight: "bold" }}>Class</span>
                  </Label>
                  <Select
                    id="class"
                    options={classes.map((classItem) => ({
                      value: classItem._id,
                      label: classItem.className,
                    }))}
                    onChange={(selected) => {
                      handleChange({
                        target: {
                          name: "classId",
                          value: selected ? selected.value : "", // Handle null case
                        },
                      });
                    }}
                    value={
                      classes.find(
                        (classItem) => classItem._id === formData.classId
                      )
                        ? {
                            value: formData.classId,
                            label: classes.find(
                              (classItem) => classItem._id === formData.classId
                            ).className,
                          }
                        : null
                    }
                    placeholder="Select a Class"
                    isSearchable={true}
                    isClearable={true}
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>

              <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="examName">
                    <span style={{ fontWeight: "bold" }}>Exam Name*</span>
                  </Label>
                  <Select
                    name="examNameId"
                    options={examNames.map((examname) => ({
                      value: examname._id,
                      label: examname.examName,
                    }))}
                    value={
                      examNames.find(
                        (examName) => examName._id === formData.examNameId
                      )
                        ? {
                            value: formData.examNameId,
                            label: examNames.find(
                              (examName) => examName._id === formData.examNameId
                            ).examName,
                          }
                        : null
                    }
                    onChange={(selected) => {
                      handleChange({
                        target: {
                          name: "examNameId",
                          value: selected ? selected.value : "", // Handle null case
                        },
                      });
                    }}
                    isClearable
                    placeholder="Select an Exam Name"
                    required
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Card>

          <Card
            style={{
              boxShadow: "none",
              marginTop: "0px",
              paddingTop: "0px",
              minHeight: "400px",
            }}
            className="px-3 "
          >
            {renderTable()}
          </Card>
        </>
      )}
    </>
  );
};

export default ExamSubjectDetails;
