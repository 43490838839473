import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  Table,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../../middleware/axiosInstance";
import Select from "react-select";
import { FaRegEdit } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";

import Swal from "sweetalert2";

const CreateExamSubject = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    academicYearId: "",
    branchId: "",
    classId: "",
    examId: "",
    subjectId: "",
    totalMarks: "",
    passingMarks: "",
    isGrade: false,
    subDetails: {},
    marks: [],
    grades: [],
  });

  const [academicYears, setAcademicYears] = useState([]);
  const [branches, setBranches] = useState([]);
  const [classes, setClasses] = useState([]);
  const [examNames, setExamNames] = useState([]);
  const [examType, setExamType] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isGrade, setIsGrade] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [isGradeEditing, setIsGradeEditing] = useState(false);
  const [editGradeIndex, setEditGradeIndex] = useState(-1);

  const [error, setError] = useState({
    totalMarks: "",
    passingMarks: "",
    examEndTime: "",
  });

  useEffect(() => {
    const fetchAcademicYears = async () => {
      try {
        const response = await axiosInstance.get("/academicyears");
        setAcademicYears(
          response.data.map((ay) => ({
            value: ay._id,
            label: ay.year,
          }))
        );
      } catch (error) {
        // console.error("Error fetching academic years:", error);
      }
    };
    fetchAcademicYears();
  }, []);

  // Fetch branches
  const fetchBranches = async () => {
    try {
      const response = await axiosInstance.get("/branches");
      setBranches(
        response.data.map((branch) => ({
          value: branch._id,
          label: branch.branchName,
        }))
      );
    } catch (error) {
      // console.error("Error fetching branches:", error);
    }
  };

  useEffect(() => {
    fetchBranches();
  }, []);

  // Fetch classes based on selected branch
  const fetchClasses = async () => {
    try {
      const response = await axiosInstance.get("/classes/branch", {
        params: { branchId: formData.branchId },
      });
      setClasses(
        response.data.map((classObj) => ({
          value: classObj._id,
          label: classObj.className,
        }))
      );
    } catch (error) {
      // console.error("Error fetching classes:", error);
    }
  };

  useEffect(() => {
    if (formData.branchId) {
      fetchClasses();
    } else {
      setClasses([]);
      setSubjects([]);
      setFormData((prev) => ({
        ...prev,
        classId: null,
        examId: null,
      }));
    }
  }, [formData.branchId]);

  useEffect(() => {
    const fetchSubjectsByBranch = async () => {
      if (formData.branchId) {
        try {
          const response = await axiosInstance.get(
            "/exam/fetch/branch/subject",
            {
              params: { id: formData.branchId },
            }
          );

          // Map the response correctly using `response.data.subjects`
          setSubjects(
            response.data.subjects.map((subject) => ({
              value: subject._id,
              label: subject.subject, // Ensure the backend returns a `subject` field
            }))
          );
        } catch (error) {
          // console.error("Error fetching subjects by branch:", error);
        }
      } else {
        // Reset subjects if no branch is selected
        setSubjects([]);
      }
    };

    fetchSubjectsByBranch();
  }, [formData.branchId]);

  useEffect(() => {
    const fetchExamNames = async () => {
      // Clear exam names initially when any dependency changes
      setExamNames([]);

      if (
        formData.branchId &&
        formData.classId &&
        formData.academicYearId // Ensure academicYearId is selected
      ) {
        try {
          // Create the params object dynamically
          const params = {
            branchId: formData.branchId,
            classId: formData.classId,
            academicYearId: formData.academicYearId,
          };

          // Make the API call with the constructed params
          const response = await axiosInstance.get("/exam/exams", {
            params: params,
          });

          // Process and set exam names if available
          const exams = response.data;
          if (exams.length > 0) {
            setExamNames(
              exams.map((exam) => ({
                value: exam._id,
                label: exam.examName,
              }))
            );
          } else {
            // If no exams found, reset exam names
            setExamNames([]);
          }
        } catch (error) {
          // console.error("Error fetching exam names:", error);
          setExamNames([]); // Reset exam names in case of error
        }
      } else {
        // If essential params are missing, reset exam names
        setExamNames([]);
      }
    };

    // Fetch exams whenever formData changes
    fetchExamNames();
  }, [formData.branchId, formData.classId, formData.academicYearId]);

  useEffect(() => {
    const fetchExamTypes = async () => {
      if (formData.examId) {
        try {
          const response = await axiosInstance.get("/exam/get/examtypes", {
            params: { examId: formData.examId },
          });

          if (
            response.data.examTypes &&
            Array.isArray(response.data.examTypes)
          ) {
            // Map the exam types directly without sorting
            setExamType(
              response.data.examTypes.map((examType) => ({
                value: examType._id,
                label: examType.examType, // Use examTypeName as the label
              }))
            );
          } else {
            setExamType([]); // Clear exam types if no valid data
          }
        } catch (error) {
          // console.error("Error fetching exam types:", error);
        }
      } else {
        setExamType([]); // Clear exam types if no examId
      }
    };

    fetchExamTypes(); // Fetch exam types when examId changes
  }, [formData.examId]);

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;

    setIsGrade(checked);

    setFormData({
      ...formData,
      isGrade: checked,
    });
    setFormData((prevFormData) => ({
      ...prevFormData,
      examTypeId: "",
      passingMarks: 0,
      totalMarks: 0,
      examTime: "",
      examDate: "",
      examEndTime: "",
      grades:[]
    }));
  };

  const handleSelectChange = (selectedOption, { name }) => {
    setFormData((prev) => {
      if (name === "branchId") {
        setExamType([]);
        return {
          ...prev,
          branchId: selectedOption ? selectedOption.value : null,
          classId: null,
          academicYearId: prev.academicYearId,
          examId: null,
          passingMarks: null,
          totalMarks: null,
          examEndTime: null,
          examTime: null,
          examType: null,
          subjectName: null,
          isGrade: false,
          examTypeId: "",
        };
      }

      if (name === "academicYearId") {
        return {
          ...prev,
          academicYearId: selectedOption ? selectedOption.value : null,
          examId: null,
        };
      }

      if (name === "classId") {
        return {
          ...prev,
          classId: selectedOption ? selectedOption.value : null,
          sectionId: [], // Reset section when class is changed
        };
      }

      if (name === "examId") {
        // If examTypeId exists, set it in examType array with a dynamic label
        const updatedExamType = prev.examTypeId
          ? [{ value: prev.examTypeId, label: "Exam Type Name" }] // Replace "Exam Type Name" with the actual label if available
          : [];

        // Update the examType state
        setExamType(updatedExamType);

        return {
          ...prev,
          examId: selectedOption ? selectedOption.value : null,
          passingMarks: "",
          totalMarks: "",
          examEndTime: "",
          examTime: "",
          examType:
            updatedExamType.length > 0 ? updatedExamType[0].value : null,
          subjectName: "",
          isGrade: false,
        };
      }

      if (name === "examTypeId") {
        return {
          ...prev,
          examTypeId: selectedOption ? selectedOption.value : null,
          passingMarks: "",
          totalMarks: "",
          examEndTime: "",
          examTime: "",
          examDate: "",
        };
      }

      return {
        ...prev,
        [name]: selectedOption ? selectedOption.value : null,
      };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "min_mark" || name === "max_mark") {
      // Validate that the value is a number
      if (isNaN(value) || value < 0) {
        setError((prevErrors) => ({
          ...prevErrors,
          [name]: `${
            name === "min_mark" ? "Minimum" : "Maximum"
          } marks must be a valid number and cannot be negative.`,
        }));
      } else {
        setError((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear the error if input is valid
        }));
      }
    }

    if (name === "grade") {
      // Validate that grade is a non-empty string and doesn't exceed a certain length
      if (value.length > 50) {
        setError((prevErrors) => ({
          ...prevErrors,
          grade: "Grade must be 50 characters or less.",
        }));
      } else {
        setError((prevErrors) => ({
          ...prevErrors,
          grade: "", // Clear the error if input is valid
        }));
      }
    }
  };

  const addSubjectPaper = () => {
    const newExamEntry = {
      examTypeId: formData.examTypeId.trim(),
      passingMarks: formData.passingMarks,
      totalMarks: formData.totalMarks,
      examTime: formData.examTime,
      examDate: formData.examDate,
      examEndTime: formData.examEndTime,
    };

    if (
      !newExamEntry.examTypeId ||
      !newExamEntry.passingMarks ||
      !newExamEntry.totalMarks ||
      !newExamEntry.examTime ||
      !newExamEntry.examDate ||
      !newExamEntry.examEndTime
    ) {
      Swal.fire({
        title: "Warning!",
        text: "All fields must be filled out",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Check for duplicates based on a unique identifier (e.g., examTypeId)

    const isDuplicate = formData.marks.some(
      (entry) =>
        entry.examTypeId.toLowerCase() === newExamEntry.examTypeId.toLowerCase()
    );

    if (isDuplicate) {
      Swal.fire({
        title: "Warning!",
        text: "Exam Type already exists in the list.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return; //Exit the function if duplicate is found
    }

    // Convert totalMarks and passingMarks to numbers
    const totalMarks = Number(formData.totalMarks || 0);
    const passingMarks = Number(formData.passingMarks || 0);

    // Validation for marks and times
    if (passingMarks > totalMarks) {
      Swal.fire({
        title: "Invalid Marks Entry!",
        text: `Passing marks (${passingMarks}) cannot be greater than total marks (${totalMarks}). Please correct this.`,
        icon: "warning",
        confirmButtonText: "OK",
      });
      setLoading(false);
      return;
    }

    if (
      new Date(`1970-01-01T${formData.examTime}`) >=
      new Date(`1970-01-01T${formData.examEndTime}`)
    ) {
      Swal.fire({
        title: "Invalid Time Entry!",
        text: `Exam start time (${formData.examTime}) must be earlier than exam end time (${formData.examEndTime}).`,
        icon: "warning",
        confirmButtonText: "OK",
      });
      setLoading(false);
      return;
    }

    // If editing an existing entry (assuming `isEditing` and `editIndex` are defined)
    if (isEditing) {
      const updatedMarks = [...formData.marks];
      updatedMarks[editIndex] = newExamEntry;
      setFormData((prevFormData) => ({
        ...prevFormData,
        marks: updatedMarks,
      }));
      setIsEditing(false);
      setEditIndex(-1);
    } else {
      // Adding new entry
      setFormData((prevFormData) => ({
        ...prevFormData,
        marks: [...prevFormData.marks, newExamEntry],
      }));
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      examTypeId: "",
      passingMarks: "",
      totalMarks: "",
      examTime: "",
      examDate: "",
      examEndTime: "",
    }));
  };

  const editSubjectPaper = () => {


    const totalMarks =
      formData.totalMarks ||
      (formData.marks.length > 0 && formData.marks[0]?.totalMarks) ||
      "";
    const passingMarks =
      formData.passingMarks ||
      (formData.marks.length > 0 && formData.marks[0]?.passingMarks) ||
      "";
    const examDate =
      formData.examDate ||
      (formData.marks.length > 0 && formData.marks[0]?.examDate) ||
      "";
    const examTime =
      formData.examTime ||
      (formData.marks.length > 0 && formData.marks[0]?.examTime) ||
      "";
    const examEndTime =
      formData.examEndTime ||
      (formData.marks.length > 0 && formData.marks[0]?.examEndTime) ||
      "";

    // Convert totalMarks and passingMarks to numbers
    const totalMarksNum = Number(totalMarks);
    const passingMarksNum = Number(passingMarks);

    // Validation for passingMarks > totalMarks
    if (passingMarksNum > totalMarksNum) {
      Swal.fire({
        title: "Invalid Marks Entry!",
        text: `Passing marks (${passingMarksNum}) cannot be greater than total marks (${totalMarksNum}). Please correct this.`,
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Validation for examTime and examEndTime
    if (
      new Date(`1970-01-01T${examTime}`) >=
      new Date(`1970-01-01T${examEndTime}`)
    ) {
      Swal.fire({
        title: "Invalid Time Entry!",
        text: `Exam start time (${examTime}) must be earlier than exam end time (${examEndTime}).`,
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }








    const newExamEntry = {
      examTypeId: formData.examTypeId.trim(),
      passingMarks,
      totalMarks,
      examTime,
      examDate,
      examEndTime,
    };

    if (
      !newExamEntry.examTypeId ||
      !newExamEntry.passingMarks ||
      !newExamEntry.totalMarks ||
      !newExamEntry.examTime ||
      !newExamEntry.examDate ||
      !newExamEntry.examEndTime
    ) {
      Swal.fire({
        title: "Warning!",
        text: "All fields must be filled out",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }


    // If editing an existing entry (assuming `isEditing` and `editIndex` are defined)
    if (isEditing) {
      const updatedMarks = [...formData.marks];
      updatedMarks[editIndex] = newExamEntry;
      setFormData((prevFormData) => ({
        ...prevFormData,
        marks: updatedMarks,
      }));
      setIsEditing(false);
      setEditIndex(-1);
    } else {
      // Adding new entry
      setFormData((prevFormData) => ({
        ...prevFormData,
        marks: [...prevFormData.marks, newExamEntry],
      }));
    }

    Swal.fire({
      title: "Success!",
      text: "Exam type successfully updated.",
      icon: "success",
      confirmButtonText: "OK",
    });
    setFormData((prevFormData) => ({
      ...prevFormData,
      examTypeId: "",
      passingMarks: "",
      totalMarks: "",
      examTime: "",
      examDate: "",
      examEndTime: "",
    }));
  };

  const deleteType = (index) => {
    // If we're editing and the delete index matches the edit index, only remove that item
    setFormData((prevData) => {
      // If in editing mode, we only delete the edited item
      const updatedMarks = prevData.marks.filter((_, i) => i !== index);

      // Check if the current index being deleted matches the editIndex
      if (isEditing && editIndex === index) {
        setIsEditing(false); // Turn off editing state after deletion
        setEditIndex(null); // Reset the edit index
      }

      return {
        ...prevData,
        marks: updatedMarks,
      };
    });

    setFormData((prevFormData) => ({
      ...prevFormData,
      examTypeId: "",
      passingMarks: "",
      totalMarks: "",
      examTime: "",
      examDate: "",
      examEndTime: "",
    }));

    setIsEditing(false);
  };
  const editType = (index) => {
    const selectedExam = formData.marks[index];

    setFormData((prevFormData) => ({
      ...prevFormData,
      examTypeId: selectedExam.examTypeId,
      passingMarks: selectedExam.passingMarks,
      totalMarks: selectedExam.totalMarks,
      examTime: selectedExam.examTime,
      examDate: selectedExam.examDate,
      examEndTime: selectedExam.examEndTime,
    }));

    setIsEditing(true); // Mark that you're in edit mode
    setEditIndex(index); // Track which item is being edited
  };


  const handleCreate = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Validate marks data
    const totalMarks =
      formData.totalMarks ||
      (formData.marks.length > 0 && formData.marks[0].totalMarks); // Fallback to formData.marks[0].totalMarks if available
    const passingMarks =
      formData.passingMarks ||
      (formData.marks.length > 0 && formData.marks[0].passingMarks); // Fallback to formData.marks[0].passingMarks if available

    // Convert totalMarks and passingMarks to numbers
    const totalMarksNum = Number(totalMarks);
    const passingMarksNum = Number(passingMarks);

    // Validation for passingMarks > totalMarks
    if (passingMarksNum > totalMarksNum) {
      Swal.fire({
        title: "Invalid Marks Entry!",
        text: `Passing marks (${passingMarksNum}) cannot be greater than total marks (${totalMarksNum}). Please correct this.`,
        icon: "warning",
        confirmButtonText: "OK",
      });
      setLoading(false);
      return;
    }

    // Validate exam time
    const examTime =
      formData.examTime ||
      (formData.marks.length > 0 && formData.marks[0].examTime); // Fallback to formData.marks[0].examTime if available
    const examEndTime =
      formData.examEndTime ||
      (formData.marks.length > 0 && formData.marks[0].examEndTime); // Fallback to formData.marks[0].examEndTime if available

    if (
      new Date(`1970-01-01T${examTime}`) >=
      new Date(`1970-01-01T${examEndTime}`)
    ) {
      Swal.fire({
        title: "Invalid Time Entry!",
        text: `Exam start time (${examTime}) must be earlier than exam end time (${examEndTime}).`,
        icon: "warning",
        confirmButtonText: "OK",
      });
      setLoading(false);
      return;
    }

    if (
      examType.length > 0 &&
      !formData.isGrade &&
      formData.marks.length === 0
    ) {
      Swal.fire({
        title: "Missing Entry!",
        text: "Please add at least one mark entry for the existing Exam type",
        icon: "warning",
        confirmButtonText: "OK",
      });
      setLoading(false); // Stop loading indicator
      return; // Prevent form submission
    }

    try {
      let newExamEntry;

      // Logic for handling grades array
      const formattedGrades = formData.grades?.map((grade) => ({
        grade: grade.grade.trim(),
        min_mark: grade.min_mark,
        max_mark: grade.max_mark,
      }));

      if (isGrade) {
        const { marks, ...restFormData } = formData;

        newExamEntry = {
          ...restFormData,

          Grades: formattedGrades, // Include formatted grades
          subDetails: {
            examTypeId: formData.examTypeId,
            passingMarks: formData.passingMarks,
            totalMarks: formData.totalMarks,
            examTime: formData.examTime,
            examDate: formData.examDate,
            examEndTime: formData.examEndTime,
          },
        };

        await axiosInstance.post("/exam/setup/subject", newExamEntry);
        navigate("/exam/subject");
      } else {
        if (examType.length === 0) {
          const { marks, ...restFormData } = formData;

          newExamEntry = {
            ...restFormData,

            Grades: formattedGrades, // Include formatted grades
            subDetails: {
              passingMarks: formData.passingMarks,
              totalMarks: formData.totalMarks,
              examTime: formData.examTime,
              examDate: formData.examDate,
              examEndTime: formData.examEndTime,
            },
          };

          await axiosInstance.post("/exam/setup/subject", newExamEntry);
          navigate("/exam/subject");
        } else {
          const finalPayload = {
            ...formData,

            Grades: formattedGrades, // Include formatted grades
          };

          await axiosInstance.post("/exam/setup/subject", finalPayload);
          navigate("/exam/subject");
        }
      }
    } catch (error) {
      // console.error("Error creating exam subject:", error);
    }

    setLoading(false);
  };

  const handleCancel = () => {
    navigate("/exam/subject");
  };

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#FB0F01" : provided.borderColor,
      boxShadow: state.isFocused ? "0 0 0 1px #FB0F01" : null,
      borderWidth: state.isFocused ? "0px" : "1px",
      transition: "border-color 0.2s, box-shadow 0.2s",
      "&:hover": {
        borderColor: "#FB0F01",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#FFFFFF",
      zIndex: 999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FDD7C2" : "#FFFFFF",
      color: "black",
      "&:hover": {
        backgroundColor: "#FDD7C2",
        color: "black",
      },
      "&:active": {
        backgroundColor: "#E8F0FE",
      },
    }),
  };

  const renderTable = () => (
    <div>
      <div style={{ overflowX: "auto" }} className=" mt-3">
        {formData.marks.length > 0 && (
          <Table bordered striped responsive>
            <thead style={{ backgroundColor: "#f8f9fa", color: "#495057" }}>
              <tr>
                <th>Exam Type</th>
                <th>Exam Date</th>
                <th>Exam Start Time</th>
                <th>Exam End Time</th>
                <th>Total Marks</th>
                <th>Passing Marks</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {formData.marks.map((exam, index) => {
                const examTypename =
                  examType.find((type) => type.value === exam.examTypeId)
                    ?.label ?? "Unknown";

                return (
                  <tr key={index}>
                    <td>{examTypename}</td>
                    <td>{exam.examDate}</td>
                    <td>{new Date(`1970-01-01T${exam.examTime}:00`).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}</td>
                    <td>{new Date(`1970-01-01T${exam.examEndTime}:00`).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}</td>
                    <td>{exam.totalMarks}</td>
                    <td>{exam.passingMarks}</td>
                    <td>
                      <FaRegEdit
                        title="Edit"
                        onClick={() => editType(index)}
                        style={{ cursor: "pointer", margin: "0 6px" }}
                      />
                      <AiOutlineDelete
                        title="Delete"
                        onClick={() => deleteType(index)}
                        style={{ cursor: "pointer", margin: "0 6px" }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </div>
      <style jsx="true">{`
        table td {
          white-space: nowrap;
        }
        table th {
          white-space: nowrap;
        }

        @media (max-width: 768px) {
          table td,
          table th {
            padding: 5px;
            white-space: nowrap;
          }
        }

        @media (max-width: 576px) {
          table td,
          table th {
            white-space: nowrap;
          }
        }
      `}</style>
    </div>
  );

  const addGrade = () => {
    const { grade, max_mark, min_mark } = formData;

    // Validation for empty fields
    const isMinMarkExist = formData.grades.some(
      (g) => g.min_mark === parseFloat(min_mark)
    );
    const isMaxMarkExist = formData.grades.some(
      (g) => g.max_mark === parseFloat(max_mark)
    );
    // const isGradeExist = formData.grades.some((g) => g.grade === grade);
    const isGradeExist = formData.grades.some(
      (g) => g.grade.trim().toLowerCase() === grade.trim().toLowerCase()  // Normalize to lowercase and trim spaces before comparison
    );

    const isMaxMarkExistwithMax = formData.grades.some(
      (g) => g.max_mark === parseFloat(min_mark)
    );
    const isMaxMarkExistwithMin = formData.grades.some(
      (g) => g.min_mark === parseFloat(max_mark)
    );

    const isRangeOverlap = formData.grades.some((existingGrade) => {
      // Check if the new range [min_mark, max_mark] overlaps with any existing range
      return (
        (parseFloat(min_mark) >= existingGrade.min_mark &&
          parseFloat(min_mark) <= existingGrade.max_mark) || // Min mark within existing range
        (parseFloat(max_mark) >= existingGrade.min_mark &&
          parseFloat(max_mark) <= existingGrade.max_mark) || // Max mark within existing range
        (parseFloat(min_mark) <= existingGrade.min_mark &&
          parseFloat(max_mark) >= existingGrade.max_mark) // New range fully covers existing range
      );
    });

    if (isRangeOverlap) {
      Swal.fire({
        title: "Invalid Range!",
        text: "The new grade range overlaps with an existing range.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }
    if (isMaxMarkExistwithMax) {
      Swal.fire({
        title: "Duplicate Min percentage!",
        text: "A grade with the same max percentage already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }
    if (isMaxMarkExistwithMin) {
      Swal.fire({
        title: "Duplicate Min percentage!",
        text: "A grade with the same Min percentage already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (isMinMarkExist) {
      Swal.fire({
        title: "Duplicate Min percentage!",
        text: "A grade with the same Min percentage already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (isMaxMarkExist) {
      Swal.fire({
        title: "Duplicate Max percentage!",
        text: "A grade with the same Max percentage already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (isGradeExist) {
      Swal.fire({
        title: "Duplicate Grade!",
        text: "A grade with the same name already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (!grade || !max_mark || !min_mark) {
      Swal.fire({
        title:"Incomplete Information",
        text: "All fields must be filled out",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }
    if (grade.length > 25) {
      Swal.fire({
        title: "Invalid Grade!",
        text: "Grade must be 25 characters or fewer.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Validate that max_mark and min_mark are valid integers
    if (
      isNaN(max_mark) ||
      isNaN(min_mark) ||
      !Number.isInteger(parseFloat(max_mark)) ||
      !Number.isInteger(parseFloat(min_mark))
    ) {
      Swal.fire({
        title: "Invalid Number!",
        text: "Max Marks and Min Marks must be valid integers.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Validate that max_mark is an integer and <= 100 (percentage)
    if (parseFloat(max_mark) > 100) {
      Swal.fire({
        title: "Invalid percentage!",
        text: "Maximum percentage cannot be greater than 100.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Validate that min_mark is not greater than max_mark
    if (parseFloat(min_mark) > parseFloat(max_mark)) {
      Swal.fire({
        title: "Invalid percentage!",
        text: "Minimum percentage cannot be greater than Maximum percentage.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // NEW VALIDATION: Check if max_mark is equal to min_mark
    if (parseFloat(min_mark) === parseFloat(max_mark)) {
      Swal.fire({
        title: "Invalid percentage!",
        text: "Minimum percentage cannot be the same as Maximum percentage.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Create the new grade object
    const newGrade = {
      grade,
      max_mark: parseInt(max_mark),
      min_mark: parseInt(min_mark),
    };

    // Update the grades array in formData
    setFormData((prevData) => ({
      ...prevData,
      grades: [...prevData.grades, newGrade],
      grade: "", // Reset form fields
      max_mark: "",
      min_mark: "",
    }));
  };

  const deleteGrade = (index, _id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this grade?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        // Delete the grade from the grades array
        const updatedGrades = formData.grades.filter((_, i) => i !== index);
        setFormData((prevData) => ({
          ...prevData,
          grades: updatedGrades,
        }));
        Swal.fire("Deleted!", "The grade has been deleted.", "success");
      }
    });

    if(index == editGradeIndex)
      {
        setIsGradeEditing(false)
        setFormData((prevState) => ({
          ...prevState,
          grade: "",
          max_mark: "",
          min_mark: "",
        }));
      } 
  };

  const editGrade = (index, _id) => {
    // Get the selected grade object from the grades array
    const selectedGrade = formData.grades[index];

    // Set the form fields with the selected grade values
    setFormData({
      ...formData,
      grade: selectedGrade.grade,
      max_mark: selectedGrade.max_mark,
      min_mark: selectedGrade.min_mark,
      _id: selectedGrade._id,
      indexofList: index,
    });

    setIsGradeEditing(true);
    setEditGradeIndex(index);
  };

  const submitEditedGrade = () => {
    const { grade, max_mark, min_mark, indexofList } = formData;


    const isGradeExist = formData.grades.some(
      (g, index) =>
        g.grade.trim().toLowerCase() === grade.trim().toLowerCase() && // Normalize both to lowercase and trim spaces before comparison
        (index !== indexofList || 
          g.grade.trim().toLowerCase() !== formData.grades[indexofList]?.grade.trim().toLowerCase()) // Check if grade name has changed, with trim and case-insensitive check
    );
    // If the grade has not been changed, allow it to be saved as is
    const isGradeUnchanged = formData.grades.some(
      (g) =>
        g.indexofList === indexofList &&
        g.grade === grade &&
        g.max_mark === parseFloat(max_mark) &&
        g.min_mark === parseFloat(min_mark)
    );

    // If the grade is unchanged, skip the duplicate check and proceed with the update
    if (isGradeUnchanged) {
      const updatedGrades = [...formData.grades];
      const gradeIndex = updatedGrades.findIndex(
        (g) => g.indexofList === indexofList
      );

      if (gradeIndex !== -1) {
        // Update only the specific grade
        updatedGrades[gradeIndex] = { grade, max_mark, min_mark, indexofList };
      } else {
        Swal.fire({
          title: "Grade Not Found",
          text: "Grade not found for editing.",
          icon: "warning",
          confirmButtonText: "OK",
        });
        return;
      }

      // Update state with the new grades array
      setFormData((prevData) => ({
        ...prevData,
        grades: updatedGrades,
        grade: "", // Reset form fields
        max_mark: "",
        min_mark: "",
      }));

      // Exit edit mode
      setIsGradeEditing(false);
      setEditGradeIndex(null);

      // Show success message
      Swal.fire({
        title: "Success!",
        text: "Grade successfully updated.",
        icon: "success",
        confirmButtonText: "OK",
      });
      return; // Exit function here if no changes
    }

    // If grade already exists (excluding the current grade being edited)
    if (isGradeExist) {
      Swal.fire({
        title: "Duplicate Grade!",
        text: "A grade with this name already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return; // Exit function if duplicate grade is found
    }

    // Validate Max and Min Marks
    const isMaxMarkExistwithMax = formData.grades.some(
      (g) => g.max_mark === parseFloat(min_mark)
    );
    const isMaxMarkExistwithMin = formData.grades.some(
      (g) => g.min_mark === parseFloat(max_mark)
    );

    if (isMaxMarkExistwithMax) {
      Swal.fire({
        title: "Duplicate Max percentage!",
        text: "A grade with the same max percentage already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (isMaxMarkExistwithMin) {
      Swal.fire({
        title: "Duplicate Min percentage!",
        text: "A grade with the same Min percentage already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    const isRangeOverlap =
      formData.grades.length > 1 && // Ensure there is more than one entry
      (parseFloat(max_mark) !==
        parseFloat(formData.grades[indexofList]?.max_mark) ||
        parseFloat(min_mark) !==
          parseFloat(formData.grades[indexofList]?.min_mark)) // Check only if values have changed
        ? formData.grades.some((existingGrade, idx) => {
            // Skip checking against the current grade being edited
            if (idx === indexofList) return false;

            return (
              (parseFloat(min_mark) >= existingGrade.min_mark &&
                parseFloat(min_mark) <= existingGrade.max_mark) || // Min mark within existing range
              (parseFloat(max_mark) >= existingGrade.min_mark &&
                parseFloat(max_mark) <= existingGrade.max_mark) || // Max mark within existing range
              (parseFloat(min_mark) <= existingGrade.min_mark &&
                parseFloat(max_mark) >= existingGrade.max_mark) // New range fully covers existing range
            );
          })
        : false;

    if (isRangeOverlap) {
      Swal.fire({
        title: "Invalid Range!",
        text: "The new grade range overlaps with an existing range.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

 // Validation for empty fields
 if (grade === "" || max_mark === "" || min_mark === "" || grade == null || max_mark == null || min_mark == null) {
  Swal.fire({
    title: "Incomplete Information",
    text: "All fields must be filled out.",
    icon: "warning",
    confirmButtonText: "OK",
  });
  return;
}

    if (grade.length > 25) {
      Swal.fire({
        title: "Invalid Grade!",
        text: "Grade must be 25 characters or fewer.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (isNaN(max_mark) || isNaN(min_mark)) {
      Swal.fire({
        title: "Invalid Number!",
        text: "Max percentage and Min percentage must be valid numbers.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (parseFloat(max_mark) > 100) {
      Swal.fire({
        title: "Invalid percentage!",
        text: "Maximum percentage cannot be greater than 100.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (!Number.isInteger(parseFloat(min_mark))) {
      Swal.fire({
        title: "Invalid percentage!",
        text: "Minimum percentage must be a valid integer.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (parseFloat(min_mark) > parseFloat(max_mark)) {
      Swal.fire({
        title: "Invalid percentage!",
        text: "Minimum percentage cannot be greater than Maximum Marks.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Update the grades array with the edited grade
    const updatedGrades = [...formData.grades];

    // Find the grade to update and replace it in the array (using _id)
    const gradeIndex = updatedGrades.findIndex((g, idx) => idx === indexofList);

    if (gradeIndex !== -1) {
      updatedGrades[gradeIndex] = { grade, max_mark, min_mark, indexofList };
    } else {
      Swal.fire({
        title: "Grade Not Found",
        text: "Grade not found for editing.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Update state with the new grades array
    setFormData((prevData) => ({
      ...prevData,
      grades: updatedGrades,
      grade: "", // Reset form fields
      max_mark: "",
      min_mark: "",
    }));

    // Exit edit mode
    setIsGradeEditing(false);
    setEditGradeIndex(null);

    // Show success message
    Swal.fire({
      title: "Success!",
      text: "Grade successfully updated.",
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  // Render Table

  const renderGradeTable = () => (
    <div>
      <div style={{ overflowX: "auto" }}>
          <Table bordered striped responsive>
            <thead style={{ backgroundColor: "#f8f9fa", color: "#495057" }}>
              <tr>
                <th>% From</th>
                <th>% To</th>
                <th>Grade</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(formData.grades) && formData.grades.length > 0 ? (
                formData.grades.map((grade, index) => (
                <tr key={index}>
                  <td>{grade.min_mark}</td>
                  <td>{grade.max_mark}</td>
                  <td>{grade.grade}</td>
                  <td>
                    <FaRegEdit
                      title="Edit"
                      onClick={() => editGrade(index, grade._id)} // Edit action
                      style={{ cursor: "pointer", margin: "0 6px" }}
                    />
                    <AiOutlineDelete
                      title="Delete"
                      onClick={() => deleteGrade(index, grade._id)} // Delete action
                      style={{ cursor: "pointer", margin: "0 6px" }}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="h5 py-3 text-center">
                  No grades available
                </td>
              </tr>
            )}
          </tbody>
          <style jsx="true">{`
              table td {
                white-space: nowrap;
              }
              table th {
                white-space: nowrap;
              }

              @media (max-width: 768px) {
                table td,
                table th {
                  padding: 5px;
                  white-space: nowrap;
                }
              }

              @media (max-width: 576px) {
                table td,
                table th {
                  white-space: nowrap;
                }
              }
            `}</style>
        </Table>
      </div>
    </div>
  );

  return (
    <div>
      <Card style={{ margin: "0 auto" }}>
      <CardBody>
          <CardTitle tag="h3" className="mb-1 text-center">
              Exam Subject
            </CardTitle>
          <Form className="mt-5 " onSubmit={handleCreate}>
            <Row>
              <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="academicYearId">
                    <span style={{ fontWeight: "bold" }}>Academic Year*</span>
                  </Label>
                  <Select
                    name="academicYearId"
                    options={academicYears}
                    value={
                      academicYears.find(
                        (ay) => ay.value === formData.academicYearId
                      ) || null
                    }
                    onChange={handleSelectChange}
                    isClearable
                    placeholder="Select Academic Year"
                    required
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>

              <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="branch">
                    <span style={{ fontWeight: "bold" }}>Branch*</span>
                  </Label>
                  <Select
                    name="branchId"
                    options={branches}
                    value={
                      branches.find(
                        (branch) => branch.value === formData.branchId
                      ) || null
                    }
                    onChange={handleSelectChange}
                    isClearable
                    placeholder="Select a Branch"
                    required
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>

              <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="class">
                    <span style={{ fontWeight: "bold" }}>Class*</span>
                  </Label>
                  <Select
                    name="classId"
                    options={classes}
                    value={
                      classes.find((cls) => cls.value === formData.classId) ||
                      null
                    }
                    onChange={handleSelectChange}
                    isClearable
                    placeholder="Select a Class"
                    required
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="examName">
                    <span style={{ fontWeight: "bold" }}>Exam Name*</span>
                  </Label>
                  <Select
                    name="examId"
                    options={examNames}
                    value={
                      examNames.find(
                        (exam) => exam.value === formData.examId
                      ) || null
                    }
                    onChange={handleSelectChange}
                    isClearable
                    placeholder="Select an Exam Name"
                    required
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>

              <Col xs={12} sm={6} md={4} xl={3}>
                <FormGroup>
                  <Label for="subjects">
                    <span style={{ fontWeight: "bold" }}>Subjects*</span>
                  </Label>
                  <Select
                    name="subjectId"
                    options={subjects}
                    value={
                      subjects.find(
                        (subject) => subject.value === formData.subjectId
                      ) || null
                    }
                    onChange={(selectedOption) =>
                      setFormData((prev) => ({
                        ...prev,
                        subjectId: selectedOption ? selectedOption.value : null,
                      }))
                    }
                    isClearable
                    placeholder="Select a Subject"
                    required
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>

              <Col
                xs={12}
                sm={6}
                md={4}
                xl={3}
                className=" mt-xl-4 pt-xl-3 mt-2 ms-2"
              >
                <FormGroup check>
                  <Label check className=" fw-bold">
                    <Input
                      type="checkbox"
                      name="isGrade"
                      id="isGrade"
                      checked={formData.isGrade}
                      onChange={handleCheckboxChange}
                      style={{
                        transform: "scale(1.5)",
                        width: "12px",
                        height: "12px",
                      }}
                    />
                    Is Grade
                  </Label>
                  <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Enable this option if the subject is graded and should not be included in the total exam marks"
                      className="ms-1"
                      style={{
                        cursor: "pointer",
                        fontSize: "1rem",
                        color: "#fb0f01",
                      }}
                    >
                      <i className="bi bi-info-circle"></i>
                    </span>
                </FormGroup>
              </Col>
            </Row>

            <Row
              className="mt-4 pt-2"
              style={{
                border: "1px solid #ccc",
                padding: "10px 0px",
                margin: "5px 0",
              }}
            >
              {examType.length > 0 && !isGrade && (
                <Col
                  xs={12}
                  sm={6}
                  md={4}
                  xl={3}
                  className="px-3"
                  style={{ padding: "2px" }}
                >
                  <FormGroup>
                    <Label for="examType">
                      <span style={{ fontWeight: "bold", fontSize: "0.95rem" }}>
                        Exam Type
                      </span>
                    </Label>

                    <Select
                      name="examTypeId"
                      options={examType}
                      value={
                        examType.find(
                          (exam) => exam.value === formData.examTypeId
                        ) || null
                      }
                      onChange={handleSelectChange}
                      isClearable
                      placeholder="Select an Exam Type"
                      styles={customStyles}
                      required={formData.marks.length === 0}
                    />
                  </FormGroup>
                </Col>
              )}

              <Col
                xs={12}
                sm={6}
                md={4}
                xl={3}
                className="px-3"
                style={{ padding: "2px" }}
              >
                <FormGroup>
                  <Label for="totalMarks">
                    <span style={{ fontWeight: "bold", fontSize: "0.95rem" }}>
                      Total Marks*
                    </span>
                  </Label>
                  {formData.isGrade && (
                       <span
                       data-bs-toggle="tooltip"
                       data-bs-placement="top"
                       title="Enter the total marks if you wish to record obtained marks for this graded subject. Otherwise, leave it as zero."
                       className="ms-1"
                       style={{
                         cursor: "pointer",
                         fontSize: "1rem",
                         color: "#fb0f01",
                       }}
                     >
                       <i className="bi bi-info-circle"></i>
                     </span>
                  )}
                  <Input
                    type="text"
                    name="totalMarks"
                    id="totalMarks"
                    placeholder="Total Marks"
                    value={formData.totalMarks}
                    onChange={(e) => {
                      const { value } = e.target;

                      // Allow only digits and limit to 12 digits for totalMarks
                      if (/^\d*$/.test(value)) {
                        if (value.length <= 12) {
                          setFormData((prev) => ({
                            ...prev,
                            totalMarks: value,
                          }));
                          setError((prev) => ({ ...prev, totalMarks: "" })); // Clear error when input is valid
                        } else {
                          setError((prev) => ({
                            ...prev,
                            totalMarks:
                              "Total Marks must be a 12-digit number or less.",
                          }));
                          setTimeout(() => {
                            setError((prev) => ({ ...prev, totalMarks: "" }));
                          }, 1500);
                        }
                      } else {
                        setError((prev) => ({
                          ...prev,
                          totalMarks: "Only numeric values are allowed.",
                        }));
                        setTimeout(() => {
                          setError((prev) => ({ ...prev, totalMarks: "" }));
                        }, 1500);
                      }
                    }}
                    style={{
                      padding: "4px 12px",
                      fontSize: "0.95rem",
                      height: "37px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                    }}
                    required={formData.marks.length === 0}
                  />

                  {error.totalMarks && (
                    <div
                      style={{
                        color: "red",
                        fontSize: "0.87rem",
                        marginTop: "4px",
                      }}
                    >
                      {error.totalMarks}
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col
                xs={12}
                sm={6}
                md={4}
                xl={3}
                className="px-3"
                style={{ padding: "2px" }}
              >
                <FormGroup>
                  <Label for="passingMarks">
                    <span style={{ fontWeight: "bold", fontSize: "0.95rem" }}>
                      Passing Marks*
                    </span>
                  </Label>
                  <Input
                    type="text"
                    name="passingMarks"
                    id="passingMarks"
                    placeholder="Passing Marks"
                    value={formData.passingMarks}
                    onChange={(e) => {
                      const { value } = e.target;

                      // Allow only digits and limit to 12 digits for passingMarks
                      if (/^\d*$/.test(value)) {
                        if (value.length <= 12) {
                          setFormData((prev) => ({
                            ...prev,
                            passingMarks: value,
                          }));
                          setError((prev) => ({ ...prev, passingMarks: "" })); // Clear error when input is valid
                        } else {
                          setError((prev) => ({
                            ...prev,
                            passingMarks:
                              "Passing Marks must be a 12-digit number or less.",
                          }));
                          setTimeout(() => {
                            setError((prev) => ({ ...prev, passingMarks: "" }));
                          }, 2000);
                        }
                      } else {
                        setError((prev) => ({
                          ...prev,
                          passingMarks: "Only numeric values are allowed.",
                        }));
                        setTimeout(() => {
                          setError((prev) => ({ ...prev, passingMarks: "" }));
                        }, 2000);
                      }
                    }}
                    style={{
                      padding: "4px 12px",
                      fontSize: "0.95rem",
                      height: "37px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                    }}
                    required={formData.marks.length === 0}
                  />

                  {error.passingMarks && (
                    <div
                      style={{
                        color: "red",
                        fontSize: "0.87rem",
                        marginTop: "4px",
                      }}
                    >
                      {error.passingMarks}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col
                xs={12}
                sm={6}
                md={4}
                xl={3}
                className="px-3"
                style={{ padding: "2px" }}
              >
                <FormGroup>
                  <Label for="examDate">
                    <span style={{ fontWeight: "bold", fontSize: "0.95rem" }}>
                      Exam Date*
                    </span>
                  </Label>
                  <Input
                    type="date"
                    name="examDate"
                    id="examDate"
                    placeholder="Exam Date"
                    value={formData.examDate}
                    onChange={handleChange}
                    style={{
                      padding: "4px 12px",
                      fontSize: "0.95rem",
                      height: "37px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                    }}
                    // required={formData.marks.length === 0}
                    required={formData.marks.length === 0 && !formData.isGrade}
                  />
                </FormGroup>
              </Col>

              <Col
                xs={12}
                sm={6}
                md={4}
                xl={3}
                className="px-3"
                style={{ padding: "2px" }}
              >
                <FormGroup>
                  <Label for="examTime">
                    <span style={{ fontWeight: "bold", fontSize: "0.95rem" }}>
                      Exam Start Time*
                    </span>
                  </Label>
                  <Input
                    type="time"
                    name="examTime"
                    id="examTime"
                    placeholder="Exam Start Time"
                    value={formData.examTime}
                    onChange={handleChange}
                    style={{
                      padding: "4px 12px",
                      fontSize: "0.95rem",
                      height: "37px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                    }}
                    required={formData.marks.length === 0 && !formData.isGrade}
                  />
                </FormGroup>
              </Col>

              <Col
                xs={12}
                sm={6}
                md={4}
                xl={3}
                className="px-3"
                style={{ padding: "2px" }}
              >
                <FormGroup>
                  <Label for="examEndTime">
                    <span style={{ fontWeight: "bold", fontSize: "0.95rem" }}>
                      Exam End Time*
                    </span>
                  </Label>
                  <Input
                    type="time"
                    name="examEndTime"
                    id="examEndTime"
                    placeholder="Exam End Time"
                    value={formData.examEndTime}
                    onChange={handleChange}
                    style={{
                      padding: "4px 12px",
                      fontSize: "0.95rem",
                      height: "37px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                    }}
                    required={formData.marks.length === 0 && !formData.isGrade}
                  />
                </FormGroup>
              </Col>

              <Col
                xs={12}
                sm={6}
                xl={3}
                className="mt-sm-2  px-3"
                style={{ padding: "2px" }}
              >
                {!isEditing && (
                  <div>
                    {examType.length > 0 && !isGrade && (
                      <Button
                        color="primary"
                        className="mt-xl-4 btn-no-radius"
                        onClick={addSubjectPaper}
                      >
                        Add Exam Type
                      </Button>
                    )}
                  </div>
                )}

                {isEditing && (
                  <Button
                    color="primary"
                    className="mt-sm-4 btn-no-radius"
                    onClick={editSubjectPaper}
                    style={{
                      marginLeft: "25px",
                    }}
                  >
                    Edit Exam Type
                  </Button>
                )}
              </Col>
              {examType.length > 0 && !isGrade && <div>{renderTable()}</div>}
            </Row>

            {!formData.isGrade && (
            <div className=" mt-4 ">
              <Card
                className=" shadow-none  rounded-0  "
                style={{
                  border: "1px solid #ccc",
                }}
              >
                <CardBody className="p-0 pb-2">
                  <div className="d-flex justify-content-between align-items-center px-3 pt-1">
                    <CardTitle className="mt-2" tag="h4">
                      Subject Grade
                    </CardTitle>
                  </div>
                  <hr className="p-0 m-0"></hr>
                  <Form className="mt-3 px-3" onSubmit={handleCreate}>
                    <Row className="mt-0 pt-2">
                      <Col xs={12} sm={6} md={4} xl={3}>
                        <FormGroup>
                          <Label for="min_mark">
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "0.85rem",
                              }}
                            >
                              Minimum Percentage
                            </span>
                          </Label>
                          <Input
                            type="text"
                            name="min_mark"
                            id="min_mark"
                            placeholder="Min Percentage"
                            value={formData.min_mark}
                            onChange={handleChange}
                            style={{
                              padding: "4px",
                              fontSize: "0.85rem",
                              height: "37px",
                              borderRadius: "4px",
                              border: "1px solid #ccc",
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col xs={12} sm={6} md={4} xl={3}>
                        <FormGroup>
                          <Label for="max_mark">
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "0.85rem",
                              }}
                            >
                              Maximum Percentage
                            </span>
                          </Label>
                          <Input
                            type="text"
                            name="max_mark"
                            id="max_mark"
                            placeholder="Max Percentage"
                            value={formData.max_mark}
                            onChange={handleChange}
                            style={{
                              padding: "4px",
                              fontSize: "0.85rem",
                              height: "37px",
                              borderRadius: "4px",
                              border: "1px solid #ccc",
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col xs={12} sm={6} md={4} xl={3}>
                        <FormGroup>
                          <Label for="grade">
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "0.85rem",
                              }}
                            >
                              Grade
                            </span>
                          </Label>
                          <Input
                            type="text"
                            name="grade"
                            id="grade"
                            placeholder="Grade"
                            value={formData.grade}
                            onChange={handleChange}
                            style={{
                              padding: "4px",
                              fontSize: "0.85rem",
                              height: "37px",
                              borderRadius: "4px",
                              border: "1px solid #ccc",
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        sm={6}
                        xl={3}
                        className="mt-sm-2 mt-md-0 mt-lg-0 mt-xl-2  mb-4"
                      >
                        {!isGradeEditing && (
                          <Button
                            color="primary"
                            className=" mt-sm-4 mt-md-0 mt-lg-0 mt-xl-4 btn-no-radius"
                            onClick={addGrade} // Assuming you have this handler
                            style={{
                              marginLeft: "2px",
                            }}
                          >
                            Add Grade
                          </Button>
                        )}
                        {isGradeEditing && (
                          <Button
                            color="primary"
                            className="mt-sm-4 btn-no-radius"
                            onClick={submitEditedGrade} // Assuming you have this handler
                            style={{
                              marginLeft: "25px",
                            }}
                          >
                            Edit Grade
                          </Button>
                        )}
                      </Col>

                      {renderGradeTable()}
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </div>
            )}

            <div className="d-flex justify-content-center mt-3">
              <Button
                className="btn-no-radius"
                type="submit"
                color="primary"
                disabled={loading}
              >
                {loading ? <Spinner size="sm" /> : "Save"}
              </Button>
              <Button
                className="btn-no-radius mx-2"
                color="secondary"
                onClick={handleCancel}
                disabled={loading}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default CreateExamSubject;
