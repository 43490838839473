import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import axiosInstance from "../../../../middleware/axiosInstance";
import Select from "react-select";

const EditFeesType = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Get feesType ID from route parameters
  const [formData, setFormData] = useState({
    feesType: "",
    branchName: "",
    className: "",
    academicYear: "",
    academicYearId: "",
    amount: "",
    reminderDays: "",
    dueDate:''
  });

  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [errors, setErrors] = useState({
    feesType: "",
    amount: "",
  });

  const reminderDaysOptions = [
    { value: 1, label: "1 Day Before" },
    { value: 2, label: "2 Day Before" },
    { value: 3, label: "3 Day Before" },
    { value: 4, label: "4 Day Before" },
    { value: 5, label: "5 Day Before" },
    { value: 6, label: "6 Day Before" },
    { value: 7, label: "7 Day Before" },
    { value: 8, label: "8 Day Before" },
    { value: 9, label: "9 Day Before" },
    { value: 10, label: "10 Day Before" },
  ];

  const handleSelectChange = (selectedOption, { name }) => {
    setFormData((prev) => ({
      ...prev,
      [name]: selectedOption ? selectedOption.value : null,
    }));
  };

  // Fetch fees type details
  const fetchFeesType = async () => {
    try {
      const response = await axiosInstance.get(`/details/feestype/${id}`);
      const fetchFeesType = response.data;
      setFormData({
        branchName: fetchFeesType.branch_id.branchName,
        className: fetchFeesType.class_id.className,
        academicYear: fetchFeesType.academicyear_id.year,
        academicYearId: fetchFeesType.academicyear_id._id,
        feesType: fetchFeesType.feesType,
        amount: fetchFeesType.amount || "",
        reminderDays: fetchFeesType.reminderDays || "",
        dueDate: fetchFeesType.dueDate ? new Date(fetchFeesType.dueDate).toISOString().split('T')[0] : '',
      });
    } catch (error) {
      console.error("Error fetching fees type details:", error);
      Swal.fire("Error", "Failed to load fees type details", "error");
    } finally {
      setInitialLoading(false);
    }
  };

  useEffect(() => {
    fetchFeesType();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Validation logic for amount
    if (name === "amount") {
      // Check if the input contains only digits (no characters or special symbols)
      const isNumber = /^[0-9]*$/.test(value);

      if (!isNumber) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          amount:
            "Only numbers are allowed. Special characters and letters are not permitted.",
        }));
      } else if (value.length > 10) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          amount:
            "Please enter a valid amount (less than or equal to 10 digits).",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          amount: "", // Clear error if valid
        }));
      }
    }
    // Validation logic for className
    else if (name === "feesType") {
      const words = value.trim().split(/\s+/);
      const wordCount = words.length;

      // Check if word count exceeds 20
      if (wordCount > 40) {
        setErrors({
          ...errors,
          [name]: "Fees Type must be less than 40 words.",
        });
      }
      // Check if any word exceeds 30 characters
      else if (words.some((word) => word.length > 50)) {
        setErrors({
          ...errors,
          [name]: "Each word must be less than 50 characters.",
        });
      }
      // No errors
      else {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
      }
    }
  };

  const handleEditFees = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Check for errors before submitting
    if (errors.feesType || errors.amount) {
      setLoading(false);
      return; // Prevent form submission
    }

    try {
      await axiosInstance.put(`/update/feestype/${id}`, formData);
      navigate("/feestypes");
    } catch (error) {
      console.error("Error updating fees type:", error);
    }
    setLoading(false);
  };

  const handleCancel = () => {
    navigate("/feestypes");
  };

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#FB0F01" : provided.borderColor,
      boxShadow: state.isFocused ? "0 0 0 1px #FB0F01" : null,
      borderWidth: state.isFocused ? "0px" : "1px",
      transition: "border-color 0.2s, box-shadow 0.2s",
      "&:hover": {
        borderColor: "#FB0F01",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#FFFFFF",
      zIndex: 999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FDD7C2" : "#FFFFFF",
      color: "black",
      "&:hover": {
        backgroundColor: "#FDD7C2",
        color: "black",
      },
      "&:active": {
        backgroundColor: "#E8F0FE",
      },
    }),
  };

  return (
    <div>
      <Card style={{  margin: "0 auto" }}>
        <CardBody>
          <CardTitle tag="h3" className="mb-1 text-center">
            Edit Fees Type
          </CardTitle>
          {initialLoading ? (
            <div className="text-center">
              <Spinner color="primary" />
            </div>
          ) : (
            <Form className="mt-5" onSubmit={handleEditFees}>
              <Row>
                <Col md={3}>
                  <FormGroup>
                    <Label for="branch">
                      <span style={{ fontWeight: "bold" }}>Branch*</span>
                    </Label>
                    <Input
                      type="text"
                      name="branch"
                      id="branch"
                      value={formData.branchName} // Use branchName from formData
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="class">
                      <span style={{ fontWeight: "bold" }}>Class*</span>
                    </Label>
                    <Input
                      type="text"
                      name="class"
                      id="class"
                      value={formData.className} // Use className from formData
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="academicYear">
                      <span style={{ fontWeight: "bold" }}>Academic Year*</span>
                    </Label>
                    <Input
                      type="text"
                      name="academicYear"
                      id="academicYear"
                      value={formData.academicYear} // Use className from formData
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="feesType">
                      <span style={{ fontWeight: "bold" }}>Fees Type*</span>
                    </Label>
                    <Input
                      type="text"
                      name="feesType"
                      id="feesType"
                      value={formData.feesType}
                      onChange={handleChange}
                    />
                    {errors.feesType && (
                      <div
                        style={{
                          color: "red",
                          marginTop: "0.25rem",
                          fontSize: "0.875rem",
                        }}
                      >
                        {errors.feesType}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="amount">
                      <span style={{ fontWeight: "bold" }}>Amount*</span>
                    </Label>
                    <Input
                      type="text"
                      name="amount"
                      id="amount"
                      placeholder="Enter amount"
                      value={formData.amount}
                      onChange={handleChange}
                      inputMode="numeric"
                      required
                      maxLength="11"
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allow only digits
                      }} // Limit input length to 10 characters visually
                    />
                    {errors.amount && (
                      <div
                        style={{
                          color: "red",
                          marginTop: "0.25rem",
                          fontSize: "0.875rem",
                        }}
                      >
                        {errors.amount}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={4} xl={3}>
                  <FormGroup>
                    <Label for="reminderDays">
                      <span style={{ fontWeight: "bold" }}>
                        Due Date Reminder
                      </span>
                    </Label>
                    <Select
                      name="reminderDays"
                      options={reminderDaysOptions}
                      value={reminderDaysOptions.find(
                        (option) => option.value === formData.reminderDays
                      )}
                      onChange={handleSelectChange}
                      isClearable
                      placeholder="Select Reminder Days"
                      styles={customStyles}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={4} xl={3}>
                      <FormGroup>
                        <Label for="dueDate">
                          <span style={{ fontWeight: "bold" }}>
                            Due Date
                          </span>
                        </Label>
                        <Input
                          type="date"
                          name="dueDate"
                          id="dueDate"
                          value={formData.dueDate}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
              </Row>
              <div className="d-flex justify-content-center mt-3">
                <Button
                  className="btn-no-radius"
                  type="submit"
                  color="primary"
                  disabled={loading}
                >
                  {loading ? <Spinner size="sm" /> : "Save"}
                </Button>
                <Button
                  className="btn-no-radius mx-2"
                  color="secondary"
                  onClick={handleCancel}
                  disabled={loading}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default EditFeesType;
