import React, { useState, useEffect } from 'react'
import {
  Card,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  Spinner
} from 'reactstrap'
import axiosInstance from '../../../../middleware/axiosInstance'
import { useParams } from 'react-router-dom'
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const Setting = () => {
  const [formData, setFormData] = useState({
    inchargeSignature: false,
    principalSignature: false,
    marksPattern: {
      grade: false,
      marks: false
    },
    pageLayout: {
      portrait: false,
      landscape: false
    },
    examoverallgrade: false,
    principal: { file: null, preview: null, url: null },
    incharge: { file: null, preview: null, url: null },
    sectionId: "",
    sectionFiles: {}
  })

  const [disableportrait, setDisablePortrait] = useState(false)
  const [loading, setLoading] = useState(false);
  const [sections, setSections] = useState([]);
  const navigate = useNavigate();
  const [fetchedSectionUrls, setFetchedSectionUrls] = useState({});

  const { id } = useParams()

  const fetchExam = async () => {
    try {
      const response = await axiosInstance.get(`/exam/details/exam/${id}`);
      const fetchedExam = response.data;

      if (fetchedExam.examtype_id.length > 0) {
        setDisablePortrait(true);
      }

      const sectionUrls = fetchedExam?.config?.inchargeSignature?.sectionurl.reduce((acc, { section_id, sectioninchargeurl }) => {
        acc[section_id] = sectioninchargeurl; 
        return acc;
      }, {});

      setFetchedSectionUrls(sectionUrls);

      const sectionFiles = fetchedExam?.config?.inchargeSignature?.sectionurl.reduce((acc, { section_id, sectioninchargeurl }) => {
        acc[section_id] = {
          file: null,
          preview: sectioninchargeurl, 
        };
        return acc;
      }, {});

      setFormData((prevFormData) => ({
        ...prevFormData,
        inchargeSignature: fetchedExam?.config?.inchargeSignature?.isenable || false,
        principalSignature: fetchedExam?.config?.principalSignature?.isenable || false,
        examoverallgrade: fetchedExam?.config?.examoverallgrade || false,
        marksPattern: {
          grade: fetchedExam?.config?.marksPattern?.grade || false,
          marks: fetchedExam?.config?.marksPattern?.marks || false,
        },
        pageLayout: {
          portrait: fetchedExam?.config?.pageLayout?.portrait || false,
          landscape: fetchedExam?.config?.pageLayout?.landscape || false,
        },
        principal: {
          ...prevFormData.principal,
          url: fetchedExam?.branch_id?.principalSignImage || null,

        },
        incharge: {
          ...prevFormData.incharge,
          file: null,
          url: fetchedExam?.config?.inchargeSignature?.signurl || null,
        },
        sectionFiles,
      }));

      const sortedSections = fetchedExam.section_id.sort((a, b) =>
        a.sectionName.localeCompare(b.sectionName)
      );
      setSections(sortedSections);

    } catch (error) {
      // console.error("Error fetching exam details:", error);
    } 
  };

  useEffect(() => {
    fetchExam();
  }, [id]);




  const handleChange = e => {
    const { name, value, type, checked } = e.target

    if (type === 'checkbox') {
      if (name.startsWith('marksPattern')) {
        const key = name.split('.')[1]
        setFormData({
          ...formData,
          marksPattern: {
            ...formData.marksPattern,
            [key]: checked
          }
        })
      } else {
        setFormData({
          ...formData,
          [name]: checked
        })
      }
    } else if (type === 'radio') {
      if (name === 'pageLayout') {

        setFormData({
          ...formData,
          pageLayout: {
            ...formData.pageLayout,
            [value]: true,
            [value === 'portrait' ? 'landscape' : 'portrait']: false
          }
        })
      }
    }
    else if (name === 'sectionId') {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  }

  const handleFileChange = (event, sectionId) => {
    const file = event.target.files[0];
    const reader = new FileReader();
  
    reader.onloadend = () => {
      if (file) {
        const updatedSectionFiles = {
          ...formData.sectionFiles,
          [sectionId]: {
            file,
            preview: reader.result,
            name: file.name, // Store the file name
          },
        };
  
        setFormData((prevFormData) => ({
          ...prevFormData,
          sectionFiles: updatedSectionFiles,
        }));
      }
    };
  
    if (file) {
      reader.readAsDataURL(file);
      event.target.value = null; 
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        sectionFiles: {
          ...prevFormData.sectionFiles,
          [sectionId]: {
            file: null,
            preview: formData.sectionFiles[sectionId]?.url || null,
            name: null, // Reset name
          },
        },
      }));
    }
  };
  

  const handleClearFile = (sectionId) => {
    setFormData((prevFormData) => {
      const updatedSectionFiles = { ...prevFormData.sectionFiles };

      // Set preview to the URL fetched earlier if available
      updatedSectionFiles[sectionId] = {
        file: null, // Reset the file
        preview: fetchedSectionUrls[sectionId] || null, // Use the fetched preview URL if available
      };

      return {
        ...prevFormData,
        sectionFiles: updatedSectionFiles,
      };
    });

    // Reset the file input field
    document.getElementById(`sectionFile-${sectionId}`).value = null;
  };

  const handleFileinChange = (event, fieldName) => {
    const file = event.target.files[0];
    const reader = new FileReader();
  
    reader.onloadend = () => {
      setFormData(prev => ({
        ...prev,
        [fieldName]: { file, preview: reader.result, url: prev[fieldName]?.url, remark: prev[fieldName]?.remark },
      }));
    };
  
    if (file) {
      reader.readAsDataURL(file); // For image/pdf preview
    } else {
      setFormData(prev => ({
        ...prev,
        [fieldName]: { file: null, preview: null },
      }));
    }
  };
  
  const handleClearinchargeFile = (fieldName) => {
    setFormData(prev => ({
      ...prev,
      [fieldName]: { file: null, preview: null, url: prev[fieldName].url , remark: prev[fieldName].remark},
    }));
    document.getElementById(`${fieldName}-input`).value = null;  
  };


  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true);

    const formDatanew = new FormData();

    formDatanew.append('incharge', formData.incharge.file);
    formDatanew.append('principal', formData.principal.file);
    formDatanew.append('inchargeSignature', formData.inchargeSignature);
    formDatanew.append('principalSignature', formData.principalSignature);
    formDatanew.append('pageLayout', JSON.stringify(formData.pageLayout));
    formDatanew.append('marksPattern', JSON.stringify(formData.marksPattern));
    formDatanew.append('examoverallgrade', formData.examoverallgrade);

    Object.keys(formData.sectionFiles).forEach((sectionId) => {
      const sectionFile = formData.sectionFiles[sectionId];
      if (sectionFile?.file) {
        formDatanew.append(`sectionFile_${sectionId}`, sectionFile.file);
      }
    });

    try {
      const response = await axiosInstance.post(
        `exam/marksheetsetting/${id}`,
        formDatanew,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )

      // navigate(-1);
    } catch (error) {
      // console.error('Error saving settings:', error)
    }
    setLoading(false);
    fetchExam()
    const inputElement = document.getElementById('incharge-input');
    if (inputElement?.value) {
      inputElement.value = null;
    }
  }
  const handleCancel = () => {
    navigate(-1);
  };

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#FB0F01" : provided.borderColor,
      boxShadow: state.isFocused ? "0 0 0 1px #FB0F01" : null,
      borderWidth: state.isFocused ? "0px" : "1px",
      transition: "border-color 0.2s, box-shadow 0.2s",
      "&:hover": {
        borderColor: "#FB0F01",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#FFFFFF",
      zIndex: 999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FDD7C2" : "#FFFFFF",
      color: "black",
      "&:hover": {
        backgroundColor: "#FDD7C2",
        color: "black",
      },
      "&:active": {
        backgroundColor: "#E8F0FE",
      },
    }),
  };

  return (
    <div>
      <Card
        style={{ boxShadow: 'none', marginBottom: '10px', borderRadius: '0' }}
      >
        <div className='d-flex justify-content-between align-items-center px-3 pt-1'>
          <CardTitle className='mt-2' tag='h4'>
            Report Card Settings
          </CardTitle>
        </div>
        <hr className='mt-1' />
        <Form onSubmit={handleSubmit} className='px-3'>
          <FormGroup>
            <Label>
              <strong>Page Layout:</strong>
            </Label>
            <div className='d-sm-flex justify-content-between align-items-center'>
              <div className='text-center' style={{ flex: 1 }}>
                <div
                  style={{
                    width: '60px',
                    height: '80px',
                    border: '1px solid #000',
                    borderRadius: '4px',
                    margin: '0 auto 8px',
                    backgroundColor: formData.pageLayout.portrait
                      ? '#007bff'
                      : '#f8f9fa'
                  }}
                ></div>
                <FormGroup check>
                  <Label check className='d-block'>
                    <div className='d-flex justify-content-center'>
                      <Input
                        type='radio'
                        name='pageLayout'
                        value='portrait'
                        checked={formData.pageLayout.portrait}
                        onChange={handleChange}
                        className='custom-radio'
                        disabled={disableportrait}
                      />
                      <strong className='ms-2'>Portrait</strong>
                      {disableportrait ? <> <span
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Portrait layout is disabled because it is not recommended for exams with multiple types (e.g., written and practical)."
                        className="ms-2"
                        style={{ cursor: 'pointer', fontSize: '1rem', color: '#fb0f01' }}
                      >
                        <i className="bi bi-info-circle"></i>
                      </span></> : <></>}

                    </div>
                  </Label>
                  <small className='text-muted d-block'>
                    (Recommended for exams without multiple types such as written, practical, etc.)
                  </small>
                </FormGroup>
              </div>

              <div className='text-center mt-4' style={{ flex: 1 }}>
                <div
                  style={{
                    width: '80px',
                    height: '60px',
                    border: '1px solid #000',
                    borderRadius: '4px',
                    margin: '0 auto 8px',
                    backgroundColor: formData.pageLayout.landscape
                      ? '#007bff'
                      : '#f8f9fa'
                  }}
                ></div>
                <FormGroup check>
                  <Label check className='d-block text-center'>
                    <div className='d-flex justify-content-center'>
                      <Input
                        type='radio'
                        name='pageLayout'
                        value='landscape'
                        checked={formData.pageLayout.landscape}
                        onChange={handleChange}
                        className='custom-radio'

                      />
                      <strong className='ms-2'>Landscape</strong>
                    </div>
                  </Label>
                  <small className='text-muted d-block'>
                    (Recommended for all exams, whether they include multiple types or not.)
                  </small>
                </FormGroup>
              </div>
            </div>
          </FormGroup>

          <FormGroup className='mt-5'>
            <Label>
              <strong>Display Marks and/or Grade:</strong>
              <span
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="If Grade is selected then grades must be entered/configured in each subjects"
                className="ms-2"
                style={{ cursor: 'pointer', fontSize: '1rem', color: '#fb0f01' }}
              >
                <i className="bi bi-info-circle"></i>
              </span>
            </Label>
            <div>
              <FormGroup check inline>
                <Input
                  type='checkbox'
                  name='marksPattern.marks'
                  checked={formData.marksPattern.marks}
                  onChange={handleChange}
                  className='custom-checkbox'
                  disabled={formData.marksPattern.marks}
                />
                <Label check>Marks</Label>
              </FormGroup>
              <FormGroup check inline>
                <Input
                  type='checkbox'
                  name='marksPattern.grade'
                  checked={formData.marksPattern.grade}
                  onChange={handleChange}
                  className='custom-checkbox'
                />
                <Label check>Grade</Label>
              </FormGroup>
            </div>
          </FormGroup>

          <FormGroup className='mt-5'>
            <Label>
              <strong>Enable/Disbale Overall Exam Grade:</strong>
              <span
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="If Overall Exam Grade is enabled then grades must be entered/configured in exam"
                className="ms-2"
                style={{ cursor: 'pointer', fontSize: '1rem', color: '#fb0f01' }}
              >
                <i className="bi bi-info-circle"></i>
              </span>
            </Label>
            <div>
              <FormGroup check inline>
                <Input
                  type='checkbox'
                  name='examoverallgrade'
                  checked={formData.examoverallgrade}
                  onChange={handleChange}
                  className='custom-checkbox'
                />
                <Label check>Enable/Disable</Label>
              </FormGroup>
            </div>
          </FormGroup>

          <FormGroup>
            <Label>
              <strong>Signatures:</strong>
            </Label>
            <Row>
              <Col sm={6} lg={6} xl={4} className='mb-5'>
                <FormGroup check>
                  <Label check>
                    <Input
                      type='checkbox'
                      name='principalSignature'
                      checked={formData.principalSignature}
                      onChange={handleChange}
                      className='custom-checkbox'
                    />
                    <strong>Enable Principal's Signature</strong>
                  </Label>
                </FormGroup>

                <Col md='12' lg='6' xl='4' style={{ width: '230px', minWidth: '230px', height: '150px' }} className='mt-2'>
                  {formData.principal.preview ? (
                    formData.principal.file.type.includes('image') ? (
                      <>
                        <Label><span style={{ fontWeight: "bold" }}>Preview:</span></Label>
                        <img className='ms-5' src={formData.principal.preview} alt="Preview" style={{ width: '100%', height: '100%', objectFit: "contain" }} /></>
                    ) : formData.principal.file.type.includes('pdf') ? (
                      <>
                        <Label><span style={{ fontWeight: "bold" }}>Preview:</span></Label>
                        <iframe className='ms-5' src={formData.principal.preview} style={{ width: '100%', height: '100%', objectFit: "contain" }} title="PDF Preview"></iframe></>
                    ) : null
                  ) : formData.principal.url ? (
                    formData.principal.url.includes('pdf') ? (
                      <>
                        <Label><span style={{ fontWeight: "bold" }}>Preview:</span></Label>
                        <iframe className='ms-5' src={formData.principal.url} style={{ width: '100%', height: '100%', objectFit: "contain" }} title="PDF Preview"></iframe></>
                    ) : (
                      <>
                        <Label><span style={{ fontWeight: "bold" }}>Preview:</span></Label>
                        <img className='ms-5' src={formData.principal.url} alt="Existing File" style={{ width: '100%', height: '100%', objectFit: "contain" }} /></>
                    )
                  ) : null}
                </Col>

              </Col>
              <Col sm={6} lg={6} xl={8}>
                <Row>
                  <Col sm={12}>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type='checkbox'
                          name='inchargeSignature'
                          checked={formData.inchargeSignature}
                          onChange={handleChange}
                          className='custom-checkbox'
                        />
                        <strong>Enable Teacher In-Charge Signature</strong>
                      </Label>
                    </FormGroup>
                    </Col>
                    <Col>
                    {sections.length === 0 ? (
                 <>
                   <div style={{ position: 'relative', width: '250px', display: 'inline-block' }}>
                  <Input
                  className='mt-2'
                    id="incharge-input"
                    type="file"
                    accept="image/*"
                    style={{ width: '100%', fontSize: '14px' }}
                    onChange={(e) => handleFileinChange(e, 'incharge')}
                  />
                  {formData.incharge.file && (
                    <RxCross2
                      onClick={() => handleClearinchargeFile('incharge')}
                      style={{
                        position: 'absolute',
                        top: '14px',
                        right: '8px',
                        cursor: 'pointer',
                        color: 'red',
                        fontSize: '20px',
                        backgroundColor:'#ffffff'
                      }}
                    />
                  )}
                  <span style={{ fontSize: "12px", color: "#d83030" }}>Maximum Upload Limit: 5MB</span>
                </div>
                 </>
                    ) : (
                      <>
                      <FormGroup className='mt-2'>
                      <Label>
                        <strong>Section:</strong>
                      </Label>
                      <Select
                        id="section"
                        options={sections.map((section) => ({
                          value: section._id,
                          label: section.sectionName,
                        }))}
                        onChange={(selected) => {
                          handleChange({
                            target: {
                              name: "sectionId",
                              value: selected ? selected.value : "", // Handle null case
                            },
                          });
                        }}
                        value={
                          sections.find(
                            (section) => section._id === formData.sectionId
                          )
                            ? {
                              value: formData.sectionId,
                              label: sections.find(
                                (section) => section._id === formData.sectionId
                              ).sectionName,
                            }
                            : null
                        }
                        placeholder={sections.length === 0 ? "No Section" : "Select a Section"}
                        isDisabled={sections.length === 0}
                        isSearchable={true}
                        isClearable={true}
                        styles={customStyles}
                      />
                    </FormGroup>
                    <Label for="sectionFile">
                        <span style={{ fontWeight: "bold" }}>Upload File for Section</span>
                      </Label>
                    <Col >
                      
                      <div style={{ position: "relative", width: "250px", display: "inline-block" }}>
                      {formData.sectionFiles[formData.sectionId]?.name && (
    <div
      style={{
        position: "absolute",
        top: "7px", // Align vertically with the input field
        left: "107px", // Add a little spacing from the left
        fontSize: "14px",
        color: "#555",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        backgroundColor: "#fff", // Add background to overlap input styles
        padding: "0 5px", // Add padding for better visibility
        zIndex: 2, // Ensure it overlaps the input field
        width: '46%',
      }}
    >
      {formData.sectionFiles[formData.sectionId]?.name}
    </div>
  )}
                        <Input
                          id={`sectionFile-${formData.sectionId}`}
                          type="file"
                          accept="image/*"
                          style={{ width: "100%", fontSize: "14px" }}
                          onChange={(e) => handleFileChange(e, formData.sectionId)}
                        />
                        {formData.sectionFiles[formData.sectionId]?.file && (
                          <RxCross2
                            onClick={() => handleClearFile(formData.sectionId)}
                            style={{
                              position: "absolute",
                              top: "7px",
                              right: "8px",
                              cursor: "pointer",
                              color: "red",
                              fontSize: "20px",
                              backgroundColor: "#ffffff",
                            }}
                          />
                        )}
                        <span style={{ fontSize: "12px", color: "#d83030" }}>
                          Maximum Upload Limit: 5MB
                        </span>
                      </div>
                    </Col>
                    </>
                    )}
                  </Col>
                  {sections.length === 0 ? (
                 
                     <Col >
                      <FormGroup>
                      <Row className=' m-0 p-0'>
                      <Col sm={6} lg={6} xl={6} style={{ width: '230px', minWidth: '230px', height: '150px' }} className='ms-0 ps-0 mt-2'>
                    {formData.incharge?.preview ? (
                      formData.incharge?.preview ? (
                        <>
                        <Label><span style={{ fontWeight: "bold" }}>Preview:</span></Label>
                        <img className='ms-5 ms-sm-3 ms-md-5' src={formData.incharge.preview} alt="Preview" style={{ width: '230px', minWidth: '230px', height: '150px', objectFit: "contain" }} /></>
                      ) : formData.incharge?.file?.type?.includes('pdf') ? (
                        <>
                        <Label><span style={{ fontWeight: "bold" }}>Preview:</span></Label>
                        <iframe className='ms-5 ms-sm-3 ms-md-5' src={formData.incharge.preview} style={{ width: '100%', height: '100%', objectFit: "contain" }} title="PDF Preview"></iframe></>
                      ) : null
                    ) : formData.incharge.url ? (
                      formData.incharge?.url?.includes('pdf') ? (
                        <>
                        <Label><span style={{ fontWeight: "bold" }}>Preview:</span></Label>
                        <iframe className='ms-5 ms-sm-3 ms-md-5' src={formData.incharge.url} style={{ width: '100%', height: '100%', objectFit: "contain" }} title="PDF Preview"></iframe></>
                      ) : (
                        <>
                        <Label><span style={{ fontWeight: "bold" }}>Preview:</span></Label>
                        <img className='ms-5 ms-sm-3 ms-md-5' src={formData.incharge.url} alt="Existing File" style={{ width: '100%', height: '100%', objectFit: "contain" }} /></>
                      )
                    ) : null}
                   </Col>
                   </Row>
                  </FormGroup>
                  </Col>
                  ) :
                  (
                    <>
                     <Col>
                    {formData.sectionId && (
                      <FormGroup>
                        <Row className=' m-0 p-0'>
                          <Col sm={6} lg={6} xl={6} style={{ width: '230px', minWidth: '230px', height: '150px' }} className='ps-0'>
                            {formData.sectionFiles[formData.sectionId]?.preview && (
                              <div className="mt-2">
                                <Label>
                                  <span style={{ fontWeight: "bold" }}>Preview:</span>
                                </Label>
                                {formData.sectionFiles[formData.sectionId]?.preview ? (
                                  <img 
                                  className='ms-5 ms-sm-3 ms-md-5'
                                    src={formData.sectionFiles[formData.sectionId]?.preview}
                                    alt="Preview"
                                    style={{ width: '230px', minWidth: '230px', height: '150px', objectFit: "contain" }}
                                  />
                                ) : formData.sectionFiles[formData.sectionId]?.file?.type.includes("pdf") ? (
                                  <iframe
                                    src={formData.sectionFiles[formData.sectionId]?.preview}
                                    style={{ width: "100%", height: "100%", objectFit: "contain" }}
                                    title="PDF Preview"
                                  />
                                ) : null}
                              </div>
                            )}
                          </Col>
                        </Row>
                      </FormGroup>
                    )}
                  </Col>
                    </>
                  )}
                </Row>
              </Col>

            </Row>
          </FormGroup>

          <div className="d-flex justify-content-center mb-3 mt-5 pt-4">
            <Button color='primary' type='submit' >
            {loading ? (
        <>
          <Spinner size="sm" style={{ marginRight: "5px" }} />
          Saving...
        </>
      ) : (
        "Save"
      )}
            </Button>
            <Button
              className="btn-no-radius mx-2"
              color="secondary"
              onClick={handleCancel}
              disabled={loading}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  )
}

export default Setting
