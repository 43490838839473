import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../../middleware/axiosInstance";

const EditExamType = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Get examType ID from route parameters
  const [formData, setFormData] = useState({
    examType: "",
    branchName: "",
  });

  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [errors, setErrors] = useState({
    examType: "",
  });

  const fetchExamType = async () => {
    try {
      const response = await axiosInstance.get(`/exam/details/examType/${id}`);
      const fetchedExamType = response.data;

      setFormData({
        branchName: fetchedExamType.branch_id.branchName,
        examType: fetchedExamType.examType, // Assuming `examType` is the field for exam type name
      });
    } catch (error) {
      console.error("Error fetching exam type details:", error);
    } finally {
      setInitialLoading(false);
    }
  };

  useEffect(() => {
    fetchExamType();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Validation logic for examType
    if (name === "examType") {
      const words = value.trim().split(/\s+/);
      const wordCount = words.length;

      // Check if word count exceeds 20
      if (wordCount > 40) {
        setErrors({
          ...errors,
          [name]: "Exam type must be less than 40 words.",
        });
      }
      // Check if any word exceeds 30 characters
      else if (words.some((word) => word.length > 50)) {
        setErrors({
          ...errors,
          [name]: "Each word must be less than 50 characters.",
        });
      }
      // No errors
      else {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
      }
    }
  };

  const handleEditExamType = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axiosInstance.put(`/exam/update/examType/${id}`, formData);
      navigate("/examtypes");
    } catch (error) {
      console.error("Error updating exam type:", error);
    }
    setLoading(false);
  };

  const handleCancel = () => {
    navigate("/examtypes");
  };


  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      {initialLoading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 999,
          }}
        >
          <Spinner color="primary" style={{ width: "3rem", height: "3rem" }} />
        </div>
      )}
      <Card style={{ margin: "0 auto" }}>
        <CardBody>
          <CardTitle tag="h3" className="mb-1 text-center">
            Edit Exam Type
          </CardTitle>
          <Form className="mt-5" onSubmit={handleEditExamType}>
            <Row>
              <Col xs={12} sm={6} md={6} xl={4}>
                <FormGroup>
                  <Label for="branch">
                    <span style={{ fontWeight: "bold" }}>Branch*</span>
                  </Label>
                  <Input
                    type="text"
                    name="branch"
                    id="branch"
                    value={formData.branchName} // Use branchName from formData
                    disabled
                  />
                </FormGroup>
              </Col>
  
              <Col xs={12} sm={6} md={6} xl={4}>
                <FormGroup>
                  <Label for="examType">
                    <span style={{ fontWeight: "bold" }}>Exam Type*</span>
                  </Label>
                  <Input
                    type="text"
                    name="examType"
                    id="examType"
                    value={formData.examType}
                    onChange={handleChange}
                    required
                    placeholder="e.g., Written" 
                  />
                  {errors.examType && (
                    <div
                      style={{
                        color: "red",
                        marginTop: "0.25rem",
                        fontSize: "0.875rem",
                      }}
                    >
                      {errors.examType}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <div className="d-flex justify-content-center mt-3">
              <Button
                className="btn-no-radius"
                type="submit"
                color="primary"
                disabled={loading}
              >
                {loading ? <Spinner size="sm" /> : "Save"}
              </Button>
              <Button
                className="btn-no-radius mx-2"
                color="secondary"
                onClick={handleCancel}
                disabled={loading}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
  



};

export default EditExamType;
