import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  Table,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../../middleware/axiosInstance";
import Select from "react-select";
import Swal from "sweetalert2";
import { FaRegEdit } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";

const EditExam = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Get examType ID from route parameters
  const [formData, setFormData] = useState({
    examName: "",
    branchName: "",
    className: "",
    branchId: "",
    classId: "",
    sectionId: [],
    examTypeId: [],
    checkExamTypeId: [],
    academicYear: "",
    grades: [],
  });

  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [sections, setSections] = useState([]); // All section options
  const [examType, setExamType] = useState([]); // All exam type options
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);

  const [errors, setErrors] = useState({
    examName: "",
  });

  const fetchExam = async () => {
    try {
      const response = await axiosInstance.get(`/exam/details/exam/${id}`);
      const fetchedExam = response.data;
      
      setFormData({
        branchId: fetchedExam.branch_id,
        classId: fetchedExam.class_id,
        branchName: fetchedExam.branch_id.branchName,
        className: fetchedExam.class_id.className,
        sectionId: fetchedExam.section_id?.map((s) => s._id) || [],
        checkExamTypeId: fetchedExam.examtype_id?.map((e) => e._id) || [],
        examTypeId: fetchedExam.examtype_id?.map((e) => e._id) || [],
        academicYear: fetchedExam.academicyear_id.year,
        examName: fetchedExam.examName,
        grades: fetchedExam.Grades
      });
    } catch (error) {
      console.error("Error fetching exam details:", error);
    } finally {
      setInitialLoading(false);
    }
  };

  useEffect(() => {
    fetchExam();
  }, [id]);



  useEffect(() => {
    const fetchSections = async () => {
      if (formData.branchId && formData.classId) {
        try {
          const response = await axiosInstance.get("/sections", {
            params: {
              branchId: formData.branchId,
              classId: formData.classId,
            },
          });

          // Sorting sections by sectionName
          const sortedSections = response.data.sort((a, b) =>
            a.sectionName.localeCompare(b.sectionName)
          );

          // Map sorted sections to the desired format
          setSections(
            sortedSections.map((section) => ({
              value: section._id,
              label: section.sectionName,
            }))
          );
        } catch (error) {
          // console.log("Failed to fetch sections.");
        }
      } else {
        setSections([]);
      }
    };

    fetchSections();
  }, [formData.branchId, formData.classId]);

  useEffect(() => {
    const fetchExamTypes = async () => {
      if (formData.branchId) {
        try {
          const response = await axiosInstance.get("/exam/examtypes/branch", {
            params: { branchId: formData.branchId },
          });

          // Set the response data in the `examType` state
          setExamType(
            response.data.map((exam) => ({
              value: exam._id,
              label: exam.examType,
            }))
          );
        } catch (error) {
          console.error("Error fetching exam types:", error);
        }
      } else {
        // Reset exam types if no branch selected
        setExamType([]);
      }
    };

    fetchExamTypes();
  }, [formData.branchId]);


  const handleSelectChange = async (selectedOptions, field) => {
    const selectedValues = selectedOptions ? selectedOptions.map((option) => option.value) : [];
  
    // Check and handle section removals
    if (field === "sectionId") {
      const removedSections = (formData[field] || []).filter(
        (sectionId) => !selectedValues.includes(sectionId)
      );
    
      if (removedSections.length > 0) {
        try {
          // API call to check if sections are used in any marks setup
          const response = await axiosInstance.get('exam/clear/section', {
            params: { examId: id }
          });
    
          const usedSections = response.data;
    
          // Check if any of the removed sections are already in use
          const conflictingSections = removedSections.filter(sectionId =>
            usedSections.includes(sectionId)
          );
    
          if (conflictingSections.length > 0) {
            Swal.fire({
              icon: "warning",
              title: "Cannot Delete",
              text: "This section has existing marks assignments and cannot be deleted.",
              confirmButtonText: "OK"
            });
            return; // Prevent deletion if section is in use
          }
    
          // Proceed with deletion logic here
        } catch (error) {
          console.error("Error checking section usage:", error);
          // Optional: Add error handling UI for the user
        }
      }
    }
  
    // Check and handle exam type removals
    if (field === "examTypeId") {
      const removedExamTypes = (formData[field] || []).filter(
        (typeId) => !selectedValues.includes(typeId)
      );
  
      if (removedExamTypes.length > 0) {
        try {
          // Call API to check if removed exam types exist in subject setup
          const response = await axiosInstance.get(`exam/clear/examtype`, {
            params: { examId: id }, // Pass examId as a query parameter
          });
  
          const { examTypes } = response.data;
  
          // Find conflicting exam types that are already in use
          const conflictingExamTypes = removedExamTypes.filter((typeId) =>
            examTypes.some((examType) => examType._id === typeId)
          );
  
          if (conflictingExamTypes.length > 0) {
            Swal.fire({
              icon: "warning",
              title: "Cannot Delete",
              text: "Some exam types are already used in subject setup and cannot be deleted.",
              confirmButtonText: "OK"
            });
            return; // Prevent removal if exam type is in use
          }
        } catch (error) {
          console.error("Error checking exam type existence:", error);
          // Optional: Add error handling UI for the user
        }
      }
    }
  
    // Update the form data if no conflicts or API failures
    setFormData((prev) => ({
      ...prev,
      [field]: selectedValues, // Update the form field with the selected values
    }));
  };
  


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "examName") {
      const words = value.trim().split(/\s+/);
      const wordCount = words.length;

      if (wordCount > 40) {
        setErrors({
          ...errors,
          [name]: "Exam Name must be less than 40 words.",
        });
        return;
      } else if (words.some((word) => word.length > 50)) {
        setErrors({
          ...errors,
          [name]: "Each word must be less than 50 characters.",
        });
        return;
      }
      setFormData({ ...formData, [name]: value });
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleEditExam = async (e) => {
    e.preventDefault();
    setLoading(true);


    try {
      // Construct the payload
      const payload = {
        examName: formData.examName,
        sectionIds: formData.sectionId, // Ensure sectionId is an array
        examTypeId: formData.examTypeId, // Ensure examTypeId is an array
        grades: formData.grades,
      };

      await axiosInstance.put(`/exam/update/exam/${id}`, payload);

      // Navigate back to exams page
      navigate("/exams");
    } catch (error) {
      console.error("Error updating exam type:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate("/exams");
  };

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#FB0F01" : provided.borderColor,
      boxShadow: state.isFocused ? "0 0 0 1px #FB0F01" : null,
      borderWidth: state.isFocused ? "0px" : "1px",
      transition: "border-color 0.2s, box-shadow 0.2s",
      "&:hover": {
        borderColor: "#FB0F01",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#FFFFFF",
      zIndex: 999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FDD7C2" : "#FFFFFF",
      color: "black",
      "&:hover": {
        backgroundColor: "#FDD7C2",
        color: "black",
      },
      "&:active": {
        backgroundColor: "#E8F0FE",
      },
    }),
  };



  
  const addGrade = () => {
    const { grade, max_mark, min_mark } = formData;

    // Validation for empty fields
    const isMinMarkExist = formData.grades.some(
      (g) => g.min_mark === parseFloat(min_mark)
    );
    const isMaxMarkExist = formData.grades.some(
      (g) => g.max_mark === parseFloat(max_mark)
    );
    // const isGradeExist = formData.grades.some((g) => g.grade === grade);

    const isGradeExist = formData.grades.some(
      (g) => g.grade.trim().toLowerCase() === grade.trim().toLowerCase() // Normalize both to lowercase and trim spaces before comparison
    );

    const isMaxMarkExistwithMax = formData.grades.some(
      (g) => g.max_mark === parseFloat(min_mark)
    );
    const isMaxMarkExistwithMin = formData.grades.some(
      (g) => g.min_mark === parseFloat(max_mark)
    );

    const isRangeOverlap = formData.grades.some((existingGrade) => {
      // Check if the new range [min_mark, max_mark] overlaps with any existing range
      return (
        (parseFloat(min_mark) >= existingGrade.min_mark &&
          parseFloat(min_mark) <= existingGrade.max_mark) || // Min mark within existing range
        (parseFloat(max_mark) >= existingGrade.min_mark &&
          parseFloat(max_mark) <= existingGrade.max_mark) || // Max mark within existing range
        (parseFloat(min_mark) <= existingGrade.min_mark &&
          parseFloat(max_mark) >= existingGrade.max_mark) // New range fully covers existing range
      );
    });

    if (isRangeOverlap) {
      Swal.fire({
        title: "Invalid Range!",
        text: "The new grade range overlaps with an existing range.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }
    if (isMaxMarkExistwithMax) {
      Swal.fire({
        title: "Duplicate Min percentage!",
        text: "A grade with the same max percentage already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }
    if (isMaxMarkExistwithMin) {
      Swal.fire({
        title: "Duplicate Min percentage!",
        text: "A grade with the same Min percentage already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (isMinMarkExist) {
      Swal.fire({
        title: "Duplicate Min percentage!",
        text: "A grade with the same Min percentage already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (isMaxMarkExist) {
      Swal.fire({
        title: "Duplicate Max percentage!",
        text: "A grade with the same Max percentage already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (isGradeExist) {
      Swal.fire({
        title: "Duplicate Grade!",
        text: "A grade with the same Grade already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (!grade || !max_mark || !min_mark) {
      Swal.fire({
        title:"Incomplete Information",
        text: "All fields must be filled out",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }
    if (grade.length > 25) {
      Swal.fire({
        title: "Invalid Grade!",
        text: "Grade must be 25 characters or fewer.",
        icon:"warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Validate that max_mark and min_mark are valid integers
    if (
      isNaN(max_mark) ||
      isNaN(min_mark) ||
      !Number.isInteger(parseFloat(max_mark)) ||
      !Number.isInteger(parseFloat(min_mark))
    ) {
      Swal.fire({
        title: "Invalid Number!",
        text: "Max Marks and Min Marks must be valid integers.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Validate that max_mark is an integer and <= 100 (percentage)
    if (parseFloat(max_mark) > 100) {
      Swal.fire({
        title: "Invalid percentage!",
        text: "Maximum percentage cannot be greater than 100.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Validate that min_mark is not greater than max_mark
    if (parseFloat(min_mark) > parseFloat(max_mark)) {
      Swal.fire({
        title: "Invalid percentage!",
        text: "Minimum percentage cannot be greater than Maximum percentage.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // NEW VALIDATION: Check if max_mark is equal to min_mark
    if (parseFloat(min_mark) === parseFloat(max_mark)) {
      Swal.fire({
        title: "Invalid percentage!",
        text: "Minimum percentage cannot be the same as Maximum percentage.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Create the new grade object
    const newGrade = {
      grade,
      max_mark: parseInt(max_mark),
      min_mark: parseInt(min_mark),
    };

    // Update the grades array in formData
    setFormData((prevData) => ({
      ...prevData,
      grades: [...prevData.grades, newGrade],
      grade: "", // Reset form fields
      max_mark: "",
      min_mark: "",
    }));

  };



  const deleteGrade = (index, _id) => {

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this grade?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        // Delete the grade from the grades array
        const updatedGrades = formData.grades.filter((_, i) => i !== index);
        setFormData((prevData) => ({
          ...prevData,
          grades: updatedGrades,
        }));
        Swal.fire("Deleted!", "The grade has been deleted.", "success");
      }
    });

    
    
    if(index == editIndex)
      {
    
        setIsEditing(false)
        setFormData((prevState) => ({
          ...prevState,
          grade: "",
          max_mark: "",
          min_mark: "",
        }));
      } 
  };


  const editGrade = (index, _id) => {
    
    // Get the selected grade object from the grades array
    const selectedGrade = formData.grades[index];

    // Set the form fields with the selected grade values
    setFormData({
      ...formData,
      grade: selectedGrade.grade,
      max_mark: selectedGrade.max_mark,
      min_mark: selectedGrade.min_mark,
      _id: selectedGrade._id,
      indexofList: index,
    });

    setIsEditing(true);
    setEditIndex(index);
  };



  const submitEditedGrade = () => {
    const { grade, max_mark, min_mark, _id, indexofList } = formData;

     

    const isGradeExist = formData.grades.some(
      (g, index) =>
        g.grade.trim().toLowerCase() === grade.trim().toLowerCase() &&  // Normalize both to lowercase and trim spaces before comparison
        (index !== indexofList || g.grade.trim().toLowerCase() !== formData.grades[indexofList]?.grade.trim().toLowerCase()) // Check if grade name has changed, with trim and case-insensitive check
    );


    
    const isMaxMarkExistwithMax = formData.grades.some(
      (g) => g.max_mark === parseFloat(min_mark)
    );


    const isMaxMarkExistwithMin = formData.grades.some(
      (g) => g.min_mark === parseFloat(max_mark)
    );

    if (isMaxMarkExistwithMax) {
      Swal.fire({
        title: "Duplicate Min percentage!",
        text: "A grade with the same max percentage already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (isMaxMarkExistwithMin) {
      Swal.fire({
        title: "Duplicate Min percentage!",
        text: "A grade with the same Min percentage already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (isGradeExist) {
    
      Swal.fire({
        title: "Duplicate Grade!",
        text: "A grade with this name already exists.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return; // Exit function if duplicate grade is found
    }

    const isRangeOverlap = formData.grades.some((existingGrade) => {
      // Check if the new range [min_mark, max_mark] overlaps with any existing range
      return (
        (parseFloat(min_mark) >= existingGrade.min_mark &&
          parseFloat(min_mark) <= existingGrade.max_mark) || // Min mark within existing range
        (parseFloat(max_mark) >= existingGrade.min_mark &&
          parseFloat(max_mark) <= existingGrade.max_mark) || // Max mark within existing range
        (parseFloat(min_mark) <= existingGrade.min_mark &&
          parseFloat(max_mark) >= existingGrade.max_mark) // New range fully covers existing range
      );
    });

    if (formData.grades.indexofList === indexofList) {
      if (isRangeOverlap) {
        Swal.fire({
          title: "Invalid Range!",
          text: "The new grade range overlaps with an existing range.",
          icon: "warning",
          confirmButtonText: "OK",
        });
        return;
      }
    }

   


 // Validation for empty fields
 if (grade === "" || max_mark === "" || min_mark === "" || grade == null || max_mark == null || min_mark == null) {
  Swal.fire({
    title: "Incomplete Information",
    text: "All fields must be filled out.",
    icon: "warning",
    confirmButtonText: "OK",
  });
  return;
}

    if (grade.length > 25) {
      Swal.fire({
        title: "Invalid Grade!",
        text: "Grade must be 25 characters or fewer.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Validate that max_mark and min_mark are valid numbers
    if (isNaN(max_mark) || isNaN(min_mark)) {
      Swal.fire({
        title: "Invalid Number!",
        text: "Max percentage and Min percentage must be valid numbers.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Validate that max_mark is an integer and <= 100 (percentage)
    if (parseFloat(max_mark) > 100) {
      Swal.fire({
        title: "Invalid percentage!",
        text: "Maximum percentage cannot be greater than 100.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Validate that min_mark is an integer
    if (!Number.isInteger(parseFloat(min_mark))) {
      Swal.fire({
        title: "Invalid percentage!",
        text: "Minimum percentage must be a valid integer.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    // Validate that min_mark is not greater than max_mark
    if (parseFloat(min_mark) > parseFloat(max_mark)) {
      Swal.fire({
        title: "Invalid percentage!",
        text: "Minimum percentage cannot be greater than Maximum Marks.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }


    const updatedGrades = [...formData.grades];

    // Find the grade to update by indexofList or _id
    let gradeIndex;
    if (indexofList !== null && indexofList !== undefined) {
        gradeIndex = indexofList;
    } else if (_id) {
        gradeIndex = updatedGrades.findIndex((g) => g._id === _id);
    }
  
    if (gradeIndex !== -1) {
        updatedGrades[gradeIndex] = { grade, max_mark, min_mark, _id };
    } else {
        Swal.fire({
            title: "Grade Not Found",
            text: "Grade not found for editing.",
            icon: "warning",
            confirmButtonText: "OK",
        });
        return;
    }
  

    // Update state with the new grades array
    setFormData((prevData) => ({
      ...prevData,
      grades: updatedGrades,
      grade: "", // Reset form fields
      max_mark: "",
      min_mark: "",
    }));

    // Exit edit mode
    setIsEditing(false);
    setEditIndex(null);

    // Show success message
    Swal.fire({
      title: "Success!",
      text: "Grade successfully updated.",
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  

  const renderTable = () => (
    <div>
      <div style={{ overflowX: "auto" }}>
          <Table bordered striped responsive>
            <thead style={{ backgroundColor: "#f8f9fa", color: "#495057" }}>
              <tr>
                <th>% From</th>
                <th>% To</th>
                <th>Grade</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
            {Array.isArray(formData.grades) && formData.grades.length > 0 ? (
              formData.grades.map((grade, index) => (
                <tr key={index}>
                  <td>{grade.min_mark}</td>
                  <td>{grade.max_mark}</td>
                  <td>{grade.grade}</td>
                  <td>
                    <FaRegEdit
                      title="Edit"
                      onClick={() => editGrade(index, grade._id)}
                      style={{ cursor: "pointer", margin: "0 6px" }}
                    />
                    <AiOutlineDelete
                      title="Delete"
                      onClick={() => deleteGrade(index, grade._id)}
                      style={{ cursor: "pointer", margin: "0 6px" }}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr className=" text-center">
                <td colSpan="4" className=" h5 py-3">No grades available</td>
              </tr>
            )}
          </tbody>

            <style jsx="true">{`
          table td {
            white-space: nowrap;
          }
          table th {
            white-space: nowrap;
          }

          @media (max-width: 768px) {
            table td,
            table th {
              padding: 5px;
              white-space: nowrap;
            }
          }

          @media (max-width: 576px) {
            table td,
            table th {
              white-space: nowrap;
            }
          }
        `}</style>
          </Table>
      </div>
    </div>
  );

  return (
    <div>
      {initialLoading ? (
        <div
          className="d-flex justify-content-center center align-items-center p-4"
          style={{ minHeight: "90vh" }}
        >
          <Spinner color="primary" />
        </div>
      ) : (
        <Card style={{  margin: "0 auto" }}>
          <CardBody>
            <CardTitle tag="h3" className="mb-1 text-center">
              Edit Exam
            </CardTitle>

            <Form className="mt-5" onSubmit={handleEditExam}>
              <Row>
                <Col xs={12} sm={6} md={4} xl={3}>
                  <FormGroup>
                    <Label for="branch">
                      <span style={{ fontWeight: "bold" }}>Branch*</span>
                    </Label>
                    <Input
                      type="text"
                      name="branch"
                      id="branch"
                      value={formData.branchName} // Use branchName from formData
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={4} xl={3}>
                  <FormGroup>
                    <Label for="class">
                      <span style={{ fontWeight: "bold" }}>Class*</span>
                    </Label>
                    <Input
                      type="text"
                      name="class"
                      id="class"
                      value={formData.className} // Use className from formData
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={4} xl={3}>
                  <FormGroup>
                    <Label for="section">
                      <span style={{ fontWeight: "bold" }}>Section*</span>
                    </Label>
                    <Select
                      name="sectionId"
                      options={sections}
                      value={sections.filter((section) =>
                        formData.sectionId.includes(section.value)
                      )}
                      onChange={(selectedOptions) =>
                        handleSelectChange(selectedOptions, "sectionId")
                      }
                      isMulti
                      isClearable
                      required={sections.length > 0}
                      placeholder={
                        sections.length === 0
                          ? "No Section"
                          : "Select a Section"
                      }
                      isDisabled={sections.length === 0}
                      styles={customStyles}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={4} xl={3}>
                  <FormGroup>
                    <Label for="academicYear">
                      <span style={{ fontWeight: "bold" }}>Academic Year*</span>
                    </Label>
                    <Input
                      type="text"
                      name="academicYear"
                      id="academicYear"
                      value={formData.academicYear} // Use academicYear from formData
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={4} xl={3}>
                  <FormGroup>
                    <Label for="examName">
                      <span style={{ fontWeight: "bold" }}>Exam Name*</span>
                    </Label>
                    <Input
                      type="text"
                      name="examName"
                      id="examName"
                      value={formData.examName}
                      onChange={handleChange}
                    />
                    {errors.examName && (
                      <div
                        style={{
                          color: "red",
                          marginTop: "0.25rem",
                          fontSize: "0.875rem",
                        }}
                      >
                        {errors.examName}
                      </div>
                    )}
                  </FormGroup>
                </Col>

                {formData.checkExamTypeId.length > 0 && (
                <Col xs={12} sm={6} md={4} xl={3}>
                  <FormGroup>
                    <Label for="examType">
                      <span style={{ fontWeight: "bold" }}>Exam Types </span><span style={{fontSize:'15px'}}>(Only Select if Applicable)</span>
                    </Label>
                    <Select
                      className="custom-select-input"
                      name="examTypeId"
                      options={examType}
                      value={examType.filter((type) =>
                        formData.examTypeId.includes(type.value)
                      )}
                      onChange={(selectedOptions) =>
                        handleSelectChange(selectedOptions, "examTypeId")
                      }
                      isMulti
                      isClearable={false}
                      placeholder={
                        examType.length === 0
                          ? "No Exam Type"
                          : "Select an Exam Type"
                      }
                      isDisabled={examType.length === 0}
                      styles={customStyles}
                    />
                  </FormGroup>
                </Col>
                )}



              </Row>

              <div>
                <Card style={{  margin: "0 auto" }} className=" shadow-none">
                  <CardBody className="p-0 pb-2">
                    <div className="d-flex justify-content-between align-items-center  pt-1">
                      <CardTitle className="mt-2 ms-1 mb-0" tag="h4">
                        Add Grade
                      </CardTitle>
                    </div>

                    <Form className="mt-1">
                      <Row
                        className="mt-2 pt-2  m-0"
                        style={{
                          border: "1px solid #ccc", // Border around the row
                          padding: "10px 0", // Padding inside the row
                          margin: "5px", // Margin after the border (space between rows)
                        }}
                      >
                        <Col xs={12} sm={6} md={4} xl={3}>
                          <FormGroup>
                            <Label for="min_mark">
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "0.95rem",
                                }}
                              >
                                Minimum Percentage
                              </span>
                            </Label>
                            <Input
                              type="text"
                              name="min_mark"
                              id="min_mark"
                              placeholder="Min Percentage"
                              value={formData.min_mark}
                              onChange={handleChange}
                              className=" ps-2"
                              style={{
                                padding: "4px",
                                fontSize: "0.95rem",
                                height: "37px",
                                borderRadius: "4px",
                                border: "1px solid #ccc",
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col xs={12} sm={6} md={4} xl={3}>
                          <FormGroup>
                            <Label for="max_mark">
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "0.95rem",
                                }}
                              >
                                Maximum Percentage
                              </span>
                            </Label>
                            <Input
                              type="text"
                              name="max_mark"
                              id="max_mark"
                              placeholder="Max Percentage"
                              value={formData.max_mark}
                              onChange={handleChange}
                              className=" ps-2"
                              style={{
                                padding: "4px",
                                fontSize: "0.95rem",
                                height: "37px",
                                borderRadius: "4px",
                                border: "1px solid #ccc",
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col xs={12} sm={6} md={4} xl={3}>
                          <FormGroup>
                            <Label for="grade">
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "0.95rem",
                                }}
                              >
                                Grade
                              </span>
                            </Label>
                            <Input
                              type="text"
                              name="grade"
                              id="grade"
                              placeholder="Grade"
                              value={formData.grade}
                              onChange={handleChange}
                              className=" ps-2"
                              style={{
                                padding: "4px",
                                fontSize: "0.95rem",
                                height: "37px",
                                borderRadius: "4px",
                                border: "1px solid #ccc",
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col xs={12} sm={6} md={3} xl={2} className="mt-2" >
                          {!isEditing && (
                            <Button
                              color="primary"
                              className="mt-xl-4 mt-sm-4 mt-md-0 btn-no-radius mb-2"
                              onClick={addGrade} // Assuming you have this handler
                             
                            >
                              Add Grade
                            </Button>
                          )}
                          {isEditing && (
                            <Button
                              color="primary"
                              className="mt-xl-4 btn-no-radius mb-2"
                              onClick={submitEditedGrade} // Assuming you have this handler
                             
                            >
                              Edit Grade
                            </Button>
                          )}
                        </Col>

                        {renderTable()}
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </div>

              <div className="d-flex justify-content-center mt-3">
                <Button
                  className="btn-no-radius"
                  type="submit"
                  color="primary"
                  disabled={loading}
                >
                  {loading ? <Spinner size="sm" /> : "Save"}
                </Button>
                <Button
                  className="btn-no-radius mx-2"
                  color="secondary"
                  onClick={handleCancel}
                  disabled={loading}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default EditExam;
