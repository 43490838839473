
import React, { useState, useEffect } from "react";
import {
  Card,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Button,
  Table,
  Row,
  Col,
  Spinner
} from "reactstrap";
import { FaRegEdit, FaFileAlt } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";
import { useNavigate, NavLink } from "react-router-dom";
import axiosInstance from "../../../../middleware/axiosInstance";
import Swal from 'sweetalert2';
import Select from 'react-select';


const FeesType = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({ branchId: "", classId: "",academicYearId:'' });
  const [branches, setBranches] = useState([]);
  const [classes, setClasses] = useState([]);
  const [academicYears, setAcademicYears] = useState([]);
  const [feesTypes, setFeesTypes] = useState([]);
  const [error, setError] = useState("");
  const [noDataFound, setNoDataFound] = useState(false);

  const [loading, setLoading] = useState(true);

  const token = localStorage.getItem("token");

  // Fetch all branches on page load
  useEffect(() => {

    const fetchBranches = async () => {
      try {
        const response = await axiosInstance.get("/branches");
          setBranches(response.data);
      } catch (error) {
        console.error("Error fetching branches:", error);
          setError("Failed to fetch branches."); // Set error state
      }
    };
    
    fetchBranches();
    
  }, [token]);
  
  // Fetch classes based on the selected branch
  useEffect(() => {
    const fetchClasses = async () => {
      if (formData.branchId) {
        try {
          const response = await axiosInstance.get("/classes/branch", {
            params: { branchId: formData.branchId },
          });
         
            setClasses(response.data);
            setFormData((prev) => ({ ...prev, classId: "" }));
        } catch (error) {
          console.error("Error fetching classes:", error);
            setError("Failed to fetch classes."); // Set error state
        }
        finally {
          setLoading(false);
        }
        
      }
       else {
          setClasses([]);
          setFormData((prev) => ({ ...prev, classId: "" }));
      }
    };
    
    fetchClasses();
    
  }, [formData.branchId, token]);
  
  // Fetch academic years
  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const response = await axiosInstance.get("/academicyears");
        setAcademicYears(response.data);

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();  
        const currentDay = currentDate.getDate();

        let defaultAcademicYear;

        if (currentMonth > 4 || (currentMonth === 4 && currentDay >= 1)) {
          // June 1 to December 31, or May 1 to May 31
          
          defaultAcademicYear = `${currentYear}-${String(currentYear + 1).slice(
            -2
          )}`;
        } else {
          defaultAcademicYear = `${currentYear - 1}-${String(currentYear).slice(
            -2
          )}`;
        }

         const defaultAcademicYearEntry = response.data.find(
          (year) => year.year === defaultAcademicYear  
        );

        

        if (defaultAcademicYearEntry) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            academicYearId: defaultAcademicYearEntry._id,  
          }));
        }
      } catch (error) {
        console.error("Error fetching academic years:", error);
      }finally {
        setLoading(false);  
      }
    };

    getAcademicYears();
  }, []);


  useEffect(() => {
    const fetchFeesTypes = async () => {
      try {
        let response;
        
        if (formData.branchId || formData.academicYearId || formData.classId) {
          // Fetch fees types filtered by branch, class, and academic year
          response = await axiosInstance.get("/feestype", {
            params: {
              branchId: formData.branchId,
              classId: formData.classId || null, // Allow classId to be null
              academicYearId: formData.academicYearId || null, // New filter by academic year
            },
          });
        } else {
          // Fetch all fees types when no filter is selected
          response = await axiosInstance.get("/feestype");
        }

        setFeesTypes(response.data);
        setNoDataFound(response.data.length === 0);
      } catch (error) {
        setError("Failed to fetch fees types.");
      }
      finally {
        setLoading(false);
      }
    };

    fetchFeesTypes(); // Fetch fees types when branchId, classId, or academicYearId changes
  
  }, [formData.branchId, formData.classId, formData.academicYearId, token]);

  const handleChange = ({ target: { name, value } }) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "branchId") {
      setClasses([]); // Clear classes when branch changes
      setFormData((prev) => ({ ...prev, classId: "", academicYearId: "" })); // Reset classId and academicYearId
      setNoDataFound(false); // Reset noDataFound when branch changes
    } else if (name === "classId" || name === "academicYearId") {
      setNoDataFound(false); // Reset noDataFound when class or academic year changes
    }
  };



  // Handle edit and delete actions
  const handleEdit = (id) => {
    navigate(`/edit/feestype/${id}`);
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });

    if (result.isConfirmed) {
      try {
        await axiosInstance.delete(`/delete/feestype/${id}`);
        setFeesTypes((prevFeesTypes) =>
          prevFeesTypes.filter((feesType) => feesType._id !== id)
        );
      } catch (error) {
        console.error('Error deleting fees type:', error);
      }
    }
  };



  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: 'nowrap', 
      textOverflow: 'ellipsis', 
      overflow: 'hidden',
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#FB0F01' : provided.borderColor,
      boxShadow: state.isFocused ? '0 0 0 1px #FB0F01' : null,
      borderWidth: state.isFocused ? '0px' : '1px',
      transition: 'border-color 0.2s, box-shadow 0.2s',
      '&:hover': {
        borderColor: '#FB0F01',
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#FFFFFF',
      zIndex: 999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? '#FDD7C2' 
        : '#FFFFFF', 
      color: 'black',
      '&:hover': {
        backgroundColor: '#FDD7C2',
        color: 'black',
      },
      '&:active': {
        backgroundColor: '#E8F0FE',
      },
    }),
  };

  // Render the fees types table
  const renderTable = () => (
    <div>
      <div className="px-0 pb-3">
        <CardTitle tag="h4" className="mt-3 mb-1">
          Fees Type List
        </CardTitle>
      </div>
      <div style={{ overflowX: 'auto' }}>
        <Table bordered striped responsive>
          <thead style={{ backgroundColor: "#f8f9fa", color: "#495057" }}>
            <tr>
              <th>Fees Type</th>
              <th>Class</th>
              <th>Branch</th>
              <th>Academic Year</th>
              <th>Amount</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {feesTypes.length
              ? feesTypes.map((feesType) => (
                  <tr key={feesType._id}>
                    <td>{feesType.feesType}</td>
                    <td>{feesType.class_id.className}</td>
                    <td>{feesType.branch_id.branchName}</td>
                    <td>{feesType.academicyear_id.year}</td>
                    <td>{feesType.amount}</td>
                    <td>
                      <FaRegEdit
                        title="Edit"
                        onClick={() => handleEdit(feesType._id)}
                        style={{ cursor: "pointer", margin: "0 6px" }}
                      />
                      <AiOutlineDelete
                        title="Delete"
                        onClick={() => handleDelete(feesType._id)}
                        style={{ cursor: "pointer", margin: "0 6px" }}
                      />
                    </td>
                  </tr>
                ))
              : noDataFound && (
                  <tr>
                    <td rowSpan="4" colSpan="6" className="text-center">
                      <FaFileAlt className="mt-3" size={70} color="#888" />
                      <h4 className="alert-heading mb-3 mt-3">
                        No Fees Types Found
                      </h4>
                    </td>
                  </tr>
                )}
          </tbody>
        </Table>
        <style jsx="true">{`
          table td{
            white-space: nowrap; 
          }
            table th {
             white-space: nowrap; 
            }

          @media (max-width: 768px) {
            table td, table th {
              padding: 5px;
              white-space: nowrap; 
            }
          }

          @media (max-width: 576px) {
            table td, table th {
              white-space: nowrap; 
            }
          }
        `}</style>
      </div>
     
    </div>
  );

  return (
    <>
     {loading ? (
        <div className="d-flex justify-content-center center align-items-center p-4" style={{ minHeight: '90vh' }}>
          <Spinner color="primary" />
        </div>
      ) : (
        <>
      <Card style={{boxShadow: 'none', marginBottom: '10px', borderRadius: '0' }}>
        <div
          className="d-flex justify-content-between align-items-center px-3 pt-1"
        >
          <CardTitle className='mt-2' tag="h4">Fees Types</CardTitle>
          <NavLink to="/create/feestype" className='pb-0 mb-0'>
            <Button className="btn-no-radius" color="primary">
              Add Fees Type
            </Button>
          </NavLink>
        </div>
        <hr className='mt-1' />
        <div className="px-3 pt-0">
          <Form>
          <Row> 
            <Col xs={12} sm={6} md={4} xl={3}>
              <FormGroup>
                <Label for="branch">
                  <span style={{ fontWeight: "bold" }}>Branch</span>
                </Label>
                <Select
                className=""
                  id="branch"
                  options={branches.map(branch => ({ value: branch._id, label: branch.branchName }))}
                  onChange={selected => {
                    handleChange({ 
                      target: { 
                        name: 'branchId', 
                        value: selected ? selected.value : '' // Handle null case
                      } 
                    });
                  }}
                  value={branches.find(branch => branch._id === formData.branchId) ? { value: formData.branchId, label: branches.find(branch => branch._id === formData.branchId).branchName } : null}
                  placeholder="Select a Branch"
                  isSearchable={true}
                  isClearable={true}
                  styles={customStyles}
                />
              </FormGroup>
            </Col>
            
            <Col xs={12} sm={6} md={4} xl={3}>
              <FormGroup>
                <Label for="class">
                  <span style={{ fontWeight: "bold" }}>Class</span>
                </Label>
                <Select
                  id="class"
                  options={classes.map(classItem => ({ value: classItem._id, label: classItem.className }))}
                  onChange={selected => {
                    handleChange({ 
                      target: { 
                        name: 'classId', 
                        value: selected ? selected.value : '' // Handle null case
                      } 
                    });
                  }}
                  value={classes.find(classItem => classItem._id === formData.classId) ? { value: formData.classId, label: classes.find(classItem => classItem._id === formData.classId).className } : null}
                  placeholder="Select a Class"
                  isSearchable={true}
                  isClearable={true}
                  styles={customStyles}
                />
              </FormGroup>
            </Col>

            <Col  xs={12} sm={6} md={4} xl={3}>
              <FormGroup>
                <Label for="academicYear">
                  <span style={{ fontWeight: "bold" }}>Academic Year</span>
                </Label>
                <Select
                  id="academicYear"
                  options={academicYears.map(year => ({ value: year._id, label: year.year }))}
                  onChange={selected => {
                    handleChange({ 
                      target: { 
                        name: 'academicYearId', 
                        value: selected ? selected.value : '' // Handle null case
                      } 
                    });
                  }}
                  value={academicYears.find(year => year._id === formData.academicYearId) ? { value: formData.academicYearId, label: academicYears.find(year => year._id === formData.academicYearId).year } : null}
                  placeholder="Select Academic Year"
                  isSearchable={true}
                  isClearable={true}
                  styles={customStyles}
                />
              </FormGroup>
            </Col>
          </Row>
          </Form>
        </div>
      </Card>
    
      <Card
        style={{
          boxShadow: "none",
          marginTop: "0px",
          paddingTop: "0px",
          minHeight: "400px",
        }}
        className="px-3 "
      >
        {renderTable()}
      </Card>
      </>
    )}
  </>
);
};

export default FeesType;

